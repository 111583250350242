import React from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import ChefDeDiocese from "./tabs/ChefDeDiocese";
import ChefDeZone from "./tabs/ChefDeZone";
import ChefDeDistrict from "./tabs/ChefDeDistrict";
import Diocese from "./tabs/Diocese";
import BreadCrumb from "../../../shared/BreadCrumb";
import DocumentList from "./DocumentList";

const steps = [
    { id: "chef-diocese", Component: ChefDeDiocese },
    { id: "chef-zone", Component: ChefDeZone },
    { id: "chef-district", Component: ChefDeDistrict },
    { id: "diocese", Component: Diocese },
];
const tabs = [
    "Chef de Diocèse",
    "Chef de Zone",
    "Chef de District",
    "Diocèse"
];


function DetailChef(){

    const {
        step,
        navigation: { go },
        index,
      }: useStepType = useStep({ initialStep: 0, steps });
      const { Component } = step;

    return(
        <>
            <div className="dash-admin-page-content-container mb-3">
                {index === 0 && 
                    <BreadCrumb 
                    pageName="Paroisses" 
                    secondPage="Chef de Diocèse"
                />}
                {index === 1 && 
                    <BreadCrumb 
                    pageName="Paroisses" 
                    secondPage="Chef de Zone"
                />}
                {index === 2 && 
                    <BreadCrumb 
                    pageName="Paroisses" 
                    secondPage="Chef de District"
                />}
                {index === 3 && 
                    <BreadCrumb 
                    pageName="Paroisses" 
                    secondPage="Diocèse"
                />}
            </div>

            <div className="content-detail-chef">
                <div className="eglise-dash-tabs mb-0 bg-white pt-4 px-4">
                    <ul className="tabs-container tabs-container-admin mb-0">
                        {tabs.map((tab: any, key: any) => (
                        <li
                            className={`tab-item  ${index === key && 
                                "tab-active-item-admin"
                            }`}
                            key={key}
                            onClick={() => go(key)}
                            role="button"
                        >
                            {tab}
                        </li>
                        ))}
                    </ul>
                </div>
                <div className="tabs-detail-chef pt-4">
                    <Component />
                    <DocumentList />
                </div>
            </div>
        </>
    )
}

export default DetailChef