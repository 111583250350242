import React from "react";
import PhoneInput from "react-phone-input-2";
import UseCrudAdmin from "./requestForm/UseCrudAdmin";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import { Authorisations } from "../../../utils/constante";
import { useAdminFromLocation } from "../../../utils/api/user/user.api";

function AddOrUpdateAdmin() {
  const [admin] = useAdminFromLocation();
  const { register, onSubmit, errors, isLoading, phone, handleChangePhone } =
    UseCrudAdmin(admin);
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Admin" secondPage="Ajouter un admin" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <h2 className="chart-item-admin-title mb-4">
            Nouveau administrateur
          </h2>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-6 mb-3">
                  <Input
                    type="text"
                    label="Nom"
                    id="nom"
                    placeholder="Nom"
                    {...register("nom")}
                    error={errors.nom}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Input
                    type="text"
                    label="Prénom"
                    id="prenom"
                    placeholder="Prénom"
                    {...register("prenom")}
                    error={errors.prenom}
                    required
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <Input
                    type="email"
                    label="Email"
                    id="email"
                    placeholder="Email"
                    {...register("email")}
                    error={errors.email}
                    required
                  />
                </div>
                <div className="col-md-6">
                  {/* <Input
                    label="Téléphone"
                    type="numeric"
                    placeholder="Téléphone"
                    {...register("telephone")}
                    error={errors.telephone}
                  /> */}
                  <div className="mb-3">
                    <label
                      htmlFor={"telephone"}
                      className={`form-label form-label-modal-custom`}
                      aria-labelledby={"telephone"}
                    >
                      Téléphone
                    </label>

                    <PhoneInput
                      defaultMask={".. ... .. .."}
                      containerClass="phone-input-admin"
                      inputClass="form-control custom-padding"
                      country={"sn"}
                      placeholder=""
                      inputProps={{
                        name: "telephone",
                        required: true,
                        autoFocus: true,
                      }}
                      countryCodeEditable={false}
                      enableAreaCodes={true}
                      prefix="+"
                      value={phone}
                      onChange={(phone, country: any) => {
                        handleChangePhone(phone, country);
                      }}
                      inputStyle={{
                        width: "100%",
                        paddingBottom: "22px",
                        borderRadius: "10px",
                        paddingTop: "22px",
                        height: "54px",
                        backgroundColor: "#F2F6FA",
                        border: "none",
                      }}
                      dropdownStyle={{
                        backgroundColor: "#F2F6FA",
                      }}
                      enableLongNumbers={true}
                    />
                    <FormError error={errors?.telephone?.message} />
                  </div>
                </div>
                {/* <div className="col-md-6 mb-3">
                  <Input
                    type="text"
                    label="Adresse"
                    id="adresse"
                    placeholder="Adresse"
                    {...register("adresse")}
                    error={errors.adresse}
                  />
                </div> */}
                <div className="col-md-12">
                  <div className="custom-wrapper-title pb-5">Habilitations</div>
                  <div className="container-checkbox-input">
                    <div className="form-checkbox-input checkbox-input">
                      <div className="custom-permission-container">
                        {Authorisations?.map((item) => (
                          <label key={item?.field} className={item?.field === "dashboard" ? "disabled" : ""}>
                            <input
                              type="checkbox"
                              {...register(item?.field)}
                              id={`checkbox-${item?.field}`}
                              disabled={
                                item?.field === "dashboard"
                              }
                            />
                            <span>{item?.label}</span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn btn-theme-admin w-100"
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>{admin ? "Modification..." : "Ajout..."}</span>
                      </>
                    ) : admin ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateAdmin;
