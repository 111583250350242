import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormError, Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import UseCrudCompteRendu from "../requestForm/UseCrudCompteRendu";

const AddContenuMeetingModal = ({
  modalAddContenuMeeting,
  setModalAddContenuMeeting,
  membre_type,
  compteRendu,
}: {
  modalAddContenuMeeting: any;
  setModalAddContenuMeeting: any;
  membre_type: any;
  compteRendu: any;
}) => {
  // console.log("compteRendu", compteRendu)
  function closeModalAddContenuMeeting() {
    setModalAddContenuMeeting(false);
  }

  const [activeElementType, setActiveElementType] = useState("pdf");

  const dropDownChanged = (e) => {
    setActiveElementType(e.target.value);
  };

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    description,
    rapport,
    pointDeDiscussion,
    affairesNouvelles,
    annonces,
    handleReset,
    handleChangeDescription,
    handleChangeRapport,
    handleChangePointDiscussion,
    handleChangeAffairesNouvelles,
    handleChangeAnnonces,
    handleChangeDocument,
  } = UseCrudCompteRendu(setModalAddContenuMeeting, membre_type, compteRendu);

  useEffect(() => {
    if (compteRendu) {
      setActiveElementType(compteRendu?.type_fichier);
    }
  }, [compteRendu]);

  return (
    <Modal
      show={modalAddContenuMeeting}
      onHide={() => {
        handleReset();
        closeModalAddContenuMeeting();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      size="lg"
      centered
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">Nouveau contenu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="mt-4" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Titre"
                  id="titre"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors.titre}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="description"
                  className="form-label form-label-modal-custom"
                  aria-labelledby="description"
                >
                  Description{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={description}
                  onChange={handleChangeDescription}
                />
                <FormError error={errors?.description} />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="rapport"
                  className="form-label form-label-modal-custom"
                  aria-labelledby="rapport"
                >
                  Rapport
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={rapport}
                  onChange={handleChangeRapport}
                />
                <FormError error={errors?.rapport} />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="point_de_discussion"
                  className="form-label form-label-modal-custom"
                  aria-labelledby="point_de_discussion"
                >
                  Point de discussion
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={pointDeDiscussion}
                  onChange={handleChangePointDiscussion}
                />
                <FormError error={errors?.point_de_discussion} />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="affaires_nouvelles"
                  className="form-label form-label-modal-custom"
                  aria-labelledby="affaires_nouvelles"
                >
                  Affaires nouvelles
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={affairesNouvelles}
                  onChange={handleChangeAffairesNouvelles}
                />
                <FormError error={errors?.affaires_nouvelles} />
              </div>
              <div className="col-md-12 mb-3">
                <label
                  htmlFor="annonces"
                  className="form-label form-label-modal-custom"
                  aria-labelledby="annonces"
                >
                  Annonces
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={annonces}
                  onChange={handleChangeAnnonces}
                />
                <FormError error={errors?.annonces} />
              </div>
              <div className="col-md-12 my-3">
                <select
                  {...register("type_fichier")}
                  onChange={(e) => dropDownChanged(e)}
                  className="form-control form-control-modal-custom"
                  style={{ appearance: "auto" }}
                >
                  <option value="pdf">Pdf</option>
                  <option value="audio">Audio</option>
                  <option value="video">Video</option>
                </select>
                {activeElementType === "pdf" ? (
                  <div className="col-md-12 my-3">
                    <Input
                      type="file"
                      label="Pdf"
                      accept="application/pdf"
                      id="link"
                      placeholder="Lien"
                      onChange={handleChangeDocument}
                      error={errors.fichier}
                    />
                  </div>
                ) : activeElementType === "audio" ? (
                  <div className="col-md-12 my-3">
                    <Input
                      type="file"
                      label="Audio"
                      id="audio"
                      accept="audio/mp3"
                      placeholder="audio"
                      onChange={handleChangeDocument}
                      error={errors.fichier}
                    />
                  </div>
                ) : activeElementType === "video" ? (
                  <div className="col-md-12 my-3">
                    <Input
                      type="file"
                      label="Video"
                      accept="video/mp4"
                      id="video"
                      placeholder="Video"
                      onChange={handleChangeDocument}
                      error={errors.fichier}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-md-12 mb-3">
                  <Input
                    type="url"
                    label="Lien"
                    id="link"
                    placeholder="Lien"
                    {...register("lien")}
                    error={errors.lien}
                  />
                </div>
              </div>

              <div className="col-md-6 offset-md-6 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>
                        {compteRendu ? "Modification..." : "Ajout..."}
                      </span>
                    </>
                  ) : compteRendu ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddContenuMeetingModal;
