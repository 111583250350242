import React from "react"
import MembresTable from "../MembresTable/MembresTable"

const MembresTabs = ({ q_type }) => {

    const props = {
      q_type,
    };
  return (
    <div className="admin-table mt-4">
      <MembresTable {...props} />
    </div>
  );
};
export default MembresTabs