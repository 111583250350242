import React from "react";
import Skeleton from "react-loading-skeleton";

function BibliothequeSkeleton() {
  return (
    <>
      {Array(8)
        .fill(8)
        .map((i) => (
          <div className="col-lg-3 col-md-4 mb-3 d-flex" key={i}>
            <div className="content-testimonial-item w-100">
              <div className="d-flex align-items-center justify-content-between">
                <Skeleton
                  className="name-user-archive mb-1"
                  width={35}
                  height={15}
                />
                <Skeleton
                  className="name-user-archive mb-1"
                  width={25}
                  height={15}
                />
              </div>
              <Skeleton className="" height={300} />
            </div>
          </div>
        ))}
    </>
  );
}
//img-couv-tab
export default BibliothequeSkeleton;
