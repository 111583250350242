import React, { useState } from "react";
import "./CarnetRose.css";
import { MdAddCircleOutline } from "react-icons/md";
import { useGetListCarnetRoseQuery } from "../../../utils/api/carnetRoses/carnetRoses.api";
import { UseDeleteCarnetRose } from "./request/UseCrudCarnetRose";
import BreadCrumb from "../../shared/BreadCrumb";
import { AlertInfo } from "../../common/Alert";
import moment from "moment";
import AddCarnetRoseModal from "./modals/AddCarnetRoseModal";
import { CarnetRose } from "../../../utils/api/carnetRoses/carnetRoses.type";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { createMarkup, truncateString } from "../../../utils/Utils";
import { CustomPagination } from "../../common/CustomPagination";

const DeleteCarnetRoseBtn = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteCarnetRose(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
};

const CarteRoseItem = ({ carnet }: { carnet: CarnetRose }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="carnet-wrapper">
        <div className="content-testimonial-item">
          <div className="d-flex justify-content-between align-items-center pb-3">
            <p className="text-date-testimonial m-0">
              {moment(carnet?.created_at).format("DD/MM/YYYY")}
            </p>
            <div className="btn-group">
              <button
                className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <BiDotsHorizontalRounded />
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    Modifier
                  </button>
                </li>
                <li>
                  <DeleteCarnetRoseBtn slug={carnet?.slug} />
                </li>
              </ul>
            </div>
          </div>
          <p className="name-usertestimonial mb-1">{carnet?.titre}</p>
          <div className="content-body-testimonial pt-3">
            <p
              className="text-body-testimonial"
              dangerouslySetInnerHTML={createMarkup(
                truncateString(carnet?.content, 230)
              )}
            />
          </div>
        </div>
      </div>
      <AddCarnetRoseModal
        modalAddCarnetRose={show}
        setModalAddCarnetRose={setShow}
        carnetRose={carnet}
      />
    </>
  );
};

function CarnetRoseAdmin() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);
  const [modalAddCarnetRose, setModalAddCarnetRose] = useState<boolean>(false);

  function openModalAddCarnetRose() {
    setModalAddCarnetRose(true);
  }
  const { data, isLoading } = useGetListCarnetRoseQuery({
    page,
    limit: perPage,
  });

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb pageName="Carnet rose" />
        <div className="content-graph-admin mb-3">
          <button
            className="btn btn-theme-admin"
            onClick={(e) => openModalAddCarnetRose()}
          >
            <span className="pe-2">Ajouter une note</span>
            <span>
              <MdAddCircleOutline />
            </span>
          </button>
          <AddCarnetRoseModal
            modalAddCarnetRose={modalAddCarnetRose}
            setModalAddCarnetRose={setModalAddCarnetRose}
          />
        </div>
        <div className="content-testimonial-page">
          <div className="row">
            {!isLoading &&
              (!!data?.results?.length ? (
                data?.results?.map((carnet, key) => (
                  <div
                    className="col-lg-3 col-md-6 mb-3"
                    key={`${key}_${carnet?.id}`}
                  >
                    <CarteRoseItem carnet={carnet} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucun carnet rose trouvé !" />
              ))}
          </div>
          {data?.results && (
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CarnetRoseAdmin;
