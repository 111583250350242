import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { Wrapper } from "../OfficePastoral/AddMembreOfficePastoral";
import { FormError, Input, animatedComponents } from "../../common/Input";
import UseCrudAffectation from "./UseCrudAffectation";
import Select from "react-select";
import { useLocationState } from "../../../utils/Utils";
import { IAffectation } from "../../../utils/api/affectation/affectation.type";

function AddAffectationsAdmin() {
  const affectation = useLocationState<IAffectation>(undefined);
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    Controller,
    control,
    option,
    options,
    optionAffActuel,
    optionsAffActuel,
    optionAffProchain,
    optionsAffProchain,
    handleSelectResponsable,
    handleSelectAffActuel,
    handleSelectAffProchain,
  } = UseCrudAffectation(affectation);
  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb
            pageName="Affectations"
            secondPage={
              affectation ? "Modifier l'affectation" : "Ajouter une affectation"
            }
          />
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <Wrapper customClass="mt-3">
          <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
            <div className="custom-wrapper-title">
              Informations personnelles
            </div>
          </div>
          <div className="form-content">
            <div className="row row-infos-perso">
              <div className="col-md-12">
                <Input
                  label="Date de début"
                  type="date"
                  placeholder="Date de début"
                  {...register("date_debut")}
                  error={errors.date_debut}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor={"affection_actuelle"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"affection_actuelle"}
                  >
                    Affectation actuel{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="affection_actuelle"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={optionsAffActuel}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelectAffActuel}
                          value={optionAffActuel}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.affection_actuelle} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor={"affectation_prochaine"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"affectation_prochaine"}
                  >
                    Affectation prochaine
                  </label>

                  <Controller
                    name="affectation_prochaine"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={optionsAffProchain}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelectAffProchain}
                          value={optionAffProchain}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.affectation_prochaine} />
                </div>
              </div>

              <div className="col-md-12 mb-3">
                <div className="mb-4">
                  <label
                    htmlFor={"responsable"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"responsable"}
                  >
                    Responsable{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="responsable"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={options}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelectResponsable}
                          value={option}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.responsable} />
                </div>
              </div>
              <div className="col-md-4 offset-md-8 pt-5">
                <button
                  className="btn btn-theme-admin w-100"
                  disabled={isLoading}
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>
                        {affectation ? "Modification..." : "Ajout..."}
                      </span>
                    </>
                  ) : affectation ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Wrapper>

        {/* <Wrapper customClass="mt-3">
        <div className="custom-wrapper-title pb-5">Habilitations</div>
        <div className="container-checkbox-input">
          <div className="form-checkbox-input checkbox-input">
            <div className="custom-permission-container">
              {Authorisations?.map((item) => (
                <label key={item?.field}>
                  <input
                    type="checkbox"
                    {...register(item?.field)}
                    id={`checkbox-${item?.field}`}
                    disabled={
                      item?.field === `${field}` ||
                      item?.field === "dashboard"
                    }
                  />
                  <span>{item?.label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>

      </Wrapper> */}
      </form>
    </>
  );
}

export default AddAffectationsAdmin;
