import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { AffectationFormData, IAffectation } from "./affectation.type";

export const AffectationApi = createApi({
    reducerPath: "affectationApi",
    tagTypes: ["affectation", "affectationList"],
    baseQuery: fetchBaseQuery({
      baseUrl: `${ApiBaseUrl}/api/`,
      prepareHeaders,
    }),
    endpoints: (builder) => ({
      addOrEditAffectation: builder.mutation<
        IAffectation,
        { slug?: string; data: AffectationFormData | FormData }
      >({
        invalidatesTags: ["affectation", "affectationList"],
        query: ({ slug, data }) => {
          if (slug) {
            return {
              url: `affectations/${slug}/`,
              method: "PUT",
              body: data,
            };
          }
          return {
            url: `affectations/`,
            method: "POST",
            body: data,
          };
        },
      }),
  
      getAffectation: builder.query<PaginationResults<IAffectation>, TypeQuery>({
        providesTags: ["affectationList"],
        query: (query) => QueryUrl("affectations/", query),
      }),
  
      deleteAffectation: builder.mutation<AffectationFormData, string>({
        query: (slug) => ({
          url: `affectations/${slug}/`,
          method: "DELETE",
        }),
        invalidatesTags: ["affectation", "affectationList"],
      }),
    }),
  });

  export const {
    useAddOrEditAffectationMutation,
    useDeleteAffectationMutation,
    useGetAffectationQuery
  } = AffectationApi;