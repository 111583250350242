import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useForm } from "react-hook-form";
import { CompteRenduFormdata } from "../../../../utils/api/reunion/reunion.type";
import {
  useAddCompteRenduMutation,
  useDeleteCompteRenduMutation,
} from "../../../../utils/api/reunion/reunion.api";
import { useAppSelector } from "../../../../redux/hook";

function UseCrudCompteRendu(
  setModalAddContenuMeeting: any,
  membre_type: any,
  compteRendu?: any
) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre").nullable(),
    description: yup
      .string()
      .label("Description")
      .required()
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    rapport: yup
      .string()
      .label("Rapport")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    point_de_discussion: yup
      .string()
      .label("Point de discussion")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    affaires_nouvelles: yup
      .string()
      .label("Affaires nouvelles")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    annonces: yup
      .string()
      .label("Annonces")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    type_fichier: yup.string().label("Type de fichier").nullable(),
    lien: yup.string().url().label("Lien").nullable(),
    fichier: yup.mixed().label("Fichier").nullable(),
    reunion: yup.string().label("Reunion").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<CompteRenduFormdata>({
    resolver: yupResolver(validationSchema),
  });

  const [description, setDescription] = useState<string>("");
  const [rapport, setRapport] = useState<string>("");
  const [pointDeDiscussion, setPointDeDiscussion] = useState<string>("");
  const [affairesNouvelles, setAffairesNouvelles] = useState<string>("");
  const [annonces, setAnnonces] = useState<string>("");
  const [document, setDocument] = useState<File>();
  const [previewImage, setPreviewImage] = useState(
    compteRendu?.fichier ? getImage(compteRendu?.fichier) : null
  );

  const [addOrUpdateCompteRendu, { isLoading, isSuccess, error, isError }] =
    useAddCompteRenduMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: compteRendu
          ? "Compte rendu modifié avec succès"
          : "Compte rendu ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        setDescription("");
        setRapport("");
        setPointDeDiscussion("");
        setAffairesNouvelles("");
        setAnnonces("");
        setModalAddContenuMeeting(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;
  const onSubmit = (data: any) => {
    data[membre_type] = true;
    data["user"] = userID;

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "fichier") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data submitted", data);
    addOrUpdateCompteRendu({ slug: compteRendu?.slug, data: fd });
  };

  const handleReset = () => {
    if (!compteRendu?.id) {
      reset();
      setDescription("");
      setRapport("");
      setPointDeDiscussion("");
      setAffairesNouvelles("");
      setAnnonces("");
    }
  };

  useEffect(() => {
    if (compteRendu?.id) {
      const fields: (keyof CompteRenduFormdata)[] = [
        "titre",
        "description",
        "rapport",
        "point_de_discussion",
        "affaires_nouvelles",
        "annonces",
        "type_fichier",
        "lien"
      ];

      for (let field of fields) {
        register(field);
        setValue(field, compteRendu[field]);
        if (field === "description") {
          setDescription(compteRendu.description || "");
          setValue("description", compteRendu.description);
        }
        if (field === "rapport") {
          setRapport(compteRendu.rapport || "");
          setValue("rapport", compteRendu.rapport);
        }
        if (field === "point_de_discussion") {
          setPointDeDiscussion(compteRendu.point_de_discussion || "");
          setValue("point_de_discussion", compteRendu.point_de_discussion);
        }
        if (field === "affaires_nouvelles") {
          setAffairesNouvelles(compteRendu.affaires_nouvelles || "");
          setValue("affaires_nouvelles", compteRendu.affaires_nouvelles);
        }
        if (field === "annonces") {
          setAnnonces(compteRendu.annonces || "");
          setValue("annonces", compteRendu.annonces);
        }
      }
    }
  }, []);

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);
  const handleChangeRapport = useCallback((value: string) => {
    setRapport(value);
    setValue("rapport", value);
  }, []);
  const handleChangePointDiscussion = useCallback((value: string) => {
    setPointDeDiscussion(value);
    setValue("point_de_discussion", value);
  }, []);
  const handleChangeAffairesNouvelles = useCallback((value: string) => {
    setAffairesNouvelles(value);
    setValue("affaires_nouvelles", value);
  }, []);
  const handleChangeAnnonces = useCallback((value: string) => {
    setAnnonces(value);
    setValue("annonces", value);
  }, []);

  const handleChangeDocument = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      console.log(e.currentTarget.files[0]);
      setValue("fichier", e.currentTarget.files[0]);
      setDocument(e.currentTarget.files[0]);
    }
  };

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("fichier", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      register("fichier");
      setValue("fichier", file);
    }
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    description,
    rapport,
    pointDeDiscussion,
    affairesNouvelles,
    annonces,
    handleChangeDescription,
    handleChangeRapport,
    handleChangePointDiscussion,
    handleChangeAffairesNouvelles,
    handleChangeAnnonces,
    handleChangeDocument,
    handelImageChange,
  };
}

export default UseCrudCompteRendu;

export function UseDeleteCompteRendu(slug: string) {
  const [deleteData] = useDeleteCompteRenduMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce compte rendu ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Compte rendu supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
