import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../utils/api/auth/auth.api";
import {
  ForgetPasswordFormData,
  ResetPasswordFormData,
} from "../../../utils/api/auth/auth.type";
import { Color } from "../../../utils/theme";
import {
  cleannerError,
  countCharacters,
  countLowercase,
  countNumber,
  countSpecial,
  countUppercase,
  validatePassword,
} from "../../../utils/Utils";
import { useForm } from "react-hook-form";

export function UseResetPasswordForm(openModalConnexionPassword?: () => void) {
  const [resetPassword, { error, isSuccess, isLoading }] =
    useResetUserPasswordMutation();

  const state = useLocation().state as ForgetPasswordFormData;

  const navigate = useNavigate();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        email: yup.string().email().required("Email est obligatoire"),
        code: yup.string().required("Code est obligatoire"),
        new_password: validatePassword(yup),
        new_password_confirm: yup
          .string()
          .oneOf(
            [yup.ref("new_password")],
            "Les mots de passe ne correspond pas"
          ),
      }),
    []
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    setError,
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [password, setPassword] = useState("");
  const [haveMinUppercase, setHaveMinUppercase] = useState(false);
  const [haveMinLowercase, setHaveMinLowercase] = useState(false);
  const [haveMinNumber, setHaveMinNumber] = useState(false);
  const [haveMinCharacter, setHaveMinCharacter] = useState(false);
  const [haveMinSpecialCharacter, setHaveMinSpecialCharacter] = useState(false);

  const passwordHandleChange = (e: any) => {
    var password = e.target.value;
    setPassword(password);
    if (countUppercase(password)) {
      setHaveMinUppercase(true);
    } else {
      setHaveMinUppercase(false);
    }
    if (countLowercase(password)) {
      setHaveMinLowercase(true);
    } else {
      setHaveMinLowercase(false);
    }
    if (countNumber(password)) {
      setHaveMinNumber(true);
    } else {
      setHaveMinNumber(false);
    }
    if (countCharacters(password)) {
      setHaveMinCharacter(true);
    } else {
      setHaveMinCharacter(false);
    }
    if (countSpecial(password)) {
      setHaveMinSpecialCharacter(true);
    } else {
      setHaveMinSpecialCharacter(false);
    }

    setValue("new_password", e.target.value);
  };

  useEffect(() => {
    if (state?.email) {
      setValue("email", state.email);
    } else {
      setTimeout(() => navigate("/mot-de-passe-oublie"), 1);
    }
  }, [state?.email]);

  useEffect(() => {
    if (error) {
      const err = error as any;
      const message =
        err?.data?.error || `Une erreur de statut ${err?.status} est survenue.`;
      setError("code", { message: message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        iconColor: Color.success,
        confirmButtonColor: Color.success,
        title: "Mot de passe modifié avec succès!",
      }).then(async (result) => {
        if (result?.isConfirmed) {
          navigate("/");
        }
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors, 3000);
    }
  }, [errors]);
  return {
    register,
    errors,
    onSubmit: handleSubmit(resetPassword),
    clearErrors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    password,
  };
}
