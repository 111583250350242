import React, { ChangeEvent, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import {
  AffectationFormData,
  IAffectation,
} from "../../../utils/api/affectation/affectation.type";
import {
  useAddOrEditAffectationMutation,
  useDeleteAffectationMutation,
} from "../../../utils/api/affectation/affectation.api";
import { useGetMembresResponsableParoissienQuery } from "../../../utils/api/membre/membre.api";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/Utils";
import { useGetParoissesQuery } from "../../../utils/api/paroisses/paroisses.api";
import { useDelete } from "../../../utils/helpers";
import { FaTrash } from "react-icons/fa";

function UseCrudAffectation(affectation?: IAffectation) {
  const validationSchema = yup.object().shape({
    affection_actuelle: yup
      .string()
      .required("Affectation actuelle est obligatoire")
      .label("Affectation actuelle"),
    date_debut: yup
      .string()
      .required("Date de début est obligatoire")
      .label("Date de début"),
    affectation_prochaine: yup
      .string()
      .nullable()
      .label("Affectation prochaine"),
    responsable: yup
      .string()
      .required("Responsable est obligatoire")
      .label("Responsable")
      .nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
    control,
  } = useForm<AffectationFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateAffectation, { isLoading, isSuccess, error, isError }] =
    useAddOrEditAffectationMutation();

  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [optionsAffActuel, setOptionsAffActuel] = useState<
    { label: string; value: number }[]
  >([]);
  const [optionsAffProchain, setOptionsAffProchain] = useState<
    { label: string; value: number }[]
  >([]);
  const [option, setOption] = useState<
    { label: string; value: number } | undefined
  >();
  const [optionAffActuel, setOptionAffActuel] = useState<
    { label: string; value: number } | undefined
  >();
  const [optionAffProchain, setOptionAffProchain] = useState<
    { label: string; value: number } | undefined
  >();
  const { data: responsable = { results: [] } } =
    useGetMembresResponsableParoissienQuery({
      limit: 1000,
    });
  const { data: paroisses = { results: [] } } = useGetParoissesQuery({
    limit: 1000,
  });

  const navigate = useNavigate();
  let path = "/admin/affectation";

  useEffect(() => {
    if (responsable?.results?.length) {
      setOptions(
        responsable?.results?.map((el) => {
          return {
            label: el?.prenom + " " + el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [responsable]);

  useEffect(() => {
    if (paroisses?.results?.length) {
      setOptionsAffActuel(
        paroisses?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
      setOptionsAffProchain(
        paroisses?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [paroisses]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: affectation
          ? "Affectation modifiée avec succès !"
          : "Affectation ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!affectation) {
          reset();
        }
        navigate(path);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    // console.log("data submitted", data);
    await addOrUpdateAffectation({ slug: affectation?.slug, data });
  };

  const handleSelectResponsable = (val) => {
    setOption(val);
    setValue("responsable", val?.value);
  };
  const handleSelectAffActuel = (val) => {
    setOptionAffActuel(val);
    setValue("affection_actuelle", val?.value);
  };
  const handleSelectAffProchain = (val) => {
    setOptionAffProchain(val);
    setValue("affectation_prochaine", val?.value);
  };

  const handleReset = () => {
    if (!affectation) {
      reset();
    }
  };

  useEffect(() => {
    if (affectation?.id) {
      console.log({ affectation });
      let fields: (keyof AffectationFormData)[] = [
        "id",
        "slug",
        "affection_actuelle",
        "affectation_prochaine",
        "date_debut",
        "responsable",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, affectation[field as any]);
      }
      if (affectation?.responsable) {
        setOption({
          label:
            affectation?.responsable?.prenom +
            " " +
            affectation?.responsable?.nom,
          value: affectation?.responsable?.id,
        });
        setValue("responsable", affectation.responsable.id);
      } else {
        setOption(undefined);
        setValue("responsable", undefined); // or setValue("affection_actuelle", undefined);
      }

      if (affectation?.affection_actuelle) {
        setOptionAffActuel({
          label: affectation?.affection_actuelle?.nom,
          value: affectation?.affection_actuelle?.id,
        });
        setValue("affection_actuelle", affectation.affection_actuelle.id);
      } else {
        setOptionAffActuel(undefined);
        setValue("affection_actuelle", undefined); // or setValue("affection_actuelle", undefined);
      }
      if (affectation?.affectation_prochaine) {
        setOptionAffProchain({
          label: affectation?.affectation_prochaine?.nom,
          value: affectation?.affectation_prochaine?.id,
        });
        setValue("affectation_prochaine", affectation.affectation_prochaine.id);
      } else {
        setOptionAffProchain(undefined);
        setValue("affectation_prochaine", undefined); // or setValue("affectation_prochaine", undefined);
      }
    }
  }, [affectation]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    handleSelectResponsable,
    handleSelectAffActuel,
    handleSelectAffProchain,
    Controller,
    control,
    option,
    options,
    optionAffActuel,
    optionsAffActuel,
    optionAffProchain,
    optionsAffProchain,
  };
}

export default UseCrudAffectation;

export function DeleteAffectation({ item }: { item: IAffectation }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteAffectationMutation();
  const onDelete = useDelete<IAffectation>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette affectation",
    successMessage: "Affectation supprimée",
  });
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={onDelete}
      >
        <FaTrash />
      </button>
    </div>
  );
}
