import React, { useState } from "react";
import "./HeaderAdmin.css";
import { NavLink } from "react-router-dom";
import SidebarMobile from "../SidebarAdmin/SidebarMobile";
import "../SidebarAdmin/SidebarAdmin.css";
import { IoNotifications } from "react-icons/io5";
import { useAppSelector } from "../../../../redux/hook";
import { createMarkup, getAvatar } from "../../../../utils/Utils";
import {
  useGetNotificationQuery,
  useUpdateReadNotificationMutation,
} from "../../../../utils/api/notification/notification.api";
import { INotification } from "../../../../utils/api/notification/notification.type";
import moment from "moment";

function getRoute(item: INotification) {
  let route = "/";
  if (item?.type === "pasteur_creation" || item?.type === "pasteur") {
    route = `/admin/pasteurs/${item?.data?.slug}`;
  }
  if (item?.type === "message_contact") {
    route = "/admin/messages";
  }

  if (item?.type === "creation_membre") {
    if (item?.data?.conseil_administration) {
      route = `/admin/conseil-administration/${item?.data?.slug}`;
    }

    if (item?.data?.conseil_pastoral) {
      route = `/admin/conseil-pastoral/${item?.data?.slug}`;
    }
    if (item?.data?.office_pastoral) {
      route = `/admin/office-pastoral/${item?.data?.slug}`;
    }
  }
  if (item?.type === "membre") {
    if (item?.content?.toLowerCase().includes("responsable paroissien")) {
      route = `/admin/responsable-paroissien/${item?.data?.slug}`;
    }

    if (item?.content?.toLowerCase().includes("un membre du conseil administration")) {
      route = `/admin/conseil-administration/${item?.data?.slug}`;
    }
  }
  if (item?.type === "chef_diocese") {
      route = `/admin/responsable-dioceses/${item?.data?.slug}`;
  }

  return route;
}

const HeaderAdmin: React.FC = () => {
  const { user } = useAppSelector((state) => state.user);
  const [page] = useState(1);
  const [perPage] = useState(100);

  var [showNotifications, setShowNotifications] = React.useState(false);
  const [item, setItem] = useState<INotification>();
  const [updateNotif] = useUpdateReadNotificationMutation();

  var onShowAndHideNotification = () => {
    if (showNotifications === false) {
      setShowNotifications(true);
    } else {
      setShowNotifications(false);
    }
  };

  const { data = { results: [], count: 0 } } = useGetNotificationQuery({
    limit: perPage,
    page,
  });
  const onUpdateReadNotification = (notif: INotification) => {
    setShowNotifications(false);
    setItem(notif);
    updateNotif({ slug: notif.slug, read: true });
  };
  

  return (
    <div className="admin-header-component">
      <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
        <div className="headering-admin">
          <div className="header-others-items">
            <div className="container-info-user-connect-header">
              <div className="container-menu-header-admin gap-3">
                <div
                  className="notification-item-container linkable position-relative"
                  onClick={() => onShowAndHideNotification()}
                >
                  <div className="notification-item-content">
                    <IoNotifications className="notif-icon" />
                    {data?.results?.filter((x) => !x.read)?.length ==
                    0 ? null : (
                      <span className="notification-counter">
                        {data?.results?.filter((x) => !x.read)?.length}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="connected-user-container" id="header-user-admin">
                <div className="connected-user-content ml-auto">
                  <div className="dropdown dis-flex">
                    <NavLink
                      className="user-acount-dashboard-header dropdown-toggle btn"
                      to="/admin/profil"
                    >
                      <div className="user-acount-dashboard-header-display g-2">
                        <img
                          src={getAvatar(user?.avatar)}
                          className="user-avatar"
                          alt="User Avatar"
                        />
                      </div>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="hiddenable-nav-mobile-container no-view-desktop">
                <div className="hiddenable-nav-mobile-content">
                  <div className="container-nav-mobile">
                    <SidebarMobile />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showNotifications && (
          <div
            className={
              data?.results?.filter((el) => el?.read !== true)?.length
                ? "header-hiddenable-notification-section"
                : "header-hiddenable-notification-section"
            }
          >
            <ul
              className={
                data?.results?.filter((el) => el?.read !== true)?.length
                  ? "header-hiddenable-notification-ul"
                  : "header-hiddenable-notification-ul"
              }
            >
              {data?.results?.filter((x) => x.read !== true)?.length == 0 ? (
                <li
                  className="header-hiddenable-notification-li"
                  style={{ fontSize: 16 }}
                >
                  <div className="notification-item-link d-flex no-link">
                    <div className="content-text-notif ps-2">
                      <p className="text-notif mb-1">
                        Il n'y a aucune notification .
                      </p>
                    </div>
                  </div>
                </li>
              ) : (
                data?.results
                  ?.filter((x) => x.read !== true)
                  ?.map((item) => (
                    <li
                      key={item?.slug}
                      className="header-hiddenable-notification-li"
                      onClick={() => onUpdateReadNotification(item)}
                    >
                      <NavLink
                        to={getRoute(item)}
                        className="notification-item-link d-flex no-link"
                        state={item}
                      >
                        <div className="content-text-notif ps-2">
                          <p
                            className="text-notif mb-1"
                            dangerouslySetInnerHTML={createMarkup(
                              item?.content
                            )}
                          />
                          <p className="text-date-notif mb-0">
                            {moment(item?.created_at).format(
                              "DD/MM/yy - HH:mm"
                            )}
                          </p>
                        </div>
                      </NavLink>
                    </li>
                  ))
              )}
            </ul>
          </div>
        )}
      </nav>
    </div>
  );
};

export default HeaderAdmin;
