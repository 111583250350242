/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BiTrash } from "react-icons/bi";
import { MdCloudUpload } from "react-icons/md";
import { getImage } from "../../utils/Utils";
import { IconDelete3 } from "../shared/Icons";
import ErrorMessage from "./ErrorMessage";

function MyDropzone({
  setImages,
  label,
  maxFile = 1,
  acceptFile,
  withVideo = false,
  required = false
}: {
  setImages: any;
  label?: string | any;
  maxFile?: number;
  acceptFile?: any;
  withVideo?: boolean;
  required?: boolean;
}) {
  const [maxFileError, setMaxFileError] = useState<string>("");
  const onDrop = useCallback((acceptedFiles: any, fileRejections: any[]) => {
    if (acceptedFiles) {
      setImages(acceptedFiles[0]);
      console.log(acceptedFiles[0]);
    }
    fileRejections.forEach((file: { errors: any[] }) => {
      file.errors.forEach((err: { code: string }) => {
        if (err.code === "too-many-files") {
          setMaxFileError("Maximum 4 images");
        }
        if (err.code === "file-invalid-type") {
          setMaxFileError("Types de fichiers non prises en charge");
        }
      });
    });
  }, []);

  let acceptF = acceptFile
    ? acceptFile
    : {
        "image/jpeg": [],
        "image/png": [],
        "image/jpg": [],
        "image/gif": [],
        "video/mp4": [],
        "application/pdf": [],
      };
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    maxFiles: maxFile,
    multiple: true,
    maxSize: 209715200,
    accept: acceptF,
    // maxSize: 1000,
  });
  // let acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
  // 	<div className="col-3" key={file.path}>
  // 		<div className="image-item">
  // 			<img src={URL.createObjectURL(file)} alt={file.path} loading="lazy" />
  // 		</div>
  // 	</div>
  // ));
  const fileRejectionItems = fileRejections.map(
    ({ errors }: { errors: any }, index: any) => {
      return (
        <div key={index}>
          {/* {file.size} bytes */}
          <ul className="p-0">
            {errors.map((e: any) => (
              <li
                key={e.code}
                style={{ listStyle: "none" }}
                className="text-danger"
              >
                {e.code === "file-too-large" &&
                  `L'image ${index + 1} est trop grande`}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  );

  useEffect(() => {
    setTimeout(() => setMaxFileError(""), 3000);
  });
  return (
    <section className="dropzone">
      {!!label && (
        <label
          htmlFor="pro-images"
          className="form-label form-label-modal-custom mb-2"
        >
          {label}
          {required && (
            <span className="text-danger" style={{ fontSize: 15 }}>
              *
            </span>
          )}
        </label>
      )}
      <label
        {...getRootProps()}
        style={{
          overflow: "hidden",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          padding: "3rem 15px",
          background: "#EEF0F4",
          border: "1px dashed #0C98D4",
          borderRadius: "12px",
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
          textAlign: "center",
          color: "#0C98D4",
        }}
        htmlFor="pro-images"
        onClick={(e) => e?.stopPropagation()}
      >
        <input
          {...getInputProps()}
          type="file"
          id="pro-images"
          name="pro-images"
          accept="image/*"
        />
        <div className="start-label-input-choose-file">
          <div className="flex-c">
            <MdCloudUpload style={{ color: "#0C98D4", fontSize: "30px" }} />
          </div>
          <span className="first-label-input-choose-file">
            Déposez maximum {maxFile} photo(s) {!!withVideo && "ou vidéo(s)"}{" "}
            {maxFile > 1 ? "s" : ""} ici ou cliquez pour télécharger.
          </span>
          <br />
          <span className="second-label-input-choose-file">
            Format acceptés : JPG, PNG, GIF, TIFF, BMP,
            {!!withVideo && "MP4, MPG, AVI, M4A,"} inférieurs à 200Mo.
          </span>
        </div>
      </label>
      {maxFileError && <ErrorMessage message={maxFileError} />}
      <div className="row pt-4">
        {/* {acceptedFileItems} */}
        {!maxFileError && fileRejectionItems}
      </div>
    </section>
  );
}

export default MyDropzone;

type TImageGrid = {
  images: File[] | [];
  deleteFile?: (i: number, file: any) => void;
};

export const ImageGrid = ({ images, deleteFile }: TImageGrid) => {
  return (
    <>
      {images?.length
        ? images.map((file: any, idx: number) => (
            <div className="col-md-3 col-xl-3" key={idx}>
              <div className="product-img-card">
                <button
                  className="no-style-btn del-product-img"
                  title="Supprimer"
                  type="button"
                  onClick={() => {
                    if (deleteFile) {
                      deleteFile(idx, file);
                    }
                  }}
                >
                  <IconDelete3 />
                </button>
                <img
                  src={
                    file?.picture
                      ? getImage(file?.picture)
                      : URL.createObjectURL(file)
                  }
                  alt="product-img"
                  style={{
                    width: "100%",
                    height: "15vw",
                    objectFit: "fill",
                  }}
                />
              </div>
            </div>
          ))
        : ""}
    </>
  );
};

export const ImagesView = ({
  images,
  setImages,
}: {
  images: any;
  setImages: any;
}) => {
  //   const [deleteImage] = useDeletePictureMutation();
  const deleteFile = (index: number, item: any) => {
    const newArr = [...images];
    newArr.splice(index, 1);
    setImages(newArr);

    if (item && item?.image) {
      //   deleteImage(item?.slug);
    }
  };
  return (
    <div className="row">
      {images.length
        ? images.map((file: any, i: number) => (
            <div className="col-md-3 mb-1" key={file?.slug || i}>
              <div className="image-item properti-img-card">
                <button
                  className="no-style-btn del-product-img"
                  title="Supprimer"
                  type="button"
                  onClick={() => deleteFile(i, file)}
                >
                  <BiTrash />
                </button>
                <img
                  src={
                    file?.id ? getImage(file?.image) : URL.createObjectURL(file)
                  }
                  alt={file.slug}
                  loading="lazy"
                  className="img-proprety"
                  //   id="overlay"
                  //   style={{
                  //     width: "100%",
                  //     height: "15vw",
                  //     objectFit: "fill",
                  //   }}
                />
              </div>
            </div>
          ))
        : ""}
    </div>
  );
};

export const ImageItem = ({ image }) => {
  return (
    <div className="col-md-3 mb-1">
      <div className="image-item properti-img-card w-100">
        <img
          src={!image?.name ? getImage(image) : URL.createObjectURL(image)}
          alt={"image"}
          loading="lazy"
          className="img-proprety w-100"
          
        />
      </div>
    </div>
  );
};
