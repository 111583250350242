import React from "react";
import Skeleton from "react-loading-skeleton";

function PreambuleAdminSkeleton() {
  return (
    // <div
    //         className="col-lg-3 col-md-6 col-item-item text-center d-flex mb-3"
    //         key={i}
    //       >
    //         <div className="content-col-item-item w-100">
    //           <div className="content-img-profil-item py-3">
    //             <Skeleton circle className="pasteur-img-avatar" />
    //           </div>
    //           <Skeleton width={100} height={15} className="name-item-admin" />

    //           <div className="container-btn-viez-profil-item pt-3">
    //             <Skeleton width={90} height={35} />
    //           </div>
    //         </div>
    //       </div>
    <div>
      <div className="container-list-preambule-admin my-4">
        <ol>
          <Skeleton
            width={150}
            height={15}
            className="list-item-preambule-admin"
          />
        </ol>
      </div>
      <div className="container-page-preambule-admin">
        <div className="container-text-preambule-admin pt-0">
          {Array(5)
            .fill(5)
            .map((i) => (
              <div className="mb-3" key={i}>
                <Skeleton height={15} className="text-preambule-admin" />
                <Skeleton height={15} className="text-preambule-admin" />
                <Skeleton height={15} className="text-preambule-admin" />
                <Skeleton
                  width={300}
                  height={15}
                  className="text-preambule-admin"
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default PreambuleAdminSkeleton;
