import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ConditionFormdata, ICondition } from "./condition.type";
import { ApiBaseUrl } from "../../http";

const ConditionApi = createApi({
  reducerPath: "conditionApi",
  tagTypes: ["condition"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addCondition: build.mutation<
      ICondition,
      { slug?: string; data: ICondition }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `conditions/${slug}/` : `conditions/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["condition"],
    }),
    getConditions: build.query<PaginationResults<ICondition>, TypeQuery>({
      query: () => `conditions/`,
      providesTags: ["condition"],
    }),
    deleteCondition: build.mutation<ConditionFormdata, string>({
      query: (slug) => ({
        url: `conditions/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["condition"],
    }),
    getConditionsFront: build.query<PaginationResults<ICondition>, TypeQuery>({
      query: () => `conditionbyvisitor/`,
      providesTags: ["condition"],
    }),
  }),
});

export default ConditionApi

export const {
  useAddConditionMutation,
  useGetConditionsQuery,
  useDeleteConditionMutation,
  useGetConditionsFrontQuery
} = ConditionApi;