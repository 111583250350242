import React from "react";
import Modal from "react-bootstrap/Modal";
import { Input } from "../../../common/Input";
import UseCrudGrade from "../requestForm/UseCrudGrade";
import { IGrades } from "../../../../utils/api/grades/grades.type";
import { SelectInput } from "../../../common/SelectInput";

const AddGradeModal = ({
  modalAddGrade,
  setModalAddGrade,
  grade,
  setGrade,
}: {
  modalAddGrade: any;
  setModalAddGrade: any;
  grade?: IGrades;
  setGrade?: any;
}) => {
  function closeModalAddGrade() {
    setModalAddGrade(false);
  }

  const { register, onSubmit, errors, isLoading, handleReset } = UseCrudGrade(
    setModalAddGrade,
    grade,
    setGrade
  );
  return (
    <Modal
      show={modalAddGrade}
      onHide={() => {
        handleReset();
        closeModalAddGrade();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      size="lg"
      centered
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">Nouveau grade</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="mt-4" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Titre"
                  id="titre"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors.titre}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <SelectInput
                  label="Pour"
                  id="sexe"
                  {...register("sexe")}
                  customClassInput="form-control-modal-custom"
                  error={errors.sexe}
                  options={[
                    { label: "Homme", value: "homme" },
                    { label: "Femme", value: "femme" },
                  ]}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <SelectInput
                  label="Type de grade"
                  id="type"
                  {...register("type")}
                  customClassInput="form-control-modal-custom"
                  error={errors.type}
                  options={[
                    { label: "Fidèle", value: "fidele" },
                    { label: "Clergé", value: "clerge" },
                  ]}
                  required
                />
              </div>
              <div className="col-md-6 offset-md-6 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>{grade ? "Modification..." : "Ajout..."}</span>
                    </>
                  ) : grade ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddGradeModal;
