import React, {useState} from "react";


export const Wrapper = (
    {children, customClass}
    : 
    {children?: any, customClass?: string}
) => {
    return(
        <div className={`custom-big-wrapper ${customClass}`}>
            {children}
        </div>
    )
}

export const HabilitationSmallCard = (
    {text, idlabel, isChecked}
    : 
    {text?: string, idlabel?: string, isChecked?: boolean}
) => {
    const [check, setCheck] = useState(false)
    return(
        <label 
            id={idlabel}
            onClick={() => setCheck(!check)}
            className={`habilitation-sm-card `}
        >
            {   
            isChecked === check ?
                <input 
                    type="checkbox" 
                    checked
                    name="habilitation" 
                    id="habilitation" 
                />
            :
                <input 
                    type="checkbox" 
                    name="habilitation" 
                    id="habilitation" 
                />
            }
            <span className="habilitation-text">
                {text}
            </span>
        </label>
    )
}


export const HabilitationsData = [
    {
        id: 1,
        habilitation: "Dashboard",
        idlabel: "dash"
    },
    {
        id: 2,
        habilitation: "Fidèles",
        idlabel: "fidel"
    },
    {
        id: 3,
        habilitation: "Demandes",
        idlabel: "deman"
    },
    {
        id: 4,
        habilitation: "Paroisses",
        idlabel: "par"
    },
    {
        id: 5,
        habilitation: "Actualités",
        idlabel: "act"
    },
    {
        id: 6,
        habilitation: "Cotisations",
        idlabel: "cot"
    },
    {
        id: 7,
        habilitation: "Formations",
        idlabel: "form"
    },
    {
        id: 8,
        habilitation: "Messages",
        idlabel: "mess"
    },
    {
        id: 9,
        habilitation: "Paramètres",
        idlabel: "param"
    }
]