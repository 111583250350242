import React from "react";
import { createMarkup } from "../../../../../utils/Utils";
import { Map, Marker } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";

function Propos(props) {
  let {paroisse} = props
  return (
    <>
      <p
        className="text-content mb-4"
        dangerouslySetInnerHTML={createMarkup(
          paroisse?.description || ""
        )}
      />

      <div className="localisation pt-5">
        <h4 className="cs-title">Localisation</h4>

        <div
          style={{ height: "350px", width: "100%" }}
          className="container-map mt-3 position-relative"
        >
          {!!paroisse && (
            <Map
              google={props?.google}
              initialCenter={{
                lat: parseFloat(
                  paroisse?.latitude || paroisse?.pays?.latitude
                ),
                lng: parseFloat(
                  paroisse?.longitude || paroisse?.pays?.longitude
                ),
              }}
              zoom={8}
            >
              <Marker
                position={{
                  lat: parseFloat(
                    paroisse?.latitude || paroisse?.pays?.latitude
                  ),
                  lng: parseFloat(
                    paroisse?.longitude ||
                      paroisse?.pays?.longitude
                  ),
                }}
              />
            </Map>
          )}
        </div>
      </div>
    </>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
})(Propos);

{
  /* <iframe 
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1447.510476350784!2d-1.5162358137365932!3d43.48103311278324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd51401cdc979735%3A0xbdbc5ff838b9ab48!2s64600%20Anglet%2C%20France!5e0!3m2!1sfr!2ssn!4v1693404368644!5m2!1sfr!2ssn`}
        style={{border:0}} 
        allowFullScreen={true} 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
    >
    </iframe> */
}
