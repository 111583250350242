import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useForm } from "react-hook-form";
import { ReunionFormdata } from "../../../../utils/api/reunion/reunion.type";
import {
  useAddReunionMutation,
  useDeleteReunionMutation,
} from "../../../../utils/api/reunion/reunion.api";

function UseCrudReunion(
  setModalAddMeeting: any,
  membre_type?: any,
  reunion?: any
) {
  const validationSchema = yup.object().shape({
    date: yup.string().required().label("La date"),
    heure_debut: yup.string().required().label("Heure de début"),
    heure_fin: yup.string().required().label("Heure de fin").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<ReunionFormdata>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateReunion, { isLoading, isSuccess, error, isError }] =
    useAddReunionMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: reunion
          ? "Réunion modifiée avec succès"
          : "Réunion ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        setModalAddMeeting(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    data[membre_type] = true;

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    if (data?.date) {
      let dataDate = new Date(data.date);
      dataDate.setHours(0, 0, 0, 0);

      if (dataDate < today && !reunion) {
        return setError("date", { message: "Veuillez entrer une date valide" });
      }
    }
    if (data?.heure_fin === "") {
      data["heure_fin"] = null;
    }
    console.log("data submitted", data);
    addOrUpdateReunion({ slug: reunion?.slug, data });
  };

  const handleReset = () => {
    if (!reunion) {
      reset();
    }
  };

  useEffect(() => {
    if (reunion?.id) {
      const fields: (keyof ReunionFormdata)[] = [
        "date",
        "heure_debut",
        "heure_fin",
      ];
      for (let field of fields) {
        if (field !== "heure_fin") {
          register(field);
          setValue(field, reunion[field]);
        } else if (field === "heure_fin" && reunion["heure_fin"] === null) {
          register(field);
        } else {
          register(field);
          setValue(field, reunion[field]);
        }
      }
    }
  }, [reunion]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudReunion;

export function UseDeleteReunion(slug: string) {
  const [deleteData] = useDeleteReunionMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette réunion ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Réunion supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
