import React, { useState } from "react";
import "./ConseilPastoralAdmin.css";
import { NavLink } from "react-router-dom";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import MembresTabs from "./tabs/MembresTabs";
import ReunionsTabs from "./tabs/ReunionsTabs";
import AddContenuMeetingModal from "../ConseilAdministration/modal/AddContenuMeetingModal";
import AddMeetingModal from "../ConseilAdministration/modal/AddMeetingModal";

const steps = [
  { id: "membres", Component: MembresTabs },
  { id: "reunions", Component: ReunionsTabs },
];

const tabs = ["Membres", "Réunions"];
function ConseilPastoralAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const [modalAddContenuMeeting, setModalAddContenuMeeting] = useState(false);
  const [modalAddMeeting, setModalAddMeeting] = useState(false);
  const [membreType, setMembreType] = useState("");

  function openModalAddContenuMeeting() {
    setModalAddContenuMeeting(true);
  }
  function openModalAddMeeting() {
    setModalAddMeeting(true);
  }

  const props = {
    openModalAddMeeting,
    setModalAddMeeting,
    modalAddMeeting,
    setModalAddContenuMeeting,
    q_type: "conseil_pastoral",
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Conseil pastoral</h2>
        <div className="content-graph-admin">
          <div className="eglise-dash-tabs mb-0">
            <ul className="tabs-container tabs-container-admin">
              {tabs.map((tab: any, key: any) => (
                <li
                  className={`tab-item  ${
                    index === key && "tab-active-item-admin"
                  }`}
                  key={key}
                  onClick={() => go(key)}
                  role="button"
                >
                  {tab}
                </li>
              ))}
            </ul>
          </div>
          <div className="content-btn-theme-title">
            {index === 0 ? (
              <>
                <NavLink
                  to="/admin/conseil-pastoral/add-member"
                  className="btn btn-theme-admin"
                  state={"conseil_pastoral"}
                >
                  <span>Nouveau membre</span>
                </NavLink>
              </>
            ) : (
              <div className="row align-items-center">
                <div className="col-lg-9 col-md-12">
                  <div className="btn-nouveau-contenu-container">
                    <div className="">
                      <button
                        className="btn btn-theme-admin"
                        onClick={() => {
                          setMembreType("conseil_pastoral");
                          openModalAddContenuMeeting();
                        }}
                      >
                        <span>Nouveau contenu</span>
                      </button>
                    </div>
                    <div className="">
                      <button
                        className="btn btn-theme-admin"
                        onClick={() => {
                          setMembreType("conseil_pastoral");
                          openModalAddMeeting();
                        }}
                      >
                        <span>Programmer une réunion</span>
                      </button>
                    </div>
                  </div>

                  <AddContenuMeetingModal
                    modalAddContenuMeeting={modalAddContenuMeeting}
                    setModalAddContenuMeeting={setModalAddContenuMeeting}
                    membre_type={membreType}
                    compteRendu={undefined}
                  />
                  <AddMeetingModal
                    modalAddMeeting={modalAddMeeting}
                    setModalAddMeeting={setModalAddMeeting}
                    membre_type={membreType}
                    reunion={undefined}
                  />
                </div>
                <div className="col-lg-3 col-md-6">
                  <h2 className="title-tab-reunion mb-0">
                    Prochaines réunions
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="tabs-component-container">
          <Component {...props} />
        </div>
      </div>
    </div>
  );
}

export default ConseilPastoralAdmin;
