import React from 'react'
import "./ConstitutionBleuAdmin.css"
import ConstitutionBleuAdminTable from "./ConstitutionBleuAdminTable/ConstitutionBleuAdminTable"
import { NavLink } from 'react-router-dom'

function ConstitutionBleuAdmin() {

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">
        Constitution bleue
        </h2>
        <div className="content-graph-admin">
            <div className='content-btn-theme-title'>
              <NavLink 
                to="/admin/constitution-bleu/ajouter-document" 
                className="btn btn-theme-admin"
              >
                  Upload un fichier 
              </NavLink>
            </div>
            <div className="admin-table mt-5">
              <ConstitutionBleuAdminTable />
            </div>
        </div>
      </div>
    </div>
  )
}

export default ConstitutionBleuAdmin
