import React, { useEffect, useState } from "react";
import { Datas, DeleteCondition } from "./ConditionsUtilisation";
import { useGetConditionsQuery } from "../../../../utils/api/contenu/condition.api";
import { NavLink } from "react-router-dom";
import { createMarkup } from "../../../../utils/Utils";
import { AlertInfo } from "../../../common/Alert";
import { BiEditAlt } from "react-icons/bi";


function PolitiquesConfidentialite() {
  const [policy, setPolicy] = useState<any>();
  const { data, isLoading } = useGetConditionsQuery({});
  useEffect(() => {
    let filterdeData = data?.results?.filter(
      (item) => item?.type === "policy"
    );
    setPolicy(filterdeData);
  }, [data]);


  
  return (
    <div className="content-graph-admin">
      <ol>
      {policy?.length === 0 && <AlertInfo message='Pas de données' />}
      {policy?.map(condition => (
        <li key={condition?.id} className="mb-5 cond-utilisation-ol-custom-li">
          <span>
            {condition?.titre && condition?.titre}
          </span>
          <div className="cond-utilisation-ol-item-container">
            <p className="text-cgu-admin pb-2"
            dangerouslySetInnerHTML={createMarkup(condition?.text)}
            />
          </div>
          <div className='eglise-cond-utilisation-ol-btn-container'>
            <NavLink
                to="/admin/ajouter-politique-confidentialite"
                className='btn eglise-cond-utilisation-ol-btn-edit'
                state={condition}
            >
                <BiEditAlt />
            </NavLink>
            <DeleteCondition item={condition} />
        </div>
        </li>
      ))}
     
      </ol>
    </div>
  );
}

export default PolitiquesConfidentialite;
