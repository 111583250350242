import React, { useState } from "react";
import { createMarkup, useLocationState } from "../../../utils/Utils";
import { ICompteRendu } from "../../../utils/api/reunion/reunion.type";
import BreadCrumb from "../../shared/BreadCrumb";
import { Link } from "react-router-dom";
import { ApiBaseUrl } from "../../../utils/http";
import ReactPlayer from "react-player";
import Play from "../../../assets/icons/play.svg";
import AudioPlayer, { ProgressUI, ActiveUI } from "react-modern-audio-player";

function DetailsCompteRendu() {
  const item = useLocationState<ICompteRendu>(undefined);
  console.log("item", item);

  const [activeUI, setActiveUI] = useState<ActiveUI>({ all: true });
  const [progressType, setProgressType] = useState<ProgressUI>("bar");
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Contenu" secondPage="Compte rendu" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="row">
            <div className="col-md-12">
              <div className="details-cr-titre-page mb-3">
                Compte rendu reunion
              </div>
              <div className="details-cr-titre-cr mb-4">{item?.titre}</div>
              {!!item?.lien && (
                <div className="details-cr-link-label-container mb-4">
                  <div className="details-cr-link-label">Lien: </div>
                  <Link
                    to={item?.lien}
                    className="details-cr-link"
                    target="_blank"
                  >
                    {item?.lien}
                  </Link>
                </div>
              )}
              {!!item?.description && (
                <p
                  className="details-cr-description mb-4"
                  dangerouslySetInnerHTML={createMarkup(item?.description)}
                />
              )}
              {!!item?.rapport && (
                <>
                  <div className="details-cr-label mb-3">Rapport:</div>
                  <p
                    className="details-cr-description mb-4"
                    dangerouslySetInnerHTML={createMarkup(item?.rapport)}
                  />
                </>
              )}
              {!!item?.point_de_discussion && (
                <>
                  <div className="details-cr-label mb-3">
                    Points de discussion:
                  </div>
                  <p
                    className="details-cr-description mb-4"
                    dangerouslySetInnerHTML={createMarkup(
                      item?.point_de_discussion
                    )}
                  />
                </>
              )}
              {!!item?.affaires_nouvelles && (
                <>
                  <div className="details-cr-label mb-3">
                    Affaires nouvelles:
                  </div>
                  <p
                    className="details-cr-description mb-4"
                    dangerouslySetInnerHTML={createMarkup(
                      item?.affaires_nouvelles
                    )}
                  />
                </>
              )}
              {!!item?.annonces && (
                <>
                  <div className="details-cr-label mb-3">Annonces:</div>
                  <p
                    className="details-cr-description mb-4"
                    dangerouslySetInnerHTML={createMarkup(item?.annonces)}
                  />
                </>
              )}

              {!!item?.fichier && (
                <>
                  <div className="details-cr-label mb-3">Fichier:</div>
                  <div className="details-cr-description mb-3">
                    {item?.type_fichier === "pdf" ? (
                      <iframe
                        src={ApiBaseUrl + item?.fichier}
                        frameBorder="0"
                        width={`100%`}
                        height={600}
                        allowFullScreen
                        seamless
                        title="doc"
                      ></iframe>
                    ) : item?.type_fichier === "video" ? (
                      <ReactPlayer
                        className="custom-video-archive mb-3"
                        controls
                        url={ApiBaseUrl + item?.fichier}
                        playing={false}
                        playIcon={<img src={Play} alt="video" />}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <AudioPlayer
                        activeUI={{
                          ...activeUI,
                          progress: progressType,
                        }}
                        playList={[
                          {
                            src: ApiBaseUrl + item?.fichier,
                            id: 1,
                          },
                        ]}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsCompteRendu;
