import React from "react";
import ParametreGradesTable from "../ParametreTable/ParametreGradesTable";
function ParametreGradesAdmin({
  modalAddGrade,
  setModalAddGrade,
  openModalAddGrade,
}) {
  return (
    <div className="content-graph-admin">
      <div className="admin-table">
        <ParametreGradesTable
          modalAddGrade={modalAddGrade}
          setModalAddGrade={setModalAddGrade}
          openModalAddGrade={openModalAddGrade}
        />
      </div>
    </div>
  );
}

export default ParametreGradesAdmin;
