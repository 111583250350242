import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ApiBaseUrl } from "../../http";
import { CompteRenduFormdata, ICompteRendu, IReunion, ReunionFormdata } from "./reunion.type";
import { QueryUrl } from "../../Utils";

const ReunionApi = createApi({
  reducerPath: "reunionApi",
  tagTypes: ["reunion", "compte_rendu"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addReunion: build.mutation<
      IReunion,
      { slug?: string; data: IReunion }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `reunions/${slug}/` : `reunions/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["reunion"],
    }),
    getReunions: build.query<PaginationResults<IReunion>, TypeQuery>({
      query: (query) => QueryUrl("reunions/", query),
      providesTags: ["reunion"],
    }),
    deleteReunion: build.mutation<ReunionFormdata, string>({
      query: (slug) => ({
        url: `reunions/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["reunion"],
    }),

    
    addCompteRendu: build.mutation<
      ICompteRendu,
      { slug?: string; data: ICompteRendu | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `compte_rendus/${slug}/` : `compte_rendus/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["compte_rendu"],
    }),
    getCompteRendus: build.query<PaginationResults<ICompteRendu>, TypeQuery>({
      query: (query) => QueryUrl("compte_rendus/", query),
      providesTags: ["compte_rendu"],
    }),
    deleteCompteRendu: build.mutation<CompteRenduFormdata, string>({
      query: (slug) => ({
        url: `compte_rendus/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["compte_rendu"],
    }),


  }),
});

export default ReunionApi

export const {
  useAddReunionMutation,
  useDeleteReunionMutation,
  useGetReunionsQuery,
  useAddCompteRenduMutation,
  useDeleteCompteRenduMutation,
  useGetCompteRendusQuery
} = ReunionApi;