/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Ceremonies,
  CeremoniesFormData,
} from "../../../../utils/api/ceremonies/ceremonies.type";
import { useAddOrEditCeremoniesMutation, useDeleteCeremoniesMutation } from "../../../../utils/api/ceremonies/ceremonies.api";
import { Color } from "../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { cleannerError } from "../../../../utils/Utils";

function UseAddOrEditCeremonieForm(item?: Ceremonies) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    titre: yup.string().required().label("Le titre").nullable(),
    image: yup
      .mixed()
      .when("hasEdit", {
        is: false,
        then: () => yup.mixed().required().label("L'image").nullable(),
      })
      .nullable()
      .label("L'image"),
    lien: yup
      .string()
      .url()
      .matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/,
        "Veuillez entrer un lien YouTube valide"
      )
      .required()
      .label("Le lien youtube")
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<CeremoniesFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const [image, setImage] = useState<File | null>(null);
  const [addOrUpdateCeremonies, { isLoading, isSuccess, error, isError }] =
    useAddOrEditCeremoniesMutation();

  useEffect(() => {
    if (image?.name) {
      setValue("image", image);
    }
  }, [image]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? "La cérémonie a été modifiée avec succès !"
          : "La cérémonie a été ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate("/admin/ceremonies");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: CeremoniesFormData) => {
    console.log("DATA SUBMITED: ", data);

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await addOrUpdateCeremonies({ slug: item?.slug, data: fd });
  };

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof CeremoniesFormData)[] = ["titre", "lien"];
      setValue("hasEdit", true);
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    image,
    setImage,
  };
}

export default UseAddOrEditCeremonieForm;

export function UseDeleteCeremonies(slug: string) {
  const [deleteData] = useDeleteCeremoniesMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette cérémonie ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La cérémonie a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}