import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hook";
import {
  IPreambule,
  PreambuleFormData,
} from "../../../../utils/api/preambule/preambule.type";
import {
  useAddOrEditPreambuleMutation,
  useDeletePreambuleMutation,
} from "../../../../utils/api/preambule/preambule.api";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

function UseCrudPreambule(preambule?: IPreambule) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required("Nom est obligatoire").label("Titre"),
    content: yup
      .string()
      .required("Content est obligatoire")
      .label("Content")
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<PreambuleFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [content, setContent] = useState<string>("");
  const [addOrUpdatePreambule, { isLoading, isSuccess, error, isError }] =
    useAddOrEditPreambuleMutation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: preambule
          ? "Préambule modifié avec succès !"
          : "Préambule ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!preambule) {
          reset();
          setContent("");
        }
        navigate("/admin/preambule");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;

  const onSubmit = (data: any) => {
    data["user"] = userID;
    console.log("data submitted", data);
    addOrUpdatePreambule({ slug: preambule?.slug, data });
  };

  const handleReset = () => {
    if (!preambule) {
      reset();
    }
  };

  useEffect(() => {
    if (preambule?.id) {
      const fields: (keyof PreambuleFormData)[] = ["titre", "content"];

      for (let field of fields) {
        register(field);
        setValue(field, preambule[field]);
        if (field === "content") {
          setContent(preambule[field] || "");
        }
      }
    }
  }, [preambule]);

  const handleChangeContent = useCallback((value: string) => {
    setContent(value);
    setValue("content", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    handleChangeContent,
    handleReset,
  };
}

export default UseCrudPreambule;

export function UseDeletePreambule(item: IPreambule) {
  const [deleteData] = useDeletePreambuleMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce préambule ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Préambule supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
