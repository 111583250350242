import React from "react";
import Icon1 from "../../../../assets/icons/responsable1.svg";
import Icon2 from "../../../../assets/icons/responsable2.svg";
import Icon3 from "../../../../assets/icons/responsable3.svg";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { formatCurrency } from "../../../../utils/Utils";

export const types = [
  {
    label: "Quêtes",
    value: "quete",
  },
  {
    label: "Dîmes",
    value: "dime",
  },
  {
    label: "Dernier du culte",
    value: "dernier_culte",
  },
  {
    label: "Dons",
    value: "dons",
  },
];
function TabBanner(props) {
  let { data, isLoading, setQuery, setType } = props;
  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 mb-2 dis-flex">
          <div className="item-stat-dashboad-view ">
            <div className="content-stat-dashboard-view">
              <div className="content-text-item-stat-dashboad-view">
                <div className="icon-item-stat-dashboad-view">
                  <p className="titre-item-stat-dashboad-view mb-1">
                    Nombre de cotisations
                  </p>
                </div>
              </div>
              <div className="content-item-icon-stat-dashboard-view">
                <p className="chiffre-item-stat-dashboad-view mb-1">
                  {data?.nb_contributions}
                </p>
                <img
                  src={Icon1}
                  alt="Dashboard"
                  className="img-icon-dash-view"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-2 dis-flex">
          <div className="item-stat-dashboad-view">
            <div className="content-stat-dashboard-view">
              <div className="content-text-item-stat-dashboad-view">
                <div className="icon-item-stat-dashboad-view">
                  <p className="titre-item-stat-dashboad-view mb-1">
                    Total somme reçue
                  </p>
                </div>
              </div>
              <div className="content-item-icon-stat-dashboard-view">
                <p className="chiffre-item-stat-dashboad-view mb-1">
                  {formatCurrency(data?.total_amount)}
                </p>
                <img
                  src={Icon2}
                  alt="Dashboard"
                  className="img-icon-dash-view"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 mb-2 dis-flex">
          <div className="item-stat-dashboad-view">
            <div className="content-stat-dashboard-view">
              <div className="content-text-item-stat-dashboad-view">
                <div className="icon-item-stat-dashboad-view">
                  <p className="titre-item-stat-dashboad-view mb-1">
                    Cotisation restant
                  </p>
                </div>
              </div>
              <div className="content-item-icon-stat-dashboard-view">
                <p className="chiffre-item-stat-dashboad-view mb-1">
                  {data?.remaning_contributions}
                </p>
                <img
                  src={Icon3}
                  alt="Dashboard"
                  className="img-icon-dash-view"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-graph-admin mb-1">
        <div className="content-title-page-finance">
          <div className="content-select-filter-form">
            <select
              className="form-control form-control-select"
              onChange={(e) => setType(e?.target?.value)}
            >
              <option value="">Tout</option>
              {types?.map((type, i) => (
                <option value={type?.value} key={i}>
                  {" "}
                  {type?.label}{" "}
                </option>
              ))}
            </select>
          </div>
          <div className="content-form-filter d-flex align-items-center gap-2">
            <form>
              <div className="content-form-search-bloc position-relative">
                <input
                  type="search"
                  className="form-control form-control-search"
                  placeholder="Tapez pour rechercher"
                  onChange={(e) => setQuery(e?.target?.value)}
                />
                <span className="container-icon-searh-form">
                  <BiSearchAlt2 />
                </span>
              </div>
            </form>
            {/* <button className="btn btn-icon-filter">
              <BsFillFilterSquareFill />
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default TabBanner;
