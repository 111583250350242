import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Theme, ThemeFormData } from "./theme.type";

export const ThemeApi = createApi({
  reducerPath: "ThemeApi",
  tagTypes: ["theme", "themeList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditTheme: build.mutation<Theme, ThemeFormData>({
      query: ({ slug, ...data }) => ({
        url: slug ? `themes/${slug}/` : `themes/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["themeList"],
    }),
    getListTheme: build.query<PaginationResults<Theme>, TypeQuery>({
      query: (query) => QueryUrl("themes/", query),
      providesTags: ["themeList"],
    }),

    deleteTheme: build.mutation<ThemeFormData, string>({
      query: (slug) => ({
        url: `themes/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["theme", "themeList"],
    }),
  }),
});

export const {useAddOrEditThemeMutation, useDeleteThemeMutation, useGetListThemeQuery} = ThemeApi