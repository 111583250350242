import React from "react";
import { getFile, showModal } from "../../utils/Utils";

function ShowCNIUserModal({ modalId, file }: { modalId: string; file:string }) {
  return (
    <div>
      <button
        type="button"
        className="btn btn-cni"
        onClick={() => showModal(modalId)}
      >
        Carte d'identité
      </button>

      <div
        className="modal fade custom-modal"
        id={modalId}
        tabIndex={-1}
        aria-labelledby={`${modalId}Label`}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id={`${modalId}Label`}>
                CNI
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* Content */}
              <iframe
                src={getFile(file) + "#toolbar=1&zoom=50%"}
                loading="lazy"
                width="100%"
                //   height={"100vh"}
                style={{ height: "100vh" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowCNIUserModal;
