export const ParametreGradesTableDatas = [
    {
        id: 1,
        nom: "Dehoto",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
    {
        id: 2,
        nom: "Assistant Allagba / Allagba",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
    {
        id: 3,
        nom: "Assistant Leader",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
    {
        id: 4,
        nom: "Leader / Senior Allagba",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
    {
        id: 5,
        nom: "Senior Leader",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
    {
        id: 6,
        nom: "Vénérable Senior Leader",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
    {
        id: 7,
        nom: "Vénérable Senior Leader",
        date_ajout: "04 Nov 2022",
        type: "Grade des hommes",
    },
]