import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import visa from "../../../../assets/icons/visa.png";
import mastercard from "../../../../assets/icons/mastercard.png";
import { FideleCotisationTableData } from "../tables";
import { useGetCotisationsByUserQuery } from "../../../../utils/api/cotisation/cotisation.api";
import { IUser } from "../../../../utils/api/user/user.type";
import {
  formatCurrency,
  formatDateHour,
  getName,
} from "../../../../utils/Utils";
import { types } from "../../FinancesAdmin/FinanceTabs/TabBanner";
import { CustomPagination } from "../../../common/CustomPagination";

function FideleCotisationTable({ item }: { item: IUser }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetCotisationsByUserQuery({
      slug: item?.slug,
    });

  console.log("cotisation", data);

  const typeFormatter = (cell: string) => {
    let name = types?.find((type) => type?.value === cell);
    return (
      <div className="content-format-type-finance-table">
        <span className={`format-type-${cell}-table mb-0`}>{name?.label}</span>
      </div>
    );
  };

  const columns = [
    {
      dataField: "created_at",
      text: `Date`,
      formatter: (cell: string) => formatDateHour(cell),
    },
    {
      dataField: "user",
      text: "Nom",
      formatter: (cell: IUser) => getName(cell),
    },
    {
      dataField: "montant_converted",
      text: "Montant",
      formatter: (cell: string) => formatCurrency(cell),
    },
    {
      dataField: "type_collecte",
      text: "Type",
      formatter: typeFormatter,
    },
    // {
    //   dataField: "slug",
    //   text: "Action",
    //   formatter: actionFormatter,
    // },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive cotisation-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </>
      )}
    </>
  );
}

export default FideleCotisationTable;
