import React from "react";
import { BtnCloseModal } from "../../../common/Button";
import { ConstitutionBleu } from "../../../../utils/api/constitutionBleu/constitutionBleu.type";
import { getFile } from "../../../../utils/Utils";

function ShowFileModal({
  item,
  modalId,
}: {
  item: ConstitutionBleu;
  modalId: string;
}) {
  return (
    <div
      className="modal fade custom-modal"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={`${modalId}Label`}>
              Détail Document {item?.titre}
            </h1>
            <BtnCloseModal label="" />
          </div>
          <div className="modal-body">
            <iframe
              src={getFile(item?.fichier) + "#toolbar=1&zoom=100%"}
              loading="lazy"
              width="100%"
              //   height={"100vh"}
              style={{ height: "100vh" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowFileModal;
