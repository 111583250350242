import React, { useState } from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink } from "react-router-dom";
import { BsTrash } from "react-icons/bs";
import { AiFillEye } from "react-icons/ai";
// import { ConstitutionBleuAdminTableDatas } from "./ConstitutionBleuAdminTableDatas"
import PdfImg from "../../../../assets/icons/pdf.png";
import { useGetConstitutionBleuQuery } from "../../../../utils/api/constitutionBleu/constitutionBleu.api";
import { DeleteConstitutionBleu } from "../useForm/UseCrudConstitutionBleu";
import { CustomPagination } from "../../../common/CustomPagination";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import ShowFileModal from "../modal/ShowFileModal";

function ConstitutionBleuAdminTable() {
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetConstitutionBleuQuery({ limit: perPage, page });
  // const [pages, setPages] = useState(ConstitutionBleuAdminTableDatas)

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              data-bs-toggle="modal"
              data-bs-target={`#showFile${row?.slug}`}
            >
              <AiFillEye />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/constitution-bleu/modifier-document/${row?.slug}`}
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              state={row}
            >
              <MdEdit />
            </NavLink>
          </div>
          <DeleteConstitutionBleu item={row} />
        </div>
        <ShowFileModal item={row} modalId={`showFile${row?.slug}`} />
      </>
    );
  };
  const documentFormatter = (cell: any, row: any) => {
    // console.log("fichiers_in_table: ", row?.fichiers)
    return (
      <div
        className="d-flex gap-2 align-items-center linkable"
        data-bs-toggle="modal"
        data-bs-target={`#showFile${row?.slug}`}
      >
        <img src={PdfImg} alt="pdf" className="pdf-fichier-table" />

        <div className="content-format-statut-table">
          <p className="name-doc-format mb-1">{row?.fichier?.slice(12)}</p>
          {/* <p className='weight-doc-format mb-0'>3MB</p> */}
        </div>
      </div>
    );
  };
  const dateFormatter = (cell: any, row: any) => {
    return (
      <div className="date-format">
        {moment(row?.created_at).format("DD/MM/YYYY")}
      </div>
    );
  };
  const formatUserNameFormatter = (cell: any, row: any) => {
    return (
      <div className="user-infos">
        {row?.user?.nom} {row?.user?.prenom}
      </div>
    );
  };

  const columns = [
    {
      dataField: "document",
      text: "Documents",
      formatter: documentFormatter,
    },
    {
      dataField: "titre",
      text: "Titre",
      // formatter: documentFormatter,
    },
    {
      dataField: "ajouter",
      text: "Ajouté par",
      formatter: formatUserNameFormatter,
    },
    {
      dataField: "date",
      text: "Date ",
      formatter: dateFormatter,
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  // const selectRow = {
  //   mode: "checkbox",
  // };

  console.log("data: ", data);

  return (
    <>
      {!!isLoading && <TableSkeleton headers={columns} />}
      {!isLoading &&
        (data?.results?.length ? (
          <>
            <BootstrapTable
              keyField="id"
              data={data?.results}
              columns={columns}
              condensed
              hover
              wrapperClasses="table-responsive admin-table"
              noDataIndication={() => (
                <AlertInfo message="Aucune donnée trouvée" />
              )}
            />
            <div className="custom-pagination-container">
              <CustomPagination
                nbPages={data?.count}
                page={page}
                onChange={(page, perPage) => {
                  setPerPage(perPage);
                  setPage(page);
                }}
                perPage={perPage}
              />
            </div>
          </>
        ) : (
          <AlertInfo message="Aucune constitution bleue trouvée" />
        ))}
    </>
  );
}

export default ConstitutionBleuAdminTable;
