import React, { Dispatch, SetStateAction } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { MdCloudUpload } from "react-icons/md";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChart({
  graph,
  handleSearch,
  search,
  setType,
  type,
}: {
  graph: any;
  handleSearch: any;
  search: any;
  setType: Dispatch<SetStateAction<string>>;
  type: string;
}) {
  // const options = {
  //   responsive: true,
  //   plugins: {
  //     legend: {
  //       position: "top" as const,
  //       display: false,
  //     },
  //   },
  // };


  // const labels = [
  //   "Lundi", 
  //   "Mardi", 
  //   "Mercredi", 
  //   "Jeudi", 
  //   "Vendredi", 
  //   "Samedi", 
  //   "Dimanche",
  // ];

  // const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: "Patients 2020",
  //       data: [90, 110, 80, 120, 160, 50, 100],
  //       borderColor: "#9203D7",
  //       backgroundColor: "#a8bfd51f",
  //       fill: true,
  //       lineTension: 0.3,
  //     },
  //   ],
  // };

  const labels =
  graph?.map((label) => {
    if (type === "month") {
      return label.month;
    } else {
      return moment(label?.month?.toString()).format(
        type === "year" ? "MMMM" : type === "week" ? "dddd" : ""
      );
    }
  }) || [];

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
      display: false,
    },
  },
};

const data = {
  labels,
  datasets: [
    {
      label: `${
        search == "fildele"
          ? "Fideles"
          : "Responsable paroissial"
      }`,
      data: graph?.map((item) =>
        search == "fidele"
          ? item?.fideles
          : item?.responsables
      ),
      borderColor: "#9203D7",
      backgroundColor: "#a8bfd51f",
      fill: true,
      lineTension: 0.3,
    },
  ],
};

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-4">
        <form>
          <select
            className="form-control from-control-graph"
            onChange={handleSearch}
          >
            <option selected value="fidele">
            Fidele
            </option>
            <option value="responsable_paroissien">Responsable paroissial</option>
          </select>
        </form>
        {/* <div>
          <button type="submit" className={`btn btn-theme-body-page`}>
            <span className="px-2">
              <MdCloudUpload />
            </span>
            <span>Exporter les données</span>
          </button>
        </div> */}
        <div className="content-select-date-graph gap-3 d-flex">
          <button
            className={`btn custom-btn-filter-dashboard ${
              type === "year" ? "custom-btn-filter-dashboard-active" : ""
            }`}
            onClick={() => setType("year")}
          >
            Année
          </button>
          <button
            className={`btn custom-btn-filter-dashboard ${
              type === "month" ? "custom-btn-filter-dashboard-active" : ""
            }`}
            onClick={() => setType("month")}
          >
            Mois
          </button>
          <button
            className={`btn custom-btn-filter-dashboard ${
              type === "week" ? "custom-btn-filter-dashboard-active" : ""
            }`}
            onClick={() => setType("week")}
          >
            Semaine
          </button>
        </div>
      </div>
      <Line options={options} data={data} />
    </div>

    // <div className="chart-item-container">
    //   <div className="content-chart-title mb-4">
    //     <h2 className="chart-item-admin-title mb-0">
    //     Finances
    //     </h2>
    //     {/* <div className="content-select-date-graph">
    //       <select className="form-control form-control-select-date-graph">
    //         <option>Semaine du 30/11/2020</option>
    //       </select>
    //     </div> */}
    //   </div>
    //   <Line options={options} data={data} />
    // </div>
  );
}

export default LineChart;
