/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import "./CollecteAdmin.css";
import { MdAddCircleOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { CustomPagination } from "../../common/CustomPagination";
import Skeleton from "react-loading-skeleton";
import { AiOutlineMore } from "react-icons/ai";
import { createMarkup, formatCurrency, getImage } from "../../../utils/Utils";
import { useGetListCollecteQuery } from "../../../utils/api/collecte/collecte.api";
import { AlertInfo } from "../../common/Alert";
import { UseDeleteCollecte } from "./form/UseCrudCollecteForm";
import { Collecte } from "../../../utils/api/collecte/collecte.type";

const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteCollecte(slug);

  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
};

const CollecteCard = ({ item }: { item: Collecte }) => {
  return (
    <div className="collecte-card">
      <NavLink
        className={"no-link w-100"}
        to={`/admin/collectes/details/${item?.slug}`}
        state={item}
      >
        <div className="cover-wrapper">
          <img src={getImage(item?.image)} alt="cover" />
        </div>
      </NavLink>

      <div className="p-2 wrapper w-100">
        <div className="py-2 collecte-status-content w-100">
          <NavLink
            className={"no-link"}
            to={`/admin/collectes/details/${item?.slug}`}
            state={item}
          >
            <div className="percentage-and-goal d-flex align-items-center justify-content-between custom_ceg_text">
              <span className="pb-1 percentage">
                {item?.progression}&nbsp;%
              </span>
              <span className="goal">{formatCurrency(item?.montant)}</span>
            </div>
            <div className="progress collecte__progress">
              <div
                className="progress-bar collecte__progress_bar"
                role="progressbar"
                aria-label="Collecte status"
                style={{ width: `${item?.progression as any}%` }}
                aria-valuenow={item?.progression as any}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
            <div className="text-end pt-1 participants">
              {item?.nb_participants}&nbsp;participants
            </div>
          </NavLink>
        </div>
        <div className="pt-1 d-flex align-items-center gap-2 justify-content-between">
          <NavLink
            className={"no-link"}
            to={`/admin/collectes/details/${item?.slug}`}
            state={item}
          >
            <p
              className="m-0 sm_description"
              dangerouslySetInnerHTML={createMarkup(
                item?.description?.slice(0, 150) + "..."
              )}
            />
          </NavLink>

          <div className="btn-group">
            <button
              className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <AiOutlineMore style={{ fontSize: 18 }} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <NavLink
                  role="button"
                  to={`/admin/collectes/modifier/${item?.slug}`}
                  className="dropdown-item"
                  state={item}
                >
                  Modifier
                </NavLink>
              </li>
              <li>
                <BtnDelete slug={item?.slug} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const CollecteSkeleton = () => {
  return (
    <>
      {[...Array(12)]?.map((item, i) => (
        <div className="col-md-4 mb-4 custom-wrapper" key={i}>
          <div className="collecte-card w-100">
            <div className="cover-wrapper">
              <Skeleton height={230} />
            </div>

            <div className="p-2 wrapper w-100">
              <div className="py-2 collecte-status-content w-100">
                <div className="percentage-and-goal d-flex align-items-center justify-content-between custom_ceg_text">
                  <Skeleton className="pb-1 percentage mb-2" width={50} />
                  <Skeleton className="goal mb-2" width={100} />
                </div>
                <div className="progress collecte__progress">
                  <Skeleton className="progress-bar collecte__progress_bar" width={285} />
                </div>
                <Skeleton className="text-end pt-1 participants" width={100} height={8} style={{marginLeft: "65%"}} />
              </div>
              <div className="pt-1 d-flex align-items-center gap-2 justify-content-between">
                <Skeleton className="m-0 sm_description" width={235} height={50} />

                <div className="btn-group">
                  <button
                    className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <AiOutlineMore style={{ fontSize: 18 }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

function CollecteAdmin() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);

  const { data = { results: [], count: 0 }, isLoading } =
    useGetListCollecteQuery({
      limit,
      page,
    });
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Collectes</h2>
      </div>

      <div className="btn-action-container">
        <NavLink
          role="button"
          to={`/admin/collectes/ajouter`}
          className="btn custom-add-btn"
        >
          Ajouter une collecte
          <MdAddCircleOutline className="add-icon" />
        </NavLink>
      </div>

      <div className="collectes-page-content">
        <div className="custom-content pt-4">
          <div className="row all-ceremonies-row">
            {!!isLoading && <CollecteSkeleton />}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((collecte, key) => (
                  <div
                    className="col-md-4 mb-4 custom-wrapper"
                    key={`${key}_${collecte?.id}`}
                  >
                    <CollecteCard item={collecte} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucune donnée trouvée" />
              ))}
          </div>

          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setLimit(perPage);
                setPage(page);
              }}
              perPage={limit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollecteAdmin;
