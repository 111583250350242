import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, FileWithPath } from "react-dropzone";
import { getFileName, getImage } from "../../utils/Utils";
import { IconDelete3 } from "../shared/Icons";
import { FaFilePdf, FaVideo } from "react-icons/fa";
import PdfImg from "../../assets/icons/pdf.png";
import PlayImg from "../../assets/icons/play.svg";

function MyDropzoneFile({
  setFiles,
  label,
  maxFile = 1,
  isPdfFile = false,
  isMultiFile = false,
  isVideoFile = false,
}: {
  setFiles?: any;
  label?: string | any;
  maxFile?: number;
  isPdfFile?: boolean;
  isMultiFile?: boolean;
  isVideoFile?: boolean;
}) {
  const [maxFileError, setMaxFileError] = useState<string>("");
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles) {
      if (maxFile === 1) {
        setFiles(acceptedFiles[0]);
      } else {
        setFiles((prevFiles: any) => {
          console.log([...prevFiles, ...acceptedFiles]);
          return [...prevFiles, ...acceptedFiles];
        });
      }

      console.log(acceptedFiles);
    }
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "too-many-files") {
          setMaxFileError("Maximum 4 images");
        }
        if (err.code === "file-invalid-type") {
          setMaxFileError("Types de fichiers non prises en charge");
        }
      });
    });
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop,
    multiple: isMultiFile,
    maxSize: 209715200,
    accept: isPdfFile
      ? { "application/pdf": [] }
      : {
          "video/mp4": [],
          "video/mpg": [],
          "video/avi": [],
          "video/m4a": [],
        },
  });
  // let acceptedFileItems = acceptedFiles.map((file: FileWithPath) => (
  // 	<div className="col-3" key={file.path}>
  // 		<div className="image-item">
  // 			<img src={URL.createObjectURL(file)} alt={file.path} loading="lazy" />
  // 		</div>
  // 	</div>
  // ));
  const fileRejectionItems = fileRejections.map(({ file, errors }, index) => {
    return (
      <div key={index}>
        {/* {file.size} bytes */}
        <ul className="p-0">
          {errors.map((e) => (
            <li
              key={e.code}
              style={{ listStyle: "none" }}
              className="text-danger"
            >
              {e.code === "file-too-large" &&
                `Le fichier ${index + 1} est trop grand`}
            </li>
          ))}
        </ul>
      </div>
    );
  });

  useEffect(() => {
    setTimeout(() => setMaxFileError(""), 3000);
  });
  return (
    <section className="dropzone">
      {/* <label htmlFor="annonces-images" className="espace-client-form-label">
				label
			</label> */}
      <div
        {...getRootProps()}
        style={{
          border: "1px dashed #9203D7",
          overflow: "hidden",
          opacity: 1,
          borderRadius: "7px",
          display: "flex",
          justifyContent: "center",
          padding: "3rem 15px",
          background: "#F2F6FA",
        }}
      >
        <input
          {...getInputProps()}
          type="file"
          id="eglise-files"
          name="eglise-files"
          // accept="application/*"
          accept={
            isVideoFile
              ? "video/mp4,video/x-m4v,video/*"
              : isPdfFile
              ? "application/pdf"
              : "application/*"
          }
        />
        <div className="start-label-input-choose-file">
          <div className="flex-c align-items-center mb-3 gap-3">
            {!!isVideoFile && (
              <FaVideo style={{ color: "#9203D7", fontSize: "36px" }} />
            )}
            {!!isPdfFile && (
              <>
                <FaFilePdf style={{ color: "#9203D7", fontSize: "30px" }} />
              </>
            )}
          </div>
          <span className="first-label-input-choose-file">
            Déposez un fichier ici.
          </span>{" "}
          {!!isVideoFile && (
            <span className="second-label-input-choose-file">
              Format accepté : Video, inférieurs à 200Mo.
            </span>
          )}
          {!!isPdfFile && (
            <span className="second-label-input-choose-file">
              Format acceptés : PDF, inférieurs à 200Mo.
            </span>
          )}
        </div>
      </div>
      {/* {maxFileError && <ErrorMessage message={maxFileError} />} */}
      <div className="row pt-4">
        {/* {acceptedFileItems} */}
        {!maxFileError && fileRejectionItems}
      </div>
    </section>
  );
}

export default MyDropzoneFile;

type TFileGrid = {
  files: File[] | any[];
  deleteFile?: (i: number, file: any) => void;
};

export const FileGrid = ({ files, deleteFile }: TFileGrid) => {
  return (
    <>
      {files.length
        ? files.map((file: FileWithPath | any, idx) => (
            // <div className="col-md-3 mb-1" key={file.path}>
            //   <div className="image-item">
            //     <img
            //       src={URL.createObjectURL(file)}
            //       alt={file.path}
            //       loading="lazy"
            //       className="img-thumbnail"
            //       id="overlay"
            //     />
            //   </div>
            // </div>
            <div className="col-md-4 col-xl-3" key={idx}>
              <div className="product-img-card">
                <button
                  className="no-style-btn del-product-img"
                  title="Supprimer"
                  type="button"
                  onClick={() => {
                    if (deleteFile) {
                      deleteFile(idx, file);
                    }
                  }}
                >
                  <IconDelete3 />
                </button>
                <img
                  src={
                    file?.image
                      ? getImage(file?.image)
                      : URL.createObjectURL(file)
                  }
                  alt="product-img"
                  style={{
                    width: "100%",
                    height: "15vw",
                    objectFit: "fill",
                  }}
                />
              </div>
            </div>
          ))
        : ""}
    </>
  );
};

export const RenderFile = ({
  file,
  isPdfFile = false,
  isVideoFile = false,
}: {
  file: any;
  isPdfFile?: boolean;
  isVideoFile?: boolean;
}) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      {!!isPdfFile && (
        <img src={PdfImg} alt="pdf" className="pdf-fichier-table" />
      )}

      {!!isVideoFile && (
        <img
          src={PlayImg}
          alt="pdf"
          className="pdf-fichier-table"
          style={{ background: "#9203D7", width: "25px" }}
        />
      )}

      <div className="content-format-statut-table">
        <p className="name-doc-format mb-1">{getFileName(file)}</p>
        {/* <p className='weight-doc-format mb-0'>3MB</p> */}
      </div>
    </div>
  );
};
