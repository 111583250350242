import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { 
    Necrologie, 
    NecrologieFormData 
} from "./necrologie.type";


export const NecrologieApi = createApi({
  reducerPath: "NecrologieApi",
  tagTypes: ["necrologie", "necrologieApi"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditNecrologie: build.mutation<
    Necrologie,
      { 
        slug?: string; 
        data: NecrologieFormData | FormData 
      }
    >({
      query: ({ slug, data }) => ({
        url: slug ? 
            `necrologies/${slug}/` 
            : 
            `necrologies/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["necrologie"],
    }),

    getListNecrologie: build.query<PaginationResults<Necrologie>, TypeQuery>({
      query: (query) => QueryUrl("necrologies/", query),
      providesTags: ["necrologie"],
    }),

    getNecrologie: build.query<PaginationResults<Necrologie>, TypeQuery>({
      query: (query) => QueryUrl("necrologies/", query),
      providesTags: ["necrologie"],
    }),
    
    deleteNecrologie: build.mutation<NecrologieFormData, string>({
      query: (slug) => ({
        url: `necrologies/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["necrologie", "necrologieApi"],
    }),

    
  }),
});

export const {
    useAddOrEditNecrologieMutation,
    useDeleteNecrologieMutation,
    useGetNecrologieQuery,
    useGetListNecrologieQuery,
} = NecrologieApi;