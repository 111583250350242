import { useUserFromLocation } from "../../../utils/api/user/user.api";
import UseCrudResponsableParoissien from "./UseCrudResponsableParoissien";
import { useEffect, useState } from "react";
import { useAddOrEditMembreMutation } from "../../../utils/api/membre/membre.api";
import Swal from "sweetalert2";
import { Color } from "../../../utils/theme";
import BreadCrumb from "../../shared/BreadCrumb";
import ShowCNIUserModal from "../../shared/ShowCNIUserModal";
import { getAvatar } from "../../../utils/Utils";
import { TiCamera } from "react-icons/ti";
import { CustomOption, FormError, Input, OptionType, animatedComponents } from "../../common/Input";
import PhoneInput from "react-phone-input-2";
import { Authorisations } from "../../../utils/constante";
import Select from "react-select";
import GoogleInput from "../../common/GoogleInput";
import { SelectInput } from "../../common/SelectInput";
import ShowCertificatUserModal from "../../shared/ShowCertificatUserModal";

export const Wrapper = ({
  children,
  customClass,
}: {
  children?: any;
  customClass?: string;
}) => {
  return <div className={`custom-big-wrapper ${customClass}`}>{children}</div>;
};

function DetailResponsableParoissien() {
  const [membre] = useUserFromLocation();

  let field = "responsable_paroissien";

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    phone,
    handleChangePhone,
    Controller,
    control,
    option,
    options,
    address,
    onChangeAddress,
    setSearch,
    paroisses,
    onSelectParoisse,
    selected,
    setQuery,
    onSelectGrade,
    pays,
    selectedPays,
    onSelectPays,
    isLoadParoisse,
    optionsModeles,
    optionModele,
    onSelectModele,
    isLoadCertificat
  } = UseCrudResponsableParoissien(field, membre);
  const [avatar, setAvatar] = useState<any>(null);

  const [sendData, { isSuccess: done, isError, error, data, isLoading: load }] =
    useAddOrEditMembreMutation();

  useEffect(() => {
    if (done) {
      Swal.fire({
        icon: "success",
        title: "Photo de profil modifiée avec succès !",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur de statut ${err?.status} est survenue`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [done, isError]);

  const changeImage = (e: any) => {
    const file = e.target.files[0];
    setAvatar(URL.createObjectURL(file));
    let fd = new FormData();
    fd.append("avatar", file);
    sendData({ slug: membre?.slug, data: fd });
  };
  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Responsable paroisial" secondPage="Détails" />
        </div>
      </div>
      <Wrapper>
        <div className="d-flex justify-content-end">
          <div className="row w-100">
            <div className="col-md-6 offset-md-6 mb-3 d-flex justify-content-end">
              <div className="d-flex flex-column flex-md-row gap-2">
                {!!membre?.last_certificat?.file && (
                  <div className="d-flex justify-content-end">
                    <ShowCertificatUserModal
                      modalId={`certificatModal${membre?.slug}`}
                      file={membre?.last_certificat?.file}
                    />
                  </div>
                )}
                {membre?.cni && (
                  <div className="d-flex justify-content-end">
                    <ShowCNIUserModal
                      modalId={`cniModal${membre?.slug}`}
                      file={membre?.cni}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="office-grid-cover-and-name">
          <div className="container-img-profil-user mb-0">
            <div className="d-flex justify-content-center align-items-center mb-0 leader-auto-user-avatar-container">
              <label htmlFor="input-test" className="input-testt">
                <img
                  src={avatar || getAvatar(membre?.avatar)}
                  alt="Profil"
                  className="custom-img-profil-user-admin photo"
                />
                <div className="leader-auto-user-avatar-edit-hover-deux">
                  {load ? (
                    <span
                      className="spinner-border spinner-border-sm custom-spinner text-dark me-1 d-inline-block"
                      role="status"
                    ></span>
                  ) : (
                    <TiCamera className="edit-avatar-camera-icon" />
                  )}
                </div>
              </label>
              <input
                type="file"
                className="input-add-avatar"
                id="input-test"
                accept="image/*"
                onChange={(e) => changeImage(e)}
              />
            </div>
          </div>
          <span className="name">{membre?.prenom + " " + membre?.nom}</span>
        </div>
      </Wrapper>
      <form onSubmit={onSubmit}>
        <Wrapper customClass="mt-3">
          <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
            <div className="custom-wrapper-title">
              Informations personnelles
            </div>
          </div>
          <div className="form-content">
            <div className="row row-infos-perso">
              <div className="col-md-6">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors.nom}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Prénom"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors.prenom}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  error={errors.email}
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"telephone"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"telephone"}
                  >
                    Téléphone
                  </label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                      paddingTop: "22px",
                      height: "54px",
                      backgroundColor: "#F2F6FA",
                      border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Rôle"
                  id="role"
                  placeholder="Rôle"
                  {...register("role")}
                  error={errors.role}
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Adresse
                  </label>
                  <GoogleInput
                    className={`form-control form-control-modal-custom`}
                    placeholder="Adresse"
                    value={address}
                    onChange={onChangeAddress}
                  />
                  <FormError error={errors.adresse} />
                </div>
              </div>

              <div className="col-md-6">
                <Input
                  label="Nationalité"
                  type="text"
                  placeholder="Nationalité"
                  {...register("nationalite")}
                  error={errors.nationalite}
                />
              </div>

              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>

                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={onSelectPays}
                          value={selectedPays}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column gap-1 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Paroisse
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <Controller
                  name="paroisse_id"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={paroisses}
                        value={selected ? selected : null}
                        onInputChange={(val) => setQuery(val)}
                        onChange={(val) => onSelectParoisse(val)}
                        className="custom-select-items"
                        isLoading={isLoadParoisse}
                        // isDisabled={isLoadParoisse}
                      />
                    );
                  }}
                />
                <FormError error={errors.paroisse_id} />
              </div>
              <div className="col-md-6 d-flex flex-column gap-1 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Grade{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <Controller
                  name="grade"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={options}
                        value={option}
                        onInputChange={(val) => setSearch(val)}
                        onChange={(val) => onSelectGrade(val)}
                        className="custom-select-items"
                      />
                    );
                  }}
                />

                <FormError error={errors.grade} />
              </div>
              <div className="col-md-6">
                <Input
                  label="Date du dernier grade"
                  type="date"
                  placeholder="|"
                  {...register("date_dernier_grade")}
                  error={errors.date_dernier_grade}
                />
              </div>
              <div className="col-md-6 mb-3">
                <SelectInput
                  label="Onction"
                  id="onction"
                  required
                  {...register("onction")}
                  customClassInput="form-control-modal-custom"
                  error={errors.onction}
                  options={[
                    { label: "Prophete", value: "prophete" },
                    { label: "Clerge", value: "clerge" },
                    { label: "Honorable", value: "honorable" },
                    { label: "Membre", value: "membre" },
                  ]}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Modele de certificat{" "}
                </label>
                <Controller
                    name="modele"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={{
                            ...animatedComponents,
                            Option: CustomOption as any
                          }}
                          options={optionsModeles as OptionType[]}
                          value={optionModele as OptionType}
                          onInputChange={(val) => setSearch(val)}
                          onChange={(val) => onSelectModele(val)}
                          className="custom-select-items"
                          isLoading={isLoadCertificat}
                          // isDisabled={isLoadCertificat}
                        />
                      );
                    }}
                  />
              </div>
              {/* <div className="col-md-6 mb-3">
                <div className="mb-4">
                  <label
                    htmlFor={"paroisse"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"paroisse"}
                  >
                    Paroisse
                  </label>

                  <Controller
                    name="paroisse_id"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          onInputChange={(val) => setInputValue(val)}
                          options={options}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelectParoisse}
                          value={option}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.paroisse_id} />
                </div>
              </div> */}
            </div>
          </div>
        </Wrapper>

        <Wrapper customClass="mt-3">
          <div className="custom-wrapper-title pb-5">Habilitations</div>
          <div className="container-checkbox-input">
            <div className="form-checkbox-input checkbox-input">
              <div className="custom-permission-container">
                {Authorisations?.map((item) => (
                  <label key={item?.field} className={item?.field === `${field}` || item?.field === "dashboard" ? "disabled" : ""}>
                    <input
                      type="checkbox"
                      {...register(item?.field)}
                      id={`checkbox-${item?.field}`}
                      disabled={
                        item?.field === `${field}` ||
                        item?.field === "dashboard"
                      }
                    />
                    <span>{item?.label}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
          <div className="col-md-4 offset-md-8 pt-5">
            <button
              className="btn btn-theme-admin w-100"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>{"Modification..."}</span>
                </>
              ) : (
                "Modifier"
              )}
            </button>
          </div>
        </Wrapper>
      </form>
    </>
  );
}

export default DetailResponsableParoissien;
