import React from "react";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { GiDeadlyStrike } from "react-icons/gi";
import { RxDashboard } from "react-icons/rx";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { AiTwotoneSetting } from "react-icons/ai";
import { IoDocumentTextOutline } from "react-icons/io5";
import Logo from "../../../../assets/appImages/logo-nav.png";
import { BsFileEarmarkText, BsInfoCircle } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { MdAssignmentAdd, MdOutlineChurch } from "react-icons/md";
import { RiMoneyPoundCircleLine } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import { hasAccess } from "../../../../utils/Utils";

const SidebarMobile = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);
  const onLogout = () => {
    dispatch(onlogout());
  };
  return (
    <div>
      <div className="container-nav">
        <input type="checkbox" id="check" />
        <label htmlFor="check">
          <AiOutlineMenuUnfold />
          <i className="fas fa-times" id="cancel"></i>
        </label>
        <div className="sidebar" id="scrollbar-nav-mobile">
          <div className="content-nav-mobile">
            {" "}
            <div className="container-logo-sidebar-nav">
              <img src={Logo} alt="Logo" className="logo-sidebar-nav" />
            </div>
            <ul className="nav nav-items-custom flex-column">
              <li className="nav-item nav-item-vertical-custom">
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/dashboard"
                >
                  <RxDashboard />
                  <span className="item-nav-menu drop-header">Dashboard</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "preambules")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/preambule"
                >
                  <IoDocumentTextOutline />
                  <span className="item-nav-menu drop-header">Préambule</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "contitution_bleues")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/constitution-bleu"
                >
                  <IoDocumentTextOutline />
                  <span className="item-nav-menu drop-header">
                    Constitution bleue
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "pasteurs")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/pasteurs"
                >
                  <FiUsers />
                  <span className="item-nav-menu drop-header">Pasteurs</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "conseil_pastoral")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/conseil-pastoral"
                >
                  <IoDocumentTextOutline />
                  <span className="item-nav-menu drop-header">
                    Conseil pastoral
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "conseil_administration")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/conseil-administration"
                >
                  <IoDocumentTextOutline />
                  <span className="item-nav-menu drop-header">
                    Conseil d’administration
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "office_pastoral")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/office-pastoral"
                >
                  <IoDocumentTextOutline />
                  <span className="item-nav-menu drop-header">
                    Office pastoral
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "responsable_paroissien")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/responsable-paroissien"
                >
                  <IoDocumentTextOutline />
                  <span className="hiddenable-navlink-label">
                    Responsable paroissial
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "responsable_diocesien")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/responsable-dioceses"
                >
                  <IoDocumentTextOutline />
                  <span className="hiddenable-navlink-label">
                    Responsable diocèsien
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "country")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/pays"
                >
                  <CiLocationOn />
                  <span className="item-nav-menu drop-header">Pays</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "paroisses")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/paroisses"
                >
                  <MdOutlineChurch />
                  <span className="item-nav-menu drop-header">Paroisses</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "affectations")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/affectation"
                >
                  <MdAssignmentAdd />
                  <span className="item-nav-menu drop-header">Affectation</span>
                </NavLink>
              </li>

              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "formations")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/formations"
                >
                  <BsInfoCircle />
                  <span className="item-nav-menu drop-header">Formations</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "ceremonies")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/ceremonies"
                >
                  <BsInfoCircle />
                  <span className="hiddenable-navlink-label">Cérémonies</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "documentations")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/documentations"
                >
                  <IoDocumentTextOutline />
                  <span className="item-nav-menu drop-header">
                    Documentations
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "actualites")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/actualites"
                >
                  <IoDocumentTextOutline />
                  <span className="hiddenable-navlink-label">Actualités</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "carnet_roses")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/carnet-rose"
                >
                  <BsFileEarmarkText />
                  <span className="item-nav-menu drop-header">Carnet rose</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "objet_religieux")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/objets-religieux"
                >
                  <BsFileEarmarkText />
                  <span className="hiddenable-navlink-label">
                    Objets religieux
                  </span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "finances")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/finances"
                >
                  <RiMoneyPoundCircleLine />
                  <span className="item-nav-menu drop-header">Finances</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "collectes")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/collectes"
                >
                  <RiMoneyPoundCircleLine />
                  <span className="hiddenable-navlink-label">Collectes</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "temoignages")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/temoignages"
                >
                  <BsInfoCircle />
                  <span className="item-nav-menu drop-header">Témoignages</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "necrologies")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/necrologies"
                >
                  <GiDeadlyStrike />
                  <span className="item-nav-menu drop-header">Nécrologies</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "messages")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/messages"
                >
                  <CiMail />
                  <span className="item-nav-menu drop-header">Messages</span>
                </NavLink>
              </li>
              <li
                className="nav-item nav-item-vertical-custom"
                hidden={hasAccess(user, "parametres")}
              >
                <NavLink
                  className="nav-link nav-link-vertival-custom-admin"
                  to="/admin/parametres"
                >
                  <AiTwotoneSetting />
                  <span className="item-nav-menu drop-header">Paramètres</span>
                </NavLink>
              </li>
              <li className="nav-item nav-item-vertical-custom pt-5">
                <button
                  className="btn nav-link nav-link-vertival-custom-admin sidebar-logout-btn"
                  onClick={() => onLogout()}
                >
                  <IoLogOutOutline />
                  <span className="item-nav-menu drop-header">Déconnexion</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMobile;
