import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { FidelesFormData, IFideles } from "./fideles.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const FideleApi = createApi({
  reducerPath: "FideleApi",
  tagTypes: ["fidele", "fideleList", "fideleByParoisse"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditFidele: build.mutation<
      IFideles,
      {
        slug?: string;
        data: FidelesFormData | FormData;
      }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `users/${slug}/` : `fideles/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["fidele", "fideleList"],
    }),

    getListFidele: build.query<PaginationResults<IFideles>, TypeQuery>({
      query: (query) => QueryUrl("fideles/", query),
      providesTags: ["fideleList"],
    }),
    getFidele: build.query<IFideles, string>({
      query: (slug) => `fideles/${slug}`,
      providesTags: ["fidele"],
    }),
    deleteFidele: build.mutation<FidelesFormData, string>({
      query: (slug) => ({
        url: `fideles/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["fidele", "fideleList", "fideleByParoisse"],
    }),
    getFidelesOfParoisse: build.query<PaginationResults<IFideles>, TypeQuery>({
      providesTags: ["fideleByParoisse"],
      query: ({ slug, ...query }) =>
        QueryUrl(`paroisse/${slug}/fideles/`, query),
    }),
  }),
});

export const {
  useAddOrEditFideleMutation,
  useDeleteFideleMutation,
  useGetListFideleQuery,
  useLazyGetFideleQuery,
  useGetFidelesOfParoisseQuery
} = FideleApi;

export function useFideleFromLocation(): [
  IFideles,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IFideles | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetFideleQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as IFideles, isLoading, slug as string, findById];
}
