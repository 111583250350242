import React from "react";
import BreadCrumb from "../../../../shared/BreadCrumb";
import { Wrapper } from "../../DetailChef/Shared";
import { AiOutlineMore } from "react-icons/ai";
import { useForumFromLocation } from "../../../../../utils/api/forum/forum.api";
import {
  createMarkup,
  formatDateHour,
  getAvatar,
  getFile,
  getImage,
  getName,
} from "../../../../../utils/Utils";
import { BtnDelete, getField } from "./Communaute";

function CommunauteDetails() {
  const [item] = useForumFromLocation();
  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb
          pageName="Communautés"
          to={`/admin/paroisses/detail-paroisse/${item?.author?.paroisse_id?.slug}?tab=communaute`}
          secondPage={"Détails"}
          state={item?.author?.paroisse_id}
        />
      </div>

      <Wrapper>
        <div className="row">
          <div className="col-md-12 mb-4">
            <div className="social_post__card">
              <div className="p-3 d-flex justify-content-between align-items-center social_post_header__wrapper">
                <div className="d-flex align-items-center gap-2 poster__infos">
                  <picture className="author_image_wrapper">
                    <img
                      src={getAvatar(item?.author?.avatar)}
                      alt="author"
                      className="author_image"
                    />
                  </picture>

                  {/* {item?.content_type !== "image"} */}

                  <div className="name_and_paroisse">
                    <p className="m-0 pb-1 author_name">
                      {getName(item?.author)}
                    </p>
                    <p className="m-0 paroisse">
                      {item?.author?.paroisse_id?.nom}
                    </p>
                  </div>
                </div>
                <div className="btn-group">
                  {/* <button
                    className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <AiOutlineMore style={{ fontSize: 18 }} />
                  </button>
                  <ul className="dropdown-menu">
                  
                    <li>
                      <BtnDelete slug={item?.slug} />
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="separator"></div>
              <div className="p-3 custom_post__content">
                {item?.content_type === "image" || item?.image ? (
                  <p className="m-0 pb-3 post_image__wrapper">
                    <img src={getImage(item?.image)} alt={item?.slug} />
                  </p>
                ) : item?.content_type !== "text" ? (
                  <p className="m-0 pb-3 post_image__wrapper">
                    <iframe
                      src={getFile(getField(item))}
                      className={getField(item)}
                      frameBorder={0}
                    />
                  </p>
                ) : null}
                <p
                  className="m-0 pb-3 post_text"
                  dangerouslySetInnerHTML={createMarkup(item?.content || "")}
                />

                <div className="d-flex justify-content-between align-items-center post_extras__content">
                  <p className="m-0 liked_by d-flex justify-content-between align-items-center gap-3">
                    <span className="liked_image_wrapper">
                      {[...item?.likes]?.slice(0, 3)?.map((el) => (
                        <img
                          src={getAvatar(el?.user?.avatar)}
                          alt={getName(el?.user) || "avatar"}
                        />
                      ))}
                    </span>
                    <span className="people_whose_liked">
                      {`Aimé par ${item?.likes_count} personne(s)`}
                    </span>
                  </p>
                  <span className="comments">
                    {item?.comments_count} commentaire(s)
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 pb-3">
            {!!item?.comments?.length &&
              item?.comments?.map((comment) => (
                <div className="detail-forum-reponse-item-container py-2 mb-3">
                  <div className="forum-sommaire-item-user-infos-container mb-3">
                    <div className="forum-sommaire-item-user-infos">
                      <img
                        src={getAvatar(comment?.user?.avatar)}
                        alt={getName(comment?.user) || "user"}
                        className="forum-reponse-item-user-avatar"
                      />
                      <div className="forum-sommaire-item-user-name-specialite">
                        <div className="forum-reponse-item-user-name">
                          {getName(comment?.user)}
                        </div>
                        <div className="forum-reponse-item-user-specialite">
                          {comment?.user?.paroisse_id?.nom}
                        </div>
                      </div>
                    </div>
                    <div className="forum-reponse-item-date">
                      {formatDateHour(comment?.created_at)}
                    </div>
                  </div>
                  <div
                    className="forum-reponse-item-apercu mb-3"
                    dangerouslySetInnerHTML={createMarkup(comment?.content)}
                  />
                </div>
              ))}
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-10 m-auto">
            <div className="forum-sommaire-container">
              <div className="row">
                <div className="col-md-12">
                  <div className="forum-sommaire-item-container mb-3">
                    <div className="forum-sommaire-item-user-infos-container mb-3">
                      <div className="forum-discussion-item-user-infos">
                        <img
                          src={forumAvatar}
                          alt="user avatar"
                          className="forum-discussion-item-user-avatar"
                        />
                        <div className="forum-sommaire-item-user-name-specialite">
                          <div className="forum-discussion-item-user-name">
                            Docteur Astou Ndoye
                          </div>
                          <div className="forum-sommaire-item-user-specialite">
                            Médecin généraliste
                          </div>
                        </div>
                      </div>
                      <div className="forum-sommaire-item-date">
                        Il y a 2 jours
                      </div>
                    </div>
                    <div className="forum-discussion-item-apercu mb-3">
                      Lorem ipsum dolor sit amet consectetur. Libero morbi
                      faucibus nisi donec nibh. Egestas integer hendrerit
                      maecenas tincidunt faucibus at non amet commodo. Egestas
                      interdum diam vestibulum hendrerit tortor ultrices. Ut
                      quisque dictum habitasse in erat nisi sed orci. Et posuere
                      massa augue et sed lacinia eget. Eu enim nullam netus diam
                      consectetur non arcu.
                    </div>
                    <div className="forum-sommaire-item-nbr-reponse">
                      <BsChatLeftTextFill className="forum-sommaire-item-nbr-reponse-icon" />
                      3 réponses
                    </div>
                  </div>
                  <hr className="forum-custom-divider mb-3" />
                  <div className="forum-form-reponse-container mb-3">
                    <ReactQuill
                      className="editor-cgu"
                      value={""}
                      //   onChange={(value) => handleChangeContent(value)}
                      placeholder="Ajoutez votre réponse ici..."
                    />
                  </div>
                  <div className="forum-btn-reponse-container mb-3">
                    <button className="btn forum-btn-reponse">Répondre</button>
                  </div>
                  <hr className="mb-3" />
                  <div className="detail-forum-reponse-item-container">
                    <div className="forum-sommaire-item-user-infos-container mb-3">
                      <div className="forum-sommaire-item-user-infos">
                        <img
                          src={forumAvatar2}
                          alt="user avatar"
                          className="forum-reponse-item-user-avatar"
                        />
                        <div className="forum-sommaire-item-user-name-specialite">
                          <div className="forum-reponse-item-user-name">
                            Dr Abdou Seck
                          </div>
                          <div className="forum-reponse-item-user-specialite">
                            Gynécologue
                          </div>
                        </div>
                      </div>
                      <div className="forum-reponse-item-date">
                        1 hari yang lalu
                      </div>
                    </div>
                    <div className="forum-reponse-item-apercu mb-3">
                      Lorem ipsum dolor sit amet consectetur. Nec commodo
                      iaculis vitae id venenatis sagittis in.
                    </div>
                    <div className="forum-reponse-item-nbr-reponse-container">
                      <div className="forum-sommaire-item-nbr-reponse">
                        <BsChatLeftTextFill className="forum-sommaire-item-nbr-reponse-icon" />
                        Réponses
                      </div>
                      <div className="custom-vertical-divider"></div>
                      <div className="forum-reponse-item-likes-container">
                        <AiFillHeart className="forum-reponse-item-likes-icon" />
                        <div className="forum-reponse-item-likes">1</div>
                        <div className="forum-reponse-item-likes">Likes</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </Wrapper>
    </>
  );
}

export default CommunauteDetails;
