import React, { ChangeEvent, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { IModeles, ModelesFormData } from "../../../../utils/api/modeles/modeles.type";
import { useAddOrEditModelesMutation, useDeleteModelesMutation } from "../../../../utils/api/modeles/modeles.api";

function UseCrudModele(setModalAddModele: any, modele?: IModeles, setModele?: any) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    couleur: yup.string().required().label("La couleur"),
    // image: yup.mixed().required().label("L'image").nullable(),
    image: yup
      .mixed()
      .when("hasEdit", {
        is: false,
        then: () => yup.mixed().required().label("L'image").nullable(),
      })
      .nullable()
      .label("L'image"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError
  } = useForm<ModelesFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateModele, { isLoading, isSuccess, error, isError }] =
    useAddOrEditModelesMutation();

    const [previewCertificat, setPreviewCertificat] = useState("");
    const [previewLastCertificat, setPreviewLastCertificat] = useState("");

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: modele
          ? "Modèle modifié avec succès !"
          : "Modèle ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        setModele();
        setModalAddModele(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await addOrUpdateModele({ slug: modele?.slug, data: fd });
  };

  const handleReset = () => {
    reset();
    setModele();
    setPreviewCertificat("")
    setPreviewLastCertificat("")
  };

  const handelCertificatChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("image", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewCertificat(URL.createObjectURL(file));
      register("image");
      setValue("image", file);
    }
  }, []);

  useEffect(() => {
    if (modele?.id) {
      // console.log(modele)
      const fields: (keyof ModelesFormData)[] = ["couleur"];
      setValue("hasEdit", true);
      for (let field of fields) {
        register(field);
        setValue(field, modele[field]);
      }
      if(modele?.image) {
        setPreviewLastCertificat(getImage(modele?.image))
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [modele]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    handelCertificatChange,
    previewCertificat,
    previewLastCertificat
  };
}

export default UseCrudModele;

export function UseDeleteModele(slug: string) {
  const [deleteData] = useDeleteModelesMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce modèle ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Modèle supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
