import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { IMembre, MembreFormData } from '../../../../utils/api/membre/membre.type';
import { IUser } from '../../../../utils/api/user/user.type';
import { cleannerError, validatePhone } from '../../../../utils/Utils';
import { useForm } from "react-hook-form";
import { useAddOrEditMembreMutation } from '../../../../utils/api/membre/membre.api';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Color } from '../../../../utils/theme';
import { Authorisations } from '../../../../utils/constante';
import { IParoisse } from "../../../../utils/api/paroisses/paroisses.type";

function UseCrudMembreParoisse(
  paroisse: IParoisse, 
  field: string, 
  membre?: IMembre | IUser) {
    const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    prenom: yup.string().required("Prenom est obligatoire").label("Prénom"),
    nom: yup.string().required("Nom est obligatoire").label("Nom"),
    email: yup
      .string()
      .email("L'email est invalide")
      .required("Email est obligatoire"),
    telephone: validatePhone(yup, code),
    // telephone: yup.string().label("Téléphone").nullable(),
    role: yup.string().label("Role").nullable(),
    avatar: yup.mixed().nullable().label("Photo"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm<MembreFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [previewAvatar, setPreviewAvatar] = useState("");
  const [content, setContent] = useState<string>("");
  const [addOrUpdateMembre, { isLoading, isSuccess, error, isError }] =
    useAddOrEditMembreMutation();

  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };
  let typeofMembre = typeof(membre)
  const navigate = useNavigate();
  let path = `/admin/paroisses/detail-paroisse/${paroisse?.slug}?tab=membres`;

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: membre && typeofMembre !== "string"
          ? "Membre modifié avec succès !"
          : "Membre ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!membre) {
          reset();
          setContent("");
        }
        navigate(path);
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("Cet email existe dejà")) {
        Swal.fire({
          icon: "error",
          title: err?.data?.email,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: any) => {
    !membre && (data[field] = true);
    data['paroisse_id'] = paroisse?.id
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await addOrUpdateMembre({ slug: membre?.slug, data: fd });
  };

  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("avatar", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewAvatar(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  const handleReset = () => {
    if (!membre) {
      reset();
    }
  };

  useEffect(() => {
    if (membre?.id) {
      let fields: (keyof IMembre)[] = [
        "id",
        "slug",
        "prenom",
        "nom",
        "telephone",
        "email",
        "role",
        "conseil_pastoral",
        "conseil_administration",
        "office_pastoral",
        "dashboard",
        "fideles",
        "demandes",
        "paroisses",
        "actualites",
        "cotisations",
        "formations",
        "messages",
        "parametres",
        "preambules",
        "contitution_bleues",
        "pasteurs",
        "affectations",
        "documentations",
        "carnet_roses",
        "finances",
        "témoignages",
        "necrologies",
        "paroisse_id",
        "responsable_paroissien",
        "responsable_diocesien",
        "objet_religieux",
        "collectes",
        "actualites",
        "ceremonies",
      ];
      for (let field of fields) {
        register(field);
        if (field === "paroisse_id") {
          if (membre[field] && membre[field] !== undefined  && membre[field] !== null) {
            setValue(field, membre[field]);
          }
        } else {
          setValue(field, membre[field]);
        }
        
      }
      if (membre?.telephone) {
        setPhone(membre?.telephone);
      }
    } else {
      Authorisations.forEach((item) => {
        if(item?.field === field || item?.field === 'dashboard') {
          setValue(item?.field, true);
        } else {
          setValue(item?.field, false);
        }
      });
    }
  }, [membre]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    handleReset,
    handelAvatarChange,
    previewAvatar,
    phone,
    handleChangePhone,
  };
}

export default UseCrudMembreParoisse