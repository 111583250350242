import React from "react";
import { NavLink } from "react-router-dom";
import ResponsableParoissienTable from "./Table/ResponsableDioceseTable";

export default function ResponsableDeDiocese() {
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Responsable diocèsien</h2>
        <div className="content-graph-admin">
          <div className="content-btn-theme-title">
            <NavLink
              className="btn btn-theme-admin"
              to="/admin/responsable-dioceses/add"
              state={"responsable_diocesien"}
            >
              Ajouter un responsable diocèsien
            </NavLink>
          </div>
          <div className="admin-table mt-5">
            <ResponsableParoissienTable />
          </div>
        </div>
      </div>
    </div>
  );
}
