import React, { useEffect, useState } from "react";
import "./ObjetAdmin.css";
import { MdAddCircleOutline } from "react-icons/md";
import { Link, NavLink } from "react-router-dom";
import { CustomPagination } from "../../common/CustomPagination";
import Skeleton from "react-loading-skeleton";
import { AllObjetsReligieuxData } from "../data";
import { AiOutlineMore } from "react-icons/ai";
import { ObjetReligieux } from "../../../utils/api/objetReligieux/objetReligieux.type";
import { getImage } from "../../../utils/Utils";
import { useGetListObjetReligieuxQuery } from "../../../utils/api/objetReligieux/objetReligieux.api";
import { AlertInfo } from "../../common/Alert";
import { UseDeleteObjetReligieux } from "./form/AddOrUpdateForm";

const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteObjetReligieux(slug);
  return (
    <button className="dropdown-item" onClick={onDelete}>
      Supprimer
    </button>
  );
};

const ObjetCard = ({ item }: { item: ObjetReligieux }) => {
  const { nom, image, slug } = item;
  return (
    <div className="objet-card">
      <div className="cover-wrapper">
        <img src={getImage(image)} alt={nom} />
      </div>
      <div className="py-3 px-2 d-flex align-items-center justify-content-between w-100 wrapper">
        <p className="m-0 object__name">{nom}</p>
        <div className="btn-group">
          <button
            className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <AiOutlineMore style={{ fontSize: 18 }} />
          </button>
          <ul className="dropdown-menu">
            <li>
              <NavLink
                role="button"
                to={`/admin/objets-religieux/modifier/${slug}`}
                className="dropdown-item"
                state={item}
              >
                Modifier
              </NavLink>
            </li>
            <li>
              <BtnDelete slug={slug} />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

function ObjetAdmin() {
  const [page, setPage] = useState(1);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetListObjetReligieuxQuery({
      page,
      limit: 9,
    });
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Objets religieux</h2>
      </div>

      <div className="btn-action-container">
        <NavLink
          role="button"
          to={`/admin/objets-religieux/ajouter`}
          className="btn custom-add-btn"
        >
          Ajouter un nouvel objet
          <MdAddCircleOutline className="add-icon" />
        </NavLink>
      </div>

      <div className="objects-page-content">
        <div className="custom-content pt-4">
          <div className="row all-objects-row">
            {!!isLoading && <p>Chargement...</p>}
            {!isLoading &&
              (data?.results?.length ? (
                data?.results?.map((objr, key) => (
                  <div
                    className="col-md-4 mb-4 custom-wrapper"
                    key={`${key}_${objr.id}`}
                  >
                    <ObjetCard item={objr} />
                  </div>
                ))
              ) : (
                <AlertInfo message="Aucune donnée disponible" />
              ))}
          </div>

          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPage(page);
              }}
              perPage={9}
              showPerPage={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObjetAdmin;
