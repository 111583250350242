import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { IParoisse, ParoisseFormData } from "./paroisses.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ParoissesApi = createApi({
  reducerPath: "paroissesApi",
  tagTypes: ["paroisses", "paroissesList", "paroisse"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditParoisse: builder.mutation<
      IParoisse,
      { slug?: string; data: ParoisseFormData | FormData }
    >({
      invalidatesTags: ["paroisses", "paroissesList", "paroisse"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `paroisses/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `paroisses/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getParoisses: builder.query<PaginationResults<IParoisse>, TypeQuery>({
      providesTags: ["paroissesList"],
      query: (query) => QueryUrl("paroisses/", query),
    }),
    getParoisse: builder.query<IParoisse, string>({
      providesTags: ["paroisse"],
      query: (slug) => `paroisses/${slug}`,
    }),
    deleteParoisse: builder.mutation<ParoisseFormData, string>({
      query: (slug) => ({
        url: `paroisses/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["paroisses", "paroissesList"],
    }),
    paroisseBySlug: builder.query<PaginationResults<IParoisse>, TypeQuery>({
      providesTags: ["paroisses", "paroissesList"],
      query: ({ slug, ...query }) => QueryUrl(`paroisses/${slug}/`, query),
    }),
    paroisseByPays: builder.query<PaginationResults<IParoisse>, TypeQuery>({
      providesTags: ["paroisses", "paroissesList"],
      query: ({ slug, ...query }) => QueryUrl(`pays/${slug}/paroisses`, query),
    }),
  }),
});

export const {
  useAddOrEditParoisseMutation,
  useDeleteParoisseMutation,
  useGetParoissesQuery,
  useLazyGetParoisseQuery,
  useParoisseBySlugQuery,
  useParoisseByPaysQuery
} = ParoissesApi;

export function useParoisseFromLocation(): [
  IParoisse,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<IParoisse | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetParoisseQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  return [item as IParoisse, isLoading, slug as string, findById];
}
