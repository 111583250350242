import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import { MdAddCircleOutline } from "react-icons/md";
import { Input } from "../../../common/Input";
import { useGetListFormationQuery } from "../../../../utils/api/formations/formation.api";
import { AlertInfo } from "../../../common/Alert";
import { Formation } from "../../../../utils/api/formations/formation.type";
import UseCrudSeminaire from "../request/UseCrudSeminaire";
import { FaUser } from "react-icons/fa";
import { AiOutlineMore } from "react-icons/ai";
import { UseDeleteSeminaire } from "../request/UseCrudSeminaire";
import { PaginationResults } from "../../../../utils/api/user/user.type";
import { FormationSkeleton } from "../FormationAdmin";
import { getVideoId } from "../../../../utils/Utils";

function DeleteSeminaireBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteSeminaire(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}

const ListCoursSeminaire = ({
  go,
  setItem,
  data,
  isLoading,
}: {
  go: any;
  setItem: Dispatch<SetStateAction<Formation | undefined>>;
  data: Formation[];
  isLoading: boolean;
}) => {
  return (
    <>
      <div className="row row-seminaire mt-3">
        {!isLoading ? (
          data?.length > 0 ? (
            data?.map((seminaire, key) => {
              return (
                <div
                  className="col-md-3 card-wrapper mb-4"
                  key={`${key}_${seminaire?.id}`}
                >
                  <div className="custom-formation-card">
                    <span className="video-cover-content">
                      <iframe
                        src={
                          getVideoId(seminaire?.lien)
                            ? `https://www.youtube.com/embed/${getVideoId(
                                seminaire?.lien
                              )}`
                            : seminaire?.lien
                        }
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen={true}
                        loading="lazy"
                      ></iframe>
                    </span>
                    <span className="title">{seminaire?.titre} </span>
                    {seminaire?.lien && (
                      <a
                        className="video-link no-link"
                        href={seminaire?.lien}
                        target="_blank"
                      >
                        {seminaire?.lien}
                      </a>
                    )}
                    <div className="d-flex justify-content-between">
                      <span className="author pt-2">
                        <FaUser className="icon" />
                        {`${seminaire?.user?.prenom}${" "}${
                          seminaire?.user?.nom
                        }`}
                      </span>
                      <div className="btn-group">
                        <button
                          className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <AiOutlineMore style={{ fontSize: 18 }} />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setItem(seminaire);
                                go("add-cours-seminaire");
                              }}
                            >
                              Modifier
                            </button>
                          </li>
                          <li>
                            <DeleteSeminaireBtn slug={seminaire?.slug} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <AlertInfo message="Aucun seminaire trouvé" />
          )
        ) : (
          <FormationSkeleton />
        )}
      </div>
    </>
  );
};

const AddCoursSeminaire = ({
  seminaire,
  go,
  setItem,
}: {
  seminaire?: Formation;
  go?: any;
  setItem: Dispatch<SetStateAction<Formation | undefined>>;
}) => {
  const { register, onSubmit, errors, isLoading, handleReset } =
    UseCrudSeminaire(go, seminaire, setItem);
  return (
    <div className="content-graph-admin">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-6">
            <Input
              label="Titre"
              type="text"
              placeholder="Titre"
              {...register("titre")}
              error={errors?.titre}
              required
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Lien"
              type="text"
              placeholder="Lien"
              {...register("lien")}
              error={errors?.lien}
              required
            />
          </div>
          <input
            type="hidden"
            value="seminaire"
            {...register("type_formation")}
          />
        </div>
        <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
          <button
            disabled={isLoading}
            className="btn btn-theme-cancel w-100"
            type="button"
            onClick={() => {
              handleReset();
              // go("list-cours-seminaire")
            }}
          >
            Retour
          </button>
          <button
            disabled={isLoading}
            className="btn btn-theme-admin w-100"
            type="submit"
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>{seminaire ? "Modification..." : "Ajout..."}</span>
              </>
            ) : seminaire ? (
              "Modifier"
            ) : (
              "Ajouter"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const steps = [
  { id: "list-cours-seminaire", Component: ListCoursSeminaire },
  { id: "add-cours-seminaire", Component: AddCoursSeminaire },
];

function Seminaire({
  data,
  isLoading,
}: {
  data: PaginationResults<Formation>;
  isLoading: boolean;
}) {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [item, setItem] = useState<Formation>();

  const props = {
    go,
    seminaire: item,
    setItem,
    data: data?.results,
    isLoading,
  };
  return (
    <>
      <div className="btn-action-container">
        {index === 0 ? (
          <button
            onClick={() => {
              setItem(undefined);
              go("add-cours-seminaire");
            }}
            className="btn custom-add-btn"
          >
            Ajouter un séminaire
            <MdAddCircleOutline className="add-icon" />
          </button>
        ) : (
          <h3 className="custom-add-item-title">
            {!item ? "Ajouter" : "Modifier"} un cours sur le seminaire
          </h3>
        )}
      </div>

      <div className="custom-content pt-1">
        <Component {...props} />
      </div>
    </>
  );
}

export default Seminaire;
