import { Address } from "../auth/auth.type";
import { IGrades } from "../grades/grades.type";
import { IModeles } from "../modeles/modeles.type";
import { IParoisse } from "../paroisses/paroisses.type";
import { IPays } from "../pays/pays.type";

export enum UserType {
  admin = "admin",
  superAdmin = "superadmin",
  user = "user",
  membre = "membre",
  chef_diocese = "chef_diocese",
  chef_zone = "chef_zone",
  chef_district = "chef_district",
  diocese = "diocese",
  paroisse = "paroisse",
  responsable_paroissien = "responsable_paroissien",
  pasteur = "pasteur",
}

export type typeUser = "chef_diocese" | "chef_zone" | "diocese" | "chef_district";

export type CiviliteType = "homme" | "femme";

export interface IUser {
  actualites: boolean;
  adresse: string;
  avatar: string | any;
  bio: string;
  boite_postale: string;
  conseil_administration: boolean;
  conseil_pastoral: boolean;
  cotisations: boolean;
  created_at: string;
  dashboard: boolean;
  date_bapteme: string | null;
  date_debut_pasteur: string | null;
  date_dernier_grade: string | null;
  date_fin_pasteur: string | null;
  date_nomination: string | null;
  deleted: string | null;
  deletion_id: string;
  deletion_type: string;
  demandes: boolean;
  email: string;
  fideles: boolean;
  first_connexion: boolean;
  fonction: string;
  formations: boolean;
  grade: IGrades;
  id: number;
  is_active: boolean;
  is_archive: boolean;
  last_login: string;
  latitude: string;
  longitude: string;
  matricule: string;
  messages: boolean;
  nom: string;
  notification: boolean;
  office_pastoral: boolean;
  onction: string;
  modele: IModeles;
  parametres: boolean;
  paroisse_id: IParoisse;
  paroisses: boolean;
  password_reset_count: string;
  pays: IPays;
  prenom: string;
  qrcode: string;
  rang: string;
  region: string;
  role: string;
  sexe: string;
  slug: string;
  telephone: string;
  token: string;
  type_fidele: string;
  user_type: string;
  contitution_bleues: boolean;
  pasteurs: boolean;
  country: boolean;
  affectations: boolean;
  documentations: boolean;
  carnet_roses: boolean;
  finances: boolean;
  témoignages: boolean;
  necrologies: boolean;
  preambules: boolean;
  cni: string;
  responsable_paroissien: boolean;
  responsable_diocesien: boolean;
  ceremonies: boolean;
  collectes: boolean;
  objet_religieux: boolean;
}

export type UserFormData = Partial<{
  id?: number;
  actualites: boolean;
  adresse: string;
  avatar: string | any;
  bio: string;
  boite_postale: string;
  conseil_administration: boolean;
  conseil_pastoral: boolean;
  cotisations: boolean;
  created_at: string;
  dashboard: boolean;
  date_bapteme: string | null;
  date_debut_pasteur: string | null;
  date_dernier_grade: string | null;
  date_fin_pasteur: string | null;
  date_nomination: string | null;
  deleted: string | null;
  deletion_id: string;
  deletion_type: string;
  demandes: boolean;
  email: string;
  fideles: boolean;
  first_connexion: boolean;
  fonction: string;
  formations: boolean;
  grade: number;
  is_active: boolean;
  is_archive: boolean;
  last_login: string;
  latitude: string;
  longitude: string;
  matricule: string;
  messages: boolean;
  nom: string;
  notification: boolean;
  office_pastoral: boolean;
  onction: string;
  modele: IModeles;
  parametres: boolean;
  paroisse_id: number;
  paroisses: boolean;
  password_reset_count: string;
  pays: number;
  prenom: string;
  qrcode: string;
  rang: string;
  region: string;
  role: string;
  sexe: string;
  slug: string;
  telephone: string;
  token: string;
  type_fidele: string;
  user_type: string;
  contitution_bleues: boolean;
  pasteurs: boolean;
  country: boolean;
  affectations: boolean;
  documentations: boolean;
  carnet_roses: boolean;
  finances: boolean;
  témoignages: boolean;
  necrologies: boolean;
  preambules: boolean;
  cni: string;
  responsable_paroissien: boolean;
  responsable_diocesien: boolean;
  ceremonies: boolean;
  collectes: boolean;
  objet_religieux: boolean;
}>;

export interface AuthState {
  user: IUser | any;
  token?: string | null;
}

export type PaginationResults<T> = {
  count?: number;
  next?: string | null;
  previous?: string | null;
  results: T[];
  limit?: number;
  nbPage?: number;
  nbPages?: number;
  nb_contributions?: number;
  total_amount?: number;
  remaning_contributions?: number;
};

export type TypeQuery = Partial<{
  page?: number;
  limit?: number;
  word?: string | null;
  slug?: string;
  name?: string | null;
  minPrice?: string | null;
  maxPrice?: string | null;
  category?: string | null;
  orderBy?: string | null;
  order?: "DESC" | "ASC";
  user?: string;
  product?: string;
  productType?: "pack" | "product";
  numOrder?: string;
  search?: string;
  userType?: string;
  q?: string;
  q_titre?: string;
  q_type?: string;
  q_format?: string;
  pays?: string;
  type?: string;
  user_type?: string;
  filter?: string;
  q_date?: string;
}>;

export type AddressFormData = {
  _id: string;
  prenom: string;
  nom: string;
  adresse: string;
  complementAdresse: string;
  quartier: string;
  ville: string;
  telephone1: string;
  telephone2: string;
  isDefault: boolean;
  latitude: number | string;
  longitude: number | string;
};

export type GraphType = {
	month: string;
	entree: number;
	depense: number;

  depenses_month: number;
  entrees_month: number;
  proprietaires: number;
  agences: number;
  commissionproprietaires: number;
  commissionagences: number;
  total_client: number,
  paiements_month: number
};
export interface IAdminData {
  fideles: string,
  fideles_baptises: string,
  activites: string,
  finances: string,
  graph: GraphType[]
}