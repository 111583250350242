import React, { useState } from "react";
import { Input } from "../../../common/Input";
import { BtnAnnulerModal } from "../../../common/Button";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import UseFormChangePassword from "../requestForm/UseFormChangePassword";

function ChangerPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    passwordHandleChange,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    passwordHandleReset,
    PasswordInput,
  } = UseFormChangePassword();
  return (
    <div className="medsain-profil-tab-item">
      <div className="medsain-profil-tab-title">Changer mot de passe</div>
      <form className="mt-4" onSubmit={onSubmit}>
        <div className="row row-add-student my-3">
          <div className="col-md-4 position-relative">
            <Input
              type={showPassword ? "text" : "password"}
              label="Ancien mot de passe"
              id="old_password"
              placeholder="********"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("old_password")}
              error={errors?.old_password}
            />
            <span
              className="icon-edit-info-profil"
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          </div>
          <div className="col-md-4 position-relative">
            <Input
              type={showNewPassword ? "text" : "password"}
              label="Nouveau mot de passe"
              id="new_password"
              placeholder="********"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              onChange={passwordHandleChange}
              error={errors?.new_password}
            />
            <span
              className="icon-edit-info-profil"
              onClick={() => {
                setShowNewPassword(!showNewPassword);
              }}
            >
              {showNewPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          </div>

          <div className="col-md-4 position-relative">
            <Input
              type={showConfirmPassword ? "text" : "password"}
              label="Confirmer mot de passe"
              id="confirm_password"
              placeholder="********"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("confirm_password")}
              error={errors?.confirm_password}
            />
            <span
              className="icon-edit-info-profil"
              onClick={() => {
                setShowConfirmPassword(!showConfirmPassword);
              }}
            >
              {showConfirmPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>
          </div>
          <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
            <BtnAnnulerModal label="Annuler" customClass="custom-btn-annuler" />
            <button className="btn btn-theme-admin" type="submit">
              Mettre à jour
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangerPassword;
