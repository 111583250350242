import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Forum, ForumFormData } from "./forum.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ForumApi = createApi({
  reducerPath: "ForumApi",
  tagTypes: ["forum", "forumList", "forumByParoisse"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditForum: build.mutation<
      Forum,
      {
        slug?: string;
        data: ForumFormData | FormData;
      }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `forums/${slug}/` : `forums/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["forum", "forumByParoisse"],
    }),
    getForum: build.query<Forum, string>({
      query: (slug) => `forums/${slug}/`,
      providesTags: ["forum"],
    }),
    getForumByParoisse: build.query<PaginationResults<Forum>, TypeQuery>({
      query: ({ slug, ...query }) =>
        QueryUrl(`paroisse/${slug}/forums/`, query),
      providesTags: ["forumByParoisse"],
    }),
    deleteForum: build.mutation<ForumFormData, string>({
      query: (slug) => ({
        url: `forums/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["forum", "forumByParoisse"],
    }),
  }),
});

export const {
  useAddOrEditForumMutation,
  useGetForumByParoisseQuery,
  useDeleteForumMutation,
  useLazyGetForumQuery,
} = ForumApi;

export function useForumFromLocation(): [
  Forum,
  boolean,
  string,
  (slug: string) => any
] {
  const itemState = useLocationState<Forum | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetForumQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Forum, isLoading, slug as string, findById];
}
