import React from "react";
import Carte1 from "../../../../assets/appImages/carte1.svg"
import Carte2 from "../../../../assets/appImages/carte2.svg"
import Carte3 from "../../../../assets/appImages/carte3.svg"
import Carte4 from "../../../../assets/appImages/carte4.svg"
import LineChartBanque from "./LineChartBanque";

function BanquesTab() {

  return (
    <div className="content-finance-tab">
        <div className="row pt-2">
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-carte-banque">
              <img src={Carte1} alt="Carte banquaire" className="img-cate-banque" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
          <div className="item-carte-banque">
              <img src={Carte2} alt="Carte banquaire" className="img-cate-banque" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
          <div className="item-carte-banque">
              <img src={Carte3} alt="Carte banquaire" className="img-cate-banque" />
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
          <div className="item-carte-banque">
              <img src={Carte4} alt="Carte banquaire" className="img-cate-banque" />
            </div>
          </div>
        </div>
        <div className="container-filter-banque-finance py-4">
          <h4 className="title-select-banque-finance mb-0">Finances</h4>
          <div className="container-select-filter-banque-finance">
            <select className="form-control-select-banque">
              <option value="">Société Générale de Banque du Sénégal</option>
            </select>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-3">
              <div className="container-card-banque-finance">
                <span className="title-card-banque-finance">Crédit</span>
                <p className="chiffre-item-stat-dashboad-view mb-1 mt-3">$7,785</p>
              </div>
          </div>
        </div>
        <div className="content-graph-admin mb-2">
          <LineChartBanque />
        </div>
    </div>
  );
}

export default BanquesTab;
