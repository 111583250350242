import React from "react";
import { CustomOption, FormError, Input, OptionType, animatedComponents } from "../../common/Input";
import Select from "react-select";
import { BtnSubmit } from "../../common/Button";
import UseCrudFideleForm from "./requestForm/UseCrudFideleForm";
import BreadCrumb from "../../shared/BreadCrumb";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { SelectInput } from "../../common/SelectInput";
import { useUserFromLocation } from "../../../utils/api/user/user.api";
import ErrorMessage from "../../common/ErrorMessage";

function AddFideleForm() {
  const [item] = useUserFromLocation();
  const {
    register,
    errors,
    isLoading,
    phone,
    handleChangePhone,
    onSubmit,
    address,
    onChangeAddress,
    setSearch,
    paroisses,
    onSelectParoisse,
    Controller,
    control,
    selected,
    setQuery,
    onSelectGrade,
    option,
    options,
    onChangeType,
    type,
    pays,
    selectedPays,
    onSelectPays,
    handelAvatarChange,
    previewAvatar,
    isLoadParoisse,
    optionsModeles,
    optionModele,
    onSelectModele,
    isLoadCertificat,
    handleParoisseFocus,
    showParoisseError
  } = UseCrudFideleForm(item);
  // console.log({item})
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb
            pageName="Fidèles"
            to="/admin/paroisses"
            secondPage={item ? "Modifier" : "Ajouter"}
            state="fideles"
          />
        </div>
      </div>
      <div className="form-content content-graph-admin">
        <form onSubmit={onSubmit}>
          <div className="row row-add-fidel-form">
            <div className="col-md-6">
              <Input
                label="Prénom"
                type="text"
                placeholder="Prénom"
                required
                {...register("prenom")}
                error={errors.prenom}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Nom"
                type="text"
                placeholder="Nom"
                required
                {...register("nom")}
                error={errors.nom}
              />
            </div>
            <div className="col-md-6 mb-3">
              <SelectInput
                label="Sexe"
                id="sexe"
                required
                {...register("sexe")}
                customClassInput="form-control-modal-custom"
                error={errors.sexe}
                options={[
                  { label: "Homme", value: "homme" },
                  { label: "Femme", value: "femme" },
                ]}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Email"
                type="email"
                placeholder="Email"
                required
                {...register("email")}
                error={errors.email}
              />
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor={"telephone"}
                  className={`form-label form-label-modal-custom`}
                  aria-labelledby={"telephone"}
                >
                  Téléphone
                </label>

                <PhoneInput
                  defaultMask={".. ... .. .."}
                  containerClass="phone-input-admin"
                  inputClass="form-control custom-padding"
                  country={"sn"}
                  placeholder=""
                  inputProps={{
                    name: "telephone",
                    required: true,
                    autoFocus: true,
                  }}
                  countryCodeEditable={false}
                  enableAreaCodes={true}
                  prefix="+"
                  value={phone}
                  onChange={(phone, country: any) => {
                    handleChangePhone(phone, country);
                  }}
                  inputStyle={{
                    width: "100%",
                    paddingBottom: "22px",
                    borderRadius: "10px",
                    paddingTop: "22px",
                    height: "54px",
                    backgroundColor: "#F2F6FA",
                    border: "none",
                  }}
                  dropdownStyle={{
                    backgroundColor: "#F2F6FA",
                  }}
                  enableLongNumbers={true}
                />
                <FormError error={errors?.telephone?.message} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label
                  htmlFor={"address"}
                  className={`form-label form-label-modal-custom`}
                  aria-labelledby={"address"}
                >
                  Adresse
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <GoogleInput
                  className={`form-control form-control-modal-custom`}
                  placeholder="Adresse"
                  value={address}
                  onChange={onChangeAddress}
                />
                <FormError error={errors.adresse} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-4">
                <label
                  htmlFor={"pays"}
                  className={`form-label form-label-modal-custom`}
                  aria-labelledby={"pays"}
                >
                  Pays{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>

                <Controller
                  name="pays"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        // isMulti
                        options={pays}
                        classNamePrefix="react-select-custom"
                        onChange={onSelectPays}
                        value={selectedPays}
                      />
                    );
                  }}
                />
                <FormError error={errors.pays} />
              </div>
            </div>
            <div className="col-md-6">
                <Input
                  label="Nationalité"
                  type="text"
                  placeholder="Nationalité"
                  {...register("nationalite")}
                  error={errors.nationalite}
                />
              </div>
            <div className="col-md-6 d-flex flex-column gap-1 mb-3">
              <label htmlFor="" className="form-label form-label-modal-custom">
                Paroisse
                <span className="text-danger" style={{ fontSize: 15 }}>
                  *
                </span>
              </label>
              <Controller
                name="paroisse_id"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={paroisses}
                      value={selected ? selected : null}
                      onInputChange={(val) => setQuery(val)}
                      onChange={(val) => onSelectParoisse(val)}
                      onFocus={handleParoisseFocus}
                      className="custom-select-items"
                      isLoading={isLoadParoisse}
                      // isDisabled={isLoadParoisse}
                    />
                  );
                }}
              />
              {showParoisseError && (
                <div className="text-danger mt-1">
                  Veuillez d'abord sélectionner un pays.
                </div>
              )}
              <FormError error={errors.paroisse_id} />
            </div>
            <div className="col-md-6 d-flex flex-column gap-1 mb-3">
              <label htmlFor="" className="form-label form-label-modal-custom">
                Grade{" "}
              </label>
              <Controller
                name="grade"
                control={control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      options={options}
                      value={option}
                      onInputChange={(val) => setSearch(val)}
                      onChange={(val) => onSelectGrade(val)}
                      className="custom-select-items"
                    />
                  );
                }}
              />

              <FormError error={errors.grade} />
            </div>
            <div className="col-md-6">
              <SelectInput
                label="Type de fidèle"
                id="type"
                onChange={(e) => onChangeType(e)}
                value={type}
                required
                customClassInput="form-control-modal-custom"
                error={errors.type_fidele}
                options={[
                  { label: "Baptisé", value: "baptise" },
                  { label: "Sympathisant", value: "sympathisant" },
                ]}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Fonction"
                type="text"
                placeholder="Fonction"
                {...register("fonction")}
                error={errors.fonction}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Date du 1er baptême"
                type="date"
                placeholder="|"
                {...register("date_bapteme")}
                error={errors.date_bapteme}
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Date de nomination"
                type="date"
                placeholder="|"
                {...register("date_nomination")}
                error={errors.date_nomination}
              />
            </div>

            <div className="col-md-6">
              <Input
                label="Date du dernier grade"
                type="date"
                placeholder="|"
                {...register("date_dernier_grade")}
                error={errors.date_dernier_grade}
              />
            </div>
            <div className="col-md-6 mb-3">
            <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Modele de certificat{" "}
                </label>
                <Controller
                  name="modele"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={{
                            ...animatedComponents,
                            Option: CustomOption as any
                          }}
                          options={optionsModeles as OptionType[]}
                          value={optionModele as OptionType}
                          onInputChange={(val) => setSearch(val)}
                          onChange={(val) => onSelectModele(val)}
                          className="custom-select-items"
                          isLoading={isLoadCertificat}
                          // isDisabled={isLoadCertificat}
                        />
                    );
                  }}
                />
                <FormError error={errors.modele} />
            </div>
            <div className="col-md-6 mb-3">
              <SelectInput
                label="Onction"
                id="onction"
                {...register("onction")}
                customClassInput="form-control-modal-custom"
                error={errors.onction}
                options={[
                  { label: "Prophete", value: "prophete" },
                  { label: "Clerge", value: "clerge" },
                  { label: "Honorable", value: "honorable" },
                  { label: "Membre", value: "membre" },
                ]}
              />
            </div>
            <div className="col-md-6">
              <div>
                <Input
                  type="file"
                  label="Ajouter une photo"
                  id="avatar"
                  placeholder="Ajouter une photo"
                  accept="image/*"
                  onChange={(e) => handelAvatarChange(e)}
                  name="avatar"
                />
                {errors?.avatar && (
                  <ErrorMessage message={errors?.avatar?.message} />
                )}
                {previewAvatar && (
                  <div className="img-preview-container mb-3">
                    <img src={previewAvatar} className="type-img-preview" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex flex-r mt-3">
            <div className="col-md-3">
              <BtnSubmit label="Enregistrer" isLoading={isLoading} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddFideleForm;
