/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useRef, useState } from "react";
import "./PaysAdmin.css";
import PaysAdminTable from "./PaysTable/PaysAdminTable";
import AddPaysModal from "./modals/AddPaysModal";
import { Map, Marker } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";
import { InfoWindow } from "google-maps-react";
// import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";
import ReactDOM from "react-dom";
import { useGetParoissesQuery } from "../../../utils/api/paroisses/paroisses.api";
import { getAvatar } from "../../../utils/Utils";
import { AiFillEye } from "react-icons/ai";
import iconMarker from "../../../assets/icons/marker.svg";
import { useNavigate } from "react-router-dom";

const InfoWindowEx = (props) => {
  const infoWindowRef = React.createRef<any>();
  const contentElement = document.createElement(`div`);
  useEffect(() => {
    ReactDOM.render(React.Children.only(props.children), contentElement);
    infoWindowRef?.current?.infowindow?.setContent(contentElement);
  }, [props.children]);

  return <InfoWindow ref={infoWindowRef} {...props} />;
}

function PaysAdmin(props) {
  const [modalAddPays, setModalAddPays] = useState(false);
  function openModalAddPays() {
    setModalAddPays(true);
  }
  const { data } = useGetParoissesQuery({
    limit: 1000
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const [selectedPlace, setSelectedPlace] = useState<any>({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);

  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker);
    setSelectedPlace(props);
    setShowingInfoWindow(true);
  };

  const onInfoWindowClose = () => {
    setActiveMarker(null);
    setShowingInfoWindow(false);
  };

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setActiveMarker(null);
      setShowingInfoWindow(false);
    }
  };

  const navigate = useNavigate()
  const onClickItem = (item) => {
    console.log("item")
    navigate(`/admin/paroisses/chef/${item?.slug}`, {state: item});
  }

  if (!props.loaded) return <div>Loading...</div>;

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container custom-pos-flex mb-3">
        <h2 className="chart-item-admin-title mb-4">Pays</h2>
        <div className="container-display-title-theme">
          <button
            onClick={(e) => openModalAddPays()}
            className="btn btn-theme-admin"
          >
            <span>Ajouter un pays</span>
          </button>
          <AddPaysModal
            modalAddPays={modalAddPays}
            setModalAddPays={setModalAddPays}
          />
        </div>
      </div>

      {/* <div className="big-map-container"> */}

      <div
        style={{ height: "90vh", width: "100%" }}
        className="container-map mt-3 position-relative"
      >
        <Map
          className="map"
          google={props.google}
          onClick={onMapClicked}
          style={{ height: "100%", position: "relative", width: "100%" }}
          initialCenter={{
            lat: 0,
            lng: -4,
          }}
          zoom={4}
        >
          {data?.results?.map((item) => (
            <Marker
              name={item?.nom}
              position={{
                lat: parseFloat(item?.latitude),
                lng: parseFloat(item?.longitude),
              }}
              onClick={onMarkerClick}
              paroisse={item}
              icon={{
                url: iconMarker,
                scaledSize: new google.maps.Size(34, 34),
              }}
              key={item?.id}
            ></Marker>
          ))}
          {!!selectedPlace && (
            <InfoWindowEx
              marker={activeMarker}
              onClose={onInfoWindowClose}
              visible={showingInfoWindow}
              // position={selectedPlace?.position}
            >
              <InfoBullParoisse selectedPlace={selectedPlace} onClick={onClickItem} />
            </InfoWindowEx>
          )}
        </Map>
      </div>
      {/* </div> */}
      <div className="my-4">
        <PaysAdminTable
          modalAddPays={modalAddPays}
          setModalAddPays={setModalAddPays}
          openModalAddPays={openModalAddPays}
        />
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
})(PaysAdmin);

export const InfoBullParoisse = ({ selectedPlace, onClick }) => {
  // const navigate = useNavigate()
  const { paroisse } = selectedPlace;
  return (
    <div className="praticien-agenda-user-avatar-container mb-4">
      <div className="praticien-agenda-user-avatar-top"></div>
      <div className="praticien-agenda-user-avatar-bottom">
        <div className="div-praticien-agenda-user-avatar">
          <img
            src={getAvatar(paroisse?.image)}
            alt="image de la paroisse"
            className="praticien-agenda-user-avatar-image"
          />
        </div>
        <div className="infosbull-paroisse-infos-container">
          <div className="praticien-agenda-user-infos">
            <h2>{selectedPlace?.name}</h2>
            <h4>{paroisse?.email}</h4>
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn custom-btn-voir-paroisse"
              // href={`/admin/paroisse/chef/${paroisse?.slug}`}
              onClick={() => onClick(paroisse)}
            >
              Profil <AiFillEye />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

{
  /* <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63218495.12795645!2d-23.793376344075547!3d14.737974199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2ssn!4v1693319418545!5m2!1sfr!2ssn"
          className="frame-map"
          style={{ border: 0 }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */
}
