import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { 
    Formation,
    FormationFormData
} from "./formation.type";


export const FormationApi = createApi({
  reducerPath: "FormationApi",
  tagTypes: ["formation", "formationApi"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditFormation: build.mutation<
    Formation,
      { 
        slug?: string; 
        data: FormationFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? 
            `formations/${slug}/` 
            : 
            `formations/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["formation"],
    }),

    getListFormation: build.query<PaginationResults<Formation>, TypeQuery>({
      query: (query) => QueryUrl("formations/", query),
      providesTags: ["formation"],
    }),
    
    deleteFormation: build.mutation<FormationFormData, string>({
      query: (slug) => ({
        url: `formations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["formation", "formationApi"],
    }),

    
  }),
});

export const {
    useAddOrEditFormationMutation,
    useDeleteFormationMutation,
    useGetListFormationQuery,
} = FormationApi;