import React, { useState } from "react";
import "./Login.css";
import Logo from "../../assets/appImages/logo.png";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { UseResetPasswordForm } from "./requestForm/UseResetPasswordForm";
import { FormError } from "../common/Input";
import Auth from "../../assets/appImages/auth-page.png";

const ResetPassword = () => {
  const {
    register,
    errors,
    isLoading,
    onSubmit,
    haveMinCharacter,
    haveMinLowercase,
    haveMinNumber,
    haveMinSpecialCharacter,
    haveMinUppercase,
    password,
    passwordHandleChange,
  } = UseResetPasswordForm();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div>
      <div className="container-page-login-app">
        <div className="container-img-auth-login">
          <img src={Auth} alt="Login auth" className="img-auth-login-page" />
        </div>
        <div className="auth-row">
          <div className="auth-col auth-right-side-col">
            <div className="auth-right-side-container">
              <div className="auth-form-container text-center pb-4">
                <h2 className="title-auth-form-text pt-5">
                  Mot de passe oublié
                </h2>
                <div className="mt-5">
                  <form id="auth-form" onSubmit={onSubmit}>
                    <div className="row auth-form-row">
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Adresse email
                          </label>
                          <input
                            type={`email`}
                            className="form-control auth-form-control"
                            id="email-or-username"
                            aria-label="Username"
                            placeholder="Email"
                            {...register("email")}
                          />
                          <FormError error={errors?.email?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-3">
                        <div className="auth-form-group">
                          <label className="form-label form-label-auth-login">
                            Code
                          </label>
                          <input
                            type={`text`}
                            className="form-control auth-form-control"
                            id="code-or-username"
                            aria-label="Username"
                            placeholder="Code"
                            {...register("code")}
                          />
                          <FormError error={errors?.code?.message} />
                        </div>
                      </div>
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Nouveau mot de passe
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="new_password"
                            placeholder="Mot de passe"
                            aria-label="Password"
                            aria-placeholder="***********"
                            onChange={passwordHandleChange}
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )}
                          </span>
                        </div>

                        <FormError error={errors?.new_password?.message} />
                      </div>
                      {/* ============= Validation password ============= */}
                      {password && (
                        <div className="col-md-12">
                          <div className="input-group my-2 password-validation-terms-row">
                            <div className="password-validation-terms-container">
                              <h4 className="password-validation-terms-title">
                                Votre mot de passe doit contenir :{" "}
                              </h4>
                              <ul className="password-validation-terms-ul">
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinCharacter && "text-success")
                                  }
                                >
                                  Au minimum 8 caractères
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinUppercase && "text-success")
                                  }
                                >
                                  Au minimum 1 caractère en majuscule
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinLowercase && "text-success")
                                  }
                                >
                                  Au minimum 1 caractère en minuscule
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinNumber && "text-success")
                                  }
                                >
                                  Au minimum 1 nombre
                                </li>
                                <li
                                  className={
                                    "password-rule-item " +
                                    (haveMinSpecialCharacter && "text-success")
                                  }
                                >
                                  Au minimum 1 caractère spéciale
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-md-12 auth-input-col mb-2">
                        <div className="auth-form-group position-relative">
                          <label className="form-label form-label-auth-login">
                            Confirmer mot de passe
                          </label>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control auth-form-control"
                            id="new_password_confirm"
                            placeholder="Mot de passe"
                            aria-label="Password"
                            aria-placeholder="***********"
                            {...register("new_password_confirm")}
                          />
                          <span
                            className="show-hide-password"
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}
                          >
                            {showPassword ? (
                              <AiFillEyeInvisible />
                            ) : (
                              <AiFillEye />
                            )}
                          </span>
                        </div>
                        <FormError
                          error={errors?.new_password_confirm?.message}
                        />
                      </div>
                      <div className="col-md-12 auth-submit-btn-container mt-5">
                        <button
                          disabled={isLoading}
                          className="btn auth-submit-btn trans-0-2"
                          type="submit"
                        >
                          {isLoading ? (
                            <>
                              <span
                                className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                                role="status"
                              ></span>
                              <span>Envoyer...</span>
                            </>
                          ) : (
                            "Envoyer"
                          )}
                        </button>
                      </div>

                      <div className="col-md-12 mt-3 text-right">
                        <span className="mt-2">
                          <NavLink
                            className="nav-link forget-password-link"
                            to="/"
                            data-tooltip-content="Gestionnaires"
                          >
                            Se connecter
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
