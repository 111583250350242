import React, { Dispatch, SetStateAction, useState } from "react";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import { MdAddCircleOutline } from "react-icons/md";
import { FormError, Input } from "../../../common/Input";
import { AlertInfo } from "../../../common/Alert";
import { Formation } from "../../../../utils/api/formations/formation.type";
import UseCrudBateme from "../request/UseCrudBapteme";
import { UseDeleteBapteme } from "../request/UseCrudBapteme";
import { FaUser } from "react-icons/fa";
import { AiOutlineMore } from "react-icons/ai";
import { ApiBaseUrl } from "../../../../utils/http";
import { RiAlarmWarningLine } from "react-icons/ri";
import MyDropzoneFile, { RenderFile } from "../../../common/MyDropzoneFile";
import { PaginationResults } from "../../../../utils/api/user/user.type";
import { FormationSkeleton } from "../FormationAdmin";

function DeleteBaptemeBtn({ slug }: { slug: string }) {
  const onDelete = UseDeleteBapteme(slug);
  return (
    <button className="dropdown-item" onClick={() => onDelete()}>
      Supprimer
    </button>
  );
}

const ListCoursBateme = ({
  go,
  setItem,
  data,
  isLoading,
}: {
  go: any;
  setItem: Dispatch<SetStateAction<Formation | undefined>>;
  data: Formation[];
  isLoading: boolean;
}) => {
  return (
    <>
      <div className="row row-bateme mt-3">
        {!isLoading ? (
          data?.length > 0 ? (
            data?.map((bapteme, key) => {
              return (
                <div
                  className="col-md-3 card-wrapper mb-4"
                  key={`${key}_${bapteme?.id}`}
                >
                  <div className="custom-formation-card">
                    {!!bapteme?.fichier && bapteme?.fichier !== undefined ? (
                      <span className="video-cover-content">
                        <video
                          className="formation-video"
                          controls
                          muted
                          loop
                          poster={bapteme?.fichier}
                        >
                          <source src={`${ApiBaseUrl}${bapteme?.fichier}`} />
                        </video>
                      </span>
                    ) : (
                      <span className="url-not-found-content">
                        <RiAlarmWarningLine style={{ fontSize: 25 }} /> <br />
                        Oups!! <br />
                        <br />
                        L'url de la vidéo est non définie ou peut-etre vide
                        <br />
                      </span>
                    )}
                    <span className="title">{bapteme?.titre}</span>
                    <div className="d-flex justify-content-between">
                      <span className="author pt-2">
                        <FaUser className="icon" />
                        {`${bapteme?.user?.prenom}${" "}${bapteme?.user?.nom}`}
                      </span>
                      <div className="btn-group">
                        <button
                          className="btn btn-secondary btn-sm dropdown-toggle documentation-archive-custom-btn"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <AiOutlineMore style={{ fontSize: 18 }} />
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setItem(bapteme);
                                go("add-cours-bateme");
                              }}
                            >
                              Modifier
                            </button>
                          </li>
                          <li>
                            <DeleteBaptemeBtn slug={bapteme?.slug} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <AlertInfo message="Oups! Aucun bapteme trouvé" />
          )
        ) : (
          <FormationSkeleton />
        )}
      </div>
    </>
  );
};

const AddCoursBateme = ({
  bapteme,
  go,
  setItem,
}: {
  bapteme?: Formation;
  go?: any;
  setItem: Dispatch<SetStateAction<Formation | undefined>>;
}) => {
  const {
    register,
    onSubmit,
    errors,
    setVideo,
    video,
    handleReset,
    isLoading,
  } = UseCrudBateme(go, bapteme, setItem);

  //   console.log("video", video);

  return (
    <div className="content-graph-admin">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col-md-12">
            <Input
              label="Titre"
              type="text"
              placeholder="Titre"
              {...register("titre")}
              error={errors?.titre?.message}
              required
            />
          </div>
          <div className="col-md-12">
            <label className="form-label form-label-modal-custom">
              Ajouter une vidéo{" "}
              <span className="text-danger" style={{ fontSize: 15 }}>
                *
              </span>
            </label>
            <MyDropzoneFile isVideoFile setFiles={setVideo} maxFile={1} />
            {!!video && (
              <RenderFile file={video?.name || video} isVideoFile={true} />
            )}
            <FormError error={errors?.fichier} />
          </div>
        </div>
        <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
          <button
            disabled={isLoading}
            className="btn btn-theme-cancel w-100"
            type="button"
            onClick={() => {
              handleReset();
              // go("list-cours-seminaire")
            }}
          >
            Retour
          </button>
          <button
            disabled={isLoading}
            className="btn btn-theme-admin w-100"
            type="submit"
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>{bapteme ? "Modification..." : "Ajout..."}</span>
              </>
            ) : bapteme ? (
              "Modifier"
            ) : (
              "Ajouter"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

const steps = [
  { id: "list-cours-bateme", Component: ListCoursBateme },
  { id: "add-cours-bateme", Component: AddCoursBateme },
];

function Bapteme({
  data,
  isLoading,
}: {
  data: PaginationResults<Formation>;
  isLoading: boolean;
}) {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [item, setItem] = useState<Formation>();

  const props = { go, bapteme: item, setItem, data: data?.results, isLoading };
  return (
    <>
      <div className="btn-action-container">
        {index === 0 ? (
          <button
            onClick={() => {
              setItem(undefined);
              go("add-cours-bateme");
            }}
            className="btn custom-add-btn"
          >
            Ajouter un baptème
            <MdAddCircleOutline className="add-icon" />
          </button>
        ) : (
          <h3 className="custom-add-item-title">
            {!item ? "Ajouter" : "Modifier"} un cours sur le baptème
          </h3>
        )}
      </div>

      <div className="custom-content pt-1">
        <Component {...props} />
      </div>
    </>
  );
}

export default Bapteme;
