/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
  Formation,
  FormationFormData,
} from "../../../../utils/api/formations/formation.type";
import {
  useAddOrEditFormationMutation,
  useDeleteFormationMutation,
} from "../../../../utils/api/formations/formation.api";
import { useAppSelector } from "../../../../redux/hook";

function UseCrudSeminaire(
  go: any,
  formation?: Formation,
  setItem?: Dispatch<SetStateAction<Formation | undefined>>
) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    titre: yup.string().required().label("Titre").nullable(),
    lien: yup.string().url().required().label("Lien").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<FormationFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [lien, setLien] = useState<string>("");
  const [addOrUpdateFormation, { isLoading, isSuccess, error, isError }] =
    useAddOrEditFormationMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: formation
          ? "Séminaire modifié avec succès !"
          : "Séminaire ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset()
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userId = user?.id;

  const onSubmit = async (data: any) => {
    data["user"] = userId;
    data["type_formation"] = "seminaire";
    console.log("DATA SUBMITED: ", data);

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "fichier") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    await addOrUpdateFormation({ slug: formation?.slug, data: fd });
  };

  const handleReset = () => {
    reset();
    setLien("");
    if (setItem) {
      setItem(undefined);
    }
    go("list-cours-seminaire");
  };

  useEffect(() => {
    if (formation?.id) {
      const fields: (keyof FormationFormData)[] = ["titre", "lien"];
      setLien(formation.lien);
      setValue("lien", formation.lien);
      for (let field of fields) {
        register(field);
        setValue(field, formation[field]);
      }
      // console.log("formation", formation);
    }
  }, [formation]);

  useEffect(() => {
    if (lien) {
      setValue("lien", lien);
    }
    // console.log("lien", lien);
  }, [lien]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    lien,
    handleReset,
    setLien,
    go,
  };
}

export default UseCrudSeminaire;

export function UseDeleteSeminaire(slug: string) {
  const [deleteData] = useDeleteFormationMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce séminaire ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Séminaire supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
