import React from "react"
import "./FooterAdmin.css"
import VersionChecker from "../../../../versionChecker/VersionChecker"

const FooterAdmin = () => {
    return (
        <div className="container-footer-admin">
            <div className="content-text-coopyright-footer-admin">
                <p className="text-coopyright-footer-admin">Copyright © 2023 <span style={{ fontWeight: 700, fontSize: 14  }}>  EGLISE DU CHRISTIANISME CELESTE</span></p>
            </div>
            <div className="container-version-footer-app">
                <p className="text-version-footer-app">{"V" + VersionChecker()}</p> 
            </div>   
        </div>
    )
}

export default FooterAdmin