/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import {
  DocumentationFormData,
  IDocumentation,
} from "../../../../utils/api/documentations/documentations.type";
import {
  useAddOrEditDocumentationsMutation,
  useDeleteDocumentationMutation,
} from "../../../../utils/api/documentations/documentations.api";
import { useAppSelector } from "../../../../redux/hook";

function UseCrudBibliotheque(
  setModalAddVideo: any,
  typeDocumentations: any,
  setTypeDocumentations: any,
  formatDocumentations: any,
  setFormatDocumentations: any,
  documentation?: IDocumentation
) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    titre: yup.string().required("Le titre est requis").label("Titre"),
    fichier: yup.mixed().when("hasEdit", {
      is: false,
      then: () =>
        yup
          .mixed()
          .required("Veuillez uploader au moins une image.")
          .nullable(),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<DocumentationFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [pictures, setPictures] = useState<any>(null);
  const [content, setContent] = useState<string>("");
  const [addOrUpdateDocumentation, { isLoading, isSuccess, error, isError }] =
    useAddOrEditDocumentationsMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: documentation
          ? "Documentation modifiée avec succès !"
          : "Documentation ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!documentation) {
          reset();
          setPictures(null);
          setTypeDocumentations("");
        }
        setModalAddVideo(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;
  const onSubmit = async (data: any) => {
    if (!documentation) {
      data["user"] = userID;
      data["type_documentation"] = typeDocumentations;
      data["format"] = formatDocumentations;
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "fichier") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key]);
        }
      } else {
        fd.append(key, data[key]);
      }
    }
    console.log("data submitted", data);
    await addOrUpdateDocumentation({ slug: documentation?.slug, data: fd });
  };

  const handleReset = () => {
    reset();
    setContent("");
    setTypeDocumentations("");
    setFormatDocumentations("");
  };

  useEffect(() => {
    if (documentation?.id) {
      const fields: (keyof DocumentationFormData)[] = [
        "titre",
        "type_documentation",
      ];
      setPictures(documentation?.fichier);
      for (let field of fields) {
        if (field !== "fichier") {
          register(field);
          setValue(field, documentation[field]);
        }
      }
    }
  }, [documentation]);

  useEffect(() => {
    if (pictures?.name) {
      setValue("fichier", pictures);
    }
    console.log("pictures", pictures);
  }, [pictures]);

  const handleChangeContent = useCallback((value: string) => {
    setContent(value);
    setValue("content", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    handleReset,
    handleChangeContent,

    pictures,
    setPictures,
  };
}

export default UseCrudBibliotheque;

export function UseDeleteBibliotheque(slug: string) {
  const [deleteData] = useDeleteDocumentationMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce fichier ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Fichier supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
