import React from 'react'
import Modal from "react-bootstrap/Modal";
import { SelectInput } from '../../../common/SelectInput';
import { Input } from '../../../common/Input';
import { IModeles } from '../../../../utils/api/modeles/modeles.type';
import UseCrudModele from '../requestForm/UseCrudModele';
import ErrorMessage from '../../../common/ErrorMessage';

function AddModeleModal({
    modalAddModele,
    setModalAddModele,
    modele,
    setModele,
  }: {
    modalAddModele: any;
    setModalAddModele: any;
    modele?: IModeles;
    setModele?: any;
  }) {
    function closeModalAddModele() {
        setModalAddModele(false);
      }
    
      const { register, onSubmit, errors, isLoading, handleReset, handelCertificatChange, previewCertificat, previewLastCertificat } = UseCrudModele(
        setModalAddModele,
        modele,
        setModele
      );
      return (
        <Modal
          show={modalAddModele}
          onHide={() => {
            handleReset();
            closeModalAddModele();
          }}
          aria-labelledby="Show Fidel Affectation Modal"
          animation={false}
          size="lg"
          centered
        >
          <Modal.Header className="modal-header border-0 p-3" closeButton>
            <Modal.Title className="modal-title">Nouveau modele</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <form className="mt-4" 
              onSubmit={onSubmit}
              >
                <div className="row row-add-student my-3">
                  <div className="col-md-12 mb-3">
                    <SelectInput
                      label="Couleur"
                      id="sexe"
                      {...register("couleur")}
                      customClassInput="form-control-modal-custom"
                      error={errors.couleur}
                      options={[
                        { label: "Bleu", value: "bleu" },
                        { label: "Violet", value: "violet" },
                        { label: "Beige", value: "beige" },
                        { label: "Vert", value: "vert" },
                      ]}
                      required
                    />
                  </div>
                  <div className="col-md-12 mb-3">
                    <Input
                      type="file"
                      label="Image"
                      id="image"
                      placeholder="Image"
                      accept="image/*"
                       onChange={(e) => handelCertificatChange(e)}
                        name="image"
                        required
                    />
                    {errors?.image && (
                    <ErrorMessage message={errors?.image?.message} />
                    )}
                    {previewCertificat && (
                    <div className="img-preview-container mb-3">
                        <img src={previewCertificat} className="type-img-preview" />
                    </div>
                    )}
                    {!previewCertificat && previewLastCertificat && (
                    <div className="img-preview-container mb-3">
                        <img src={previewLastCertificat} className="type-img-preview custom" />
                    </div>
                    )}
                  </div>
                  <div className="col-md-6 offset-md-6 auth-submit-btn-container text-end pt-3">
                    <button
                      disabled={isLoading}
                      className="btn btn-theme-admin w-100"
                      type="submit"
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>{modele ? "Modification..." : "Ajout..."}</span>
                        </>
                      ) : modele ? (
                        "Modifier"
                      ) : (
                        "Ajouter"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      );
}

export default AddModeleModal