import React, { useEffect } from "react";
import "./ParoisseAdmin.css";
import { useStepType } from "../../../utils/type";
import { useStep } from "react-hooks-helper";
import Paroisse from "./tabs/Paroisse";
import Fidele from "./tabs/Fidele";
import { useLocationState } from "../../../utils/Utils";
import { useParoisseBySlugQuery } from "../../../utils/api/paroisses/paroisses.api";
import { useAppSelector } from "../../../redux/hook";
import { NavLink, useNavigate } from "react-router-dom";

const steps = [
  { id: "paroisses", Component: Paroisse },
  { id: "fideles", Component: Fidele },
];

const tabs = ["Paroisses", "Fidèles"];

function ParoisseAdmin() {
  const {user} = useAppSelector((s) => s?.user);
  const itemState = useLocationState<string>(undefined);
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  useEffect(() => {
    if (itemState) {
      go(itemState);
    }
  }, [itemState]);

  const { data: paroisse } =
  useParoisseBySlugQuery({
    slug: user?.paroisse_id?.slug,
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (user?.user_type === "responsable_paroissien") {
      const slug = user.paroisse_id?.slug;
      if (slug) {
        navigate(`/admin/paroisses/detail-paroisse/${slug}`);
      }
    }
  }, [user, paroisse]);
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Paroisses</h2>
      </div>

      <div className="content-graph-admin">
        <div className="eglise-dash-tabs mb-0">
          <ul className="tabs-container tabs-container-admin">
            {tabs.map((tab: any, key: any) => (
              <li
                className={`tab-item  ${
                  index === key && "tab-active-item-admin"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>

        <div className="tabs-component-container">
          <Component />
        </div>
      </div>
    </div>
  );
}

export default ParoisseAdmin;
