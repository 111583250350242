import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { IPays, PaysFormData } from "../../../../utils/api/pays/pays.type";
import {
  useAddOrEditPaysMutation,
  useDeletePaysMutation,
} from "../../../../utils/api/pays/pays.api";
import { Data } from "../../../common/GoogleInput";
import { useGetFreeChefsDioceseQuery } from "../../../../utils/api/membre/membre.api";

function UseCrudPays(setModalAddPays: any, pays?: IPays, setPays?: any) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required("Nom est obligatoire").label("Nom"),
    latitude: yup
      .string()
      .required("Latitude est obligatoire")
      .label("Latitude"),
    longitude: yup
      .string()
      .required("Longitude est obligatoire")
      .label("Longitude"),
      responsable: yup.string().required().label("Le responsable").nullable(),
    adjoint: yup.string().label("Adjoint"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<PaysFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [address, setAddress] = useState("");
  const [addOrUpdatePays, { isLoading, isSuccess, error, isError }] =
    useAddOrEditPaysMutation();

    const [options, setOptions] = useState<{ label: string; value: number }[]>(
      []
    );
    const [option, setOption] = useState<
      { label: string; value: number } | undefined
    >();

    const { data: chefs = { results: [] }, isLoading: isLoad } = useGetFreeChefsDioceseQuery({
      limit: 100,
    });

  const handleChangeAddress = (result: Data) => {
    setAddress(result?.rue);
    setValue("nom", result?.rue);
    setValue("latitude", result?.latitude);
    setValue("longitude", result?.longitude);
  };

  useEffect(() => {
    if (chefs?.results?.length) {
      setOptions(
        chefs?.results?.map((el) => {
          return {
            label: el?.prenom + " " + el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [chefs]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: pays ? "Pays modifié avec succès !" : "Pays ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!pays) {
          reset();
        }
        setModalAddPays(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    console.log("data submitted", data);
    addOrUpdatePays({ slug: pays?.slug, data });
  };

  const handleSelectResponsable = (val) => {
    setOption(val);
    setValue("responsable", val?.value);
  };

  const handleReset = () => {
    reset();
    setPays();
    setOption(undefined);
    setAddress("");
  };

  useEffect(() => {
    if (pays?.id) {
      setAddress(pays?.nom)
      const fields: (keyof PaysFormData)[] = [
        "nom",
        "latitude",
        "longitude",
        "slug",
        "responsable",
        "adjoint",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, pays[field]);
      }
      if (pays?.responsable) {
        setOption({
          label: pays?.responsable?.prenom + " " + pays?.responsable?.nom,
          value: pays?.responsable?.id,
        });
        setValue("responsable", pays?.responsable?.id);
      }
    } else {
      setOption(undefined);
      setValue("responsable", undefined);
    }
  }, [pays]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    handleChangeAddress,
    address,
    handleSelectResponsable,
    Controller,
    control,
    option,
    options,
  };
}

export default UseCrudPays;

export function UseDeletePays(item: IPays) {
  const [deleteData] = useDeletePaysMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce pays ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Pays supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
