import React, { useState } from "react";
import ShowNotificationModal from "../modal/ShowNotificationModal";
import { useGetNotificationQuery } from "../../../../utils/api/notification/notification.api";
import { formatDateHour } from "../../../../utils/Utils";
import { INotification } from "../../../../utils/api/notification/notification.type";
import { CustomPagination } from "../../../common/CustomPagination";

function Notification() {
  const [modalNotification, setModalNotification] = useState(false);
  const [notification, setNotification] = useState<INotification>();

  function openModalNotification(item: INotification) {
    setNotification(item);
    setModalNotification(true);
  }

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(12);

  const { data = { results: [], count: 0 } } = useGetNotificationQuery({
    limit: perPage,
    page,
  });
  return (
    <div className="medsain-profil-tab-item">
      <div className="medsain-profil-tab-title">Notifications</div>
      <div className="list-group mt-4">
        {data?.results?.map((data) => (
          <button
            type="button"
            onClick={() => openModalNotification(data)}
            className={`list-group-item list-group-item-action mb-2 ${
              data?.read === false ? "non-lue" : ""
            }`}
            key={data?.slug}
          >
            <div className="row">
              <div className="col-md-7">
                <div className="custom-list-group-item">{data?.content}</div>
              </div>
              <div className="col-md-3">
                <div className="list-group-item-heure pt-1">
                  {formatDateHour(data?.data?.created_at)}
                </div>
              </div>
              <div className="col-md-2">
                {data?.read === false && (
                  <span className="list-group-item-badge">Non lue</span>
                )}
              </div>
            </div>
          </button>
        ))}
      </div>
      {data?.results && (
        <div className="custom-pagination-container">
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setPerPage(perPage);
              setPage(page);
            }}
            perPage={perPage}
          />
        </div>
      )}

      <ShowNotificationModal
        modalNotification={modalNotification}
        setModalNotification={setModalNotification}
        notification={notification}
      />
    </div>
  );
}

export default Notification;
