import React, { useState } from "react";
import { AiTwotoneFileExclamation } from "react-icons/ai";
import { BsEmojiWink } from "react-icons/bs";
import { FiPaperclip } from "react-icons/fi";
import { HiOutlineCamera } from "react-icons/hi2";
import { IoMdAdd } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import { RiInboxFill, RiSendPlaneFill } from "react-icons/ri";
import { NavLink ,Link } from "react-router-dom";
import Profil from "../../../assets/appImages/patient.png";
import NewChatModal from "./modal/NewChatModal";
import BreadCrumb from "../../shared/BreadCrumb";


const DetailMessage = () => {
  const [modalNewChat, setModalNewChat] = useState(false);

  function openModalNewChat(e: any) {
    e.preventDefault();
    setModalNewChat(true);
  }


  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
      <div className="row mb-4">
          <BreadCrumb pageName="Messages" />
        </div>
        <div className="row">
          <div className="col-3 col-left-messagerie d-flex mb-3">
            <div className="content-graph-admin w-100">
              <div className="">
                <button
                  className="btn btn-theme-body-page w-100"
                  onClick={openModalNewChat}
                >
                  <span>
                    <IoMdAdd />
                  </span>
                  <span className="ps-2">Nouveau</span>
                </button>
                <NewChatModal
                  modalNewChat={modalNewChat}
                  setModalNewChat={setModalNewChat}
                />
                <div className="content-btn-discussion my-3">
                  <Link to={`/admin/messages`} className="btn btn-messagerie active">
                    <span>
                      <span>
                        <RiInboxFill />{" "}
                      </span>
                      <span className="ps-2">Discussion</span>
                    </span>
                    <span className="text-notif-message">4</span>
                  </Link>
                </div>
                <div className="content-btn-discussion mb-3">
                  <button className="btn btn-messagerie">
                    <span>
                      <span>
                        <AiTwotoneFileExclamation />{" "}
                      </span>
                      <span className="ps-2">Brouillon</span>
                    </span>
                    <span className="text-notif-message">1</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-9 col-right-messagerie d-flex mb-3">
            <div className="content-graph-admin w-100">
              <div className="dtails-messages-tabs-component">
                <div className="content-content-detail-message-info p-3">
                  <div className="content-img-pp-chat">
                    <img
                      src={Profil}
                      className="image-profil-entete image_responsive"
                      alt="profil detail message"
                      style={{ width: "60", height: "60" }}
                    />
                  </div>
                  <div className="content-info-user-chat">
                    <div className="msg-user-infos-container">
                      <div className="d-flex align-items-center msg-user-name">
                        <p className="profil-detail-message mb-0">
                          Paul Gomis
                        </p>
                      </div>
                      <div className="bloc-user-disconnect-time msg-user-lastonline">
                        <p className="text-disconnect-time mb-0">En line</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bloc-details pb-5">
                      <div className="bloc-reponse">
                        <div className="position-relative sending-msg-item">
                          <div className="bloc-message-envoyer">
                            <div className="text-message-envoyer">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nibh mauris cursus mattis molestie. Ligula ullamcorper malesuada proin libero nunc consequat interdum. A lacus vestibulum sed arcu non odio euismod lacinia. Aliquet eget sit amet tellus cras adipiscing enim.</div>
                          </div>
                          <p className="time-detail-message mt-3 text-right">
                            11:22
                          </p>
                        </div>
                      </div>
                      <div className="position-relative received-msg-item">
                        <div className="bloc-message-recu p-3">
                          <div className="content-img-pp-message-recieve">
                            <img
                              src={Profil}
                              alt="Profil"
                              className="img-pp-message-recieve"
                            />
                          </div>
                          <div className="info-text-message-recu">
                            <div className="text-message-recu">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
 incididunt ut labore et dolore magna aliqua. Id aliquet lectus proin nibh.</div>
                            <p className="time-detail-message mt-3">
                            12:33
                            </p>
                          </div>
                        </div>
                      </div>
                </div>
                <div className="p-3 border-top">
                  <form>
                    <div className="left-footer">
                      <div className="left-footer-container">
                        <div className="input-group">
                          <div className="input-container">
                            <div className="container-display-input-message">
                              <div className="share">
                                <FiPaperclip className="img-icon-chat" />
                              </div>
                              <div className="inp w-100">
                                <textarea
                                  className="textarrea-message-custom form-control"
                                  rows={1}
                                >
                                  Type your message here...
                                </textarea>
                              </div>
                            </div>
                            <div className="d-flex gap-2">
                              <div className="emoji">
                                <BsEmojiWink className="img-icon-chat" />
                              </div>
                              <div className="camera">
                                <HiOutlineCamera className="img-icon-chat" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btn-container">
                          <button type="submit">
                            <RiSendPlaneFill className="img-icon-chat" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailMessage;
