/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { MdEdit } from "react-icons/md";
import { FaTrashAlt } from "react-icons/fa";
import { useCollecteFromLocation } from "../../../utils/api/collecte/collecte.api";
import { createMarkup, formatCurrency, formatDate } from "../../../utils/Utils";
import { NavLink } from "react-router-dom";
import { UseDeleteCollecte } from "./form/UseCrudCollecteForm";

const BtnDelete = ({ slug }: { slug: string }) => {
  const onDelete = UseDeleteCollecte(slug, "/admin/collectes");

  return (
    <button onClick={() => onDelete()} className="no-link bg-transparent">
      <FaTrashAlt color="#9203D7" size={20}  />
    </button>
  );
};
function CollecteDetails() {
  const [item] = useCollecteFromLocation();
  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          <BreadCrumb pageName="Collecte" secondPage="Détails" />
        </div>
        <div className="btn-action-container">
          <span className="egs__text">Détails d'une collecte</span>
        </div>
        <div className="custom-content mt-2">
          <div className="bg_colgate collecte_detail__wrapper">
            <div className="collecte-header">
              <p className="m-0 title">{item?.titre}</p>
              <p className="m-0 ">
                Date de création &nbsp;
                <span className="date">Le {formatDate(item?.created_at)}</span>
              </p>
              <p className="m-0 d-flex align-items-center gap-3">
                <NavLink
                  to={`/admin/collectes/modifier/${item?.slug}`}
                  state={item}
                  className={"no-link"}
                >
                  <MdEdit color="#9203D7" size={20} />
                </NavLink>
                <BtnDelete slug={item?.slug} />
              </p>
            </div>
            <div className="big_content pt-4">
              <p className="m-0 resume-campain">Résumé de la campagne</p>
              <div className="campain">
                <div className="py-2 collecte-status-content w-100">
                  <div className="percentage-and-goal d-flex align-items-center justify-content-between custom_ceg_text">
                    <span className="pb-1 percentage">
                      ({item?.progression}&nbsp;%)
                    </span>
                    <span className="goal">
                      {formatCurrency(item?.montant)}(collectés)
                    </span>
                  </div>
                  <div className="progress collecte__progress">
                    <div
                      className="progress-bar collecte__progress_bar"
                      role="progressbar"
                      aria-label="Collecte status"
                      style={{ width: `${item?.progression as any}%` }}
                      aria-valuenow={item?.progression as any}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    ></div>
                  </div>
                  <div className="text-end pt-1 participants">
                    {item?.nb_participants}&nbsp;participants
                  </div>
                </div>
              </div>
              <p className="m-0 goal-wrapper d-flex gap-2">
                <span className="m-0 resume-campain">Objectif:</span>
                <span className="goal">
                  100% ({formatCurrency(item?.montant)})
                </span>
              </p>
              <div className="description pt-4">
                <p className="mb-2 description-label">Description</p>
                <div
                  className="content"
                  dangerouslySetInnerHTML={createMarkup(item?.description)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollecteDetails;
