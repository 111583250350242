import React from "react";
import { Input } from "../../../common/Input";
import UseCrudTemoignage from "../request/UseCrudTemoignage";
import Modal from "react-bootstrap/Modal";
import ReactQuill from "react-quill";

const AddTemoignageModal = (
  { 
    modalAddTemoignage, 
    setModalAddTemoignage, 
    temoignage,
  }: {
    modalAddTemoignage: any;
    setModalAddTemoignage: any;
    temoignage?: any;
  }
) => {

  function closeModalAddTemoignage() {
    setModalAddTemoignage(false);
  }
  const {
    register,
    onSubmit,
    errors,
    content,
    isLoading,
    handleChangeContent,
    handleReset,
    handelImageChange,
    previewImage
  } = UseCrudTemoignage(temoignage, setModalAddTemoignage)

  return (
    <Modal
    show={modalAddTemoignage}
    onHide={
    () => {
           handleReset();
           closeModalAddTemoignage();
         }
    }
    aria-labelledby="Show Fidel Affectation Modal"
    animation={false}
    // size="lg"
    centered
  >
    <Modal.Header className="modal-header border-0 p-3" closeButton>
      <Modal.Title className="modal-title">
        {temoignage ? "Modifier le témoignage" : "Nouveau témoignage"}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
      <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-12 pb-3">
                  <label 
                    htmlFor="text"
                    className="form-label form-label-modal-custom"
                  >
                    Ajouter une photo
                  </label>
                  {handelImageChange && (
                    <input 
                        type="file" 
                        id="temoignage_author_image"
                        onChange={(e) => handelImageChange(e)}
                        className="form-control form-control-modal-custom"
                        accept="image/*"
                    />
                    )}
                    {previewImage && (
                        <div className="preview-image pt-2">
                          <img src={previewImage} alt="image" />
                        </div>
                    )}
                </div>
                <div className="col-md-12 mb-3">
                  <Input 
                    type="text" 
                    label="Titre" 
                    id="titre" 
                    placeholder="Titre" 
                    {...register("titre")}
                    error={errors?.titre}
                    required
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label 
                    htmlFor="text"
                    className="form-label form-label-modal-custom"
                  >
                    Texte
                  </label>
                  <ReactQuill
                    className="editor-cgu"
                    theme="snow"
                    value={content}
                    onChange={handleChangeContent}
                  />
                </div>
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                      type="submit"
                      className="btn btn-theme-admin"
                      disabled={isLoading}
                  >
                    {isLoading  ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>
                          {temoignage ? "Modification..." : "Ajout..."}
                        </span>
                      </>
                      ) : temoignage ? (
                        "Modifier"
                      ) : (
                        "Ajouter"
                      )}
                  </button>
                </div>
              </div>
            </form>
      </div>
    </Modal.Body>
  </Modal>
  );
};

export default AddTemoignageModal;
