import React, { useState } from "react";
import "./ActualiteAdmin.css";
import ActualiteStep from "./Steps/ActualiteStep";
import ThemesStep from "./Steps/ThemesStep";

function ActualiteAdmin() {
  const [index, setIndex] = useState(0);
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <h2 className="chart-item-admin-title mb-4">Actualités</h2>
      </div>
      <div className="formation-page-content">
        <div className="eglise-dash-tabs mb-0 bg-white pt-3 px-4">
          <ul className="tabs-container tabs-container-admin mb-0">
            <li
              className={`tab-item  ${index === 0 && "tab-active-item-admin"}`}
              onClick={() => setIndex(0)}
              role="button"
            >
              Actualités
            </li>
            <li
              className={`tab-item  ${index === 1 && "tab-active-item-admin"}`}
              onClick={() => setIndex(1)}
              role="button"
            >
              Thèmes
            </li>
          </ul>
        </div>
      </div>
      <div className="formation-tabs-component pt-4">
        {index === 0 && <ActualiteStep />}
        {index === 1 && <ThemesStep />}
      </div>
    </div>
  );
}

export default ActualiteAdmin;
