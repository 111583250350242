import React from "react";
import FidelesAdminTable from "../Tables/FidelesAdminTable/FidelesAdminTable";
import TabBanner from "./TabBanner";
import FinancesAdminTable from "../Tables/FinancesAdminTable/FinancesAdminTable";

function FidelesTab(props) {
  return (
    <div className="content-finance-tab">
      <TabBanner {...props} />
      <div className="content-graph-admin mb-2">
        <div className="admin-table">
          <FinancesAdminTable {...props} />
        </div>
      </div>
    </div>
  );
}

export default FidelesTab;
