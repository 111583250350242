import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { Ceremonies, CeremoniesFormData } from "./ceremonies.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const CeremoniesApi = createApi({
  reducerPath: "CeremoniesApi",
  tagTypes: ["ceremonies", "ceremoniesList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditCeremonies: build.mutation<
      Ceremonies,
      {
        slug?: string;
        data: CeremoniesFormData | FormData;
      }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `ceremonies/${slug}/` : `ceremonies/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["ceremonies", "ceremoniesList"],
    }),

    getListCeremonies: build.query<PaginationResults<Ceremonies>, TypeQuery>({
      query: (query) => QueryUrl("ceremonies/", query),
      providesTags: ["ceremoniesList"],
    }),
    getCeremonies: build.query<Ceremonies, string>({
      query: (slug) => `ceremonies/${slug}/`,
      providesTags: ["ceremonies"],
    }),

    deleteCeremonies: build.mutation<CeremoniesFormData, string>({
      query: (slug) => ({
        url: `ceremonies/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["ceremonies", "ceremoniesList"],
    }),
  }),
});

export const {
  useAddOrEditCeremoniesMutation,
  useDeleteCeremoniesMutation,
  useGetListCeremoniesQuery,
  useLazyGetCeremoniesQuery,
} = CeremoniesApi;

export function useCeremonieFromLocation(): [
  Ceremonies,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Ceremonies | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetCeremoniesQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Ceremonies, isLoading, slug as string, findById];
}