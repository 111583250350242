import React from "react";
import ReactQuill from "react-quill";
import UseCrudParoisses from "./requestForm/UseCrudParoisses";
import Select from "react-select";
import { FormError, Input, animatedComponents } from "../../common/Input";
import MyDropzone from "../../common/MyDropzone";
import BreadCrumb from "../../shared/BreadCrumb";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../common/GoogleInput";
import { useParoisseFromLocation } from "../../../utils/api/paroisses/paroisses.api";

const AddParoisseForm = () => {
  // const paroisse = useLocationState<IParoisse>(undefined);
  const [paroisse] = useParoisseFromLocation()
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    description,
    handleChangeDescription,
    pays,
    onSelectPays,
    selected,
    control,
    Controller,
    address,
    handleChangePhone,
    phone,
    onChangeAddress,
  } = UseCrudParoisses(paroisse);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Paroisses" secondPage="Ajouter" />
        </div>
      </div>
      <div className="content-graph-admin">
        <div className="form-content">
          <form action="" onSubmit={onSubmit}>
            <div className="row row-add-paroisse-form">
              <div className="col-md-6">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  id="nom"
                  {...register("nom")}
                  error={errors.nom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Email"
                  type="text"
                  placeholder="Email"
                  id="email"
                  {...register("email")}
                  error={errors.email}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"telephone"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"telephone"}
                  >
                    Téléphone
                  </label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                      paddingTop: "22px",
                      height: "54px",
                      backgroundColor: "#F2F6FA",
                      border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>

                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={onSelectPays}
                          value={selected}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Adresse{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>
                  <GoogleInput
                    className={`form-control form-control-modal-custom`}
                    placeholder="Adresse"
                    value={address}
                    onChange={onChangeAddress}
                  />
                  <FormError error={errors.adresse} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      label="Latitude"
                      type="text"
                      placeholder="Latitude"
                      id="latitude"
                      readOnly
                      {...register("latitude")}
                      error={errors.latitude}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      label="Longitude"
                      type="text"
                      readOnly
                      placeholder="Longitude"
                      id="longitude"
                      {...register("longitude")}
                      error={errors.longitude}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor={""}
                  className={`form-label form-label-modal-custom`}
                  aria-labelledby={""}
                >
                  Horaire des messes en semaine
                </label>
                <div className="custom-container-time">
                  <div className="custom-container-time-input">
                    <span>Matin -</span>
                    <Input
                      type="time"
                      id="horaire_matin"
                      {...register("horaire_matin")}
                      error={errors.horaire_matin}
                    />
                  </div>
                  <div className="custom-container-time-input">
                    <span>Soir -</span>
                    <Input
                      type="time"
                      id="horaire_soir"
                      {...register("horaire_soir")}
                      error={errors.horaire_soir}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <label
                  htmlFor={""}
                  className={`form-label form-label-modal-custom`}
                  aria-labelledby={""}
                >
                  Horaire des messes le dimanche
                </label>
                <div className="custom-container-time">
                  <div className="custom-container-time-input">
                    <span>Matin -</span>
                    <Input
                      type="time"
                      id="horaire_dimanche_matin"
                      {...register("horaire_dimanche_matin")}
                      error={errors.horaire_dimanche_matin}
                    />
                  </div>
                  <div className="custom-container-time-input">
                    <span>Soir -</span>
                    <Input
                      type="time"
                      id="horaire_dimanche_soir"
                      {...register("horaire_dimanche_soir")}
                      error={errors.horaire_dimanche_soir}
                    />
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6"></div>
                          <div className="col-md-6"></div> */}
              <div className="col-md-12 pt-3 pb-5">
                <label
                  htmlFor="about_paroisse"
                  className="form-label form-label-modal-custom"
                >
                  À propos de la paroisse
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={description}
                  onChange={handleChangeDescription}
                />
                <FormError error={errors?.description} />
              </div>
              {/* <OtherActivityForm  />
                          <div className="col-md-12">
                              <button className="other-activity-big-btn">
                                  <MdAdd style={{ fontSize: 20 }} />
                                  <span>Ajouter une autre activité</span>
                              </button>
                          </div> */}
            </div>
            <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{paroisse ? "Modification..." : "Ajout..."}</span>
                  </>
                ) : paroisse ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddParoisseForm;

const OtherActivityForm = () => {
  const [image, setImages] = React.useState<File | []>();
  return (
    <>
      <div className="col-md-6">
        <Input label="Activité" type="text" placeholder="|" />
      </div>
      <div className="col-md-6">
        <Input label="Date de l’activité" type="text" placeholder="|" />
      </div>
      <div className="col-md-12 pt-3 pb-4">
        <label
          htmlFor="description"
          className="form-label form-label-modal-custom"
        >
          Description de l’activité
        </label>
        <ReactQuill
          value="Lorem ipsum dolor sit amet consectetur. Et tristique vel id augue pulvinar dictum netus. Nibh faucibus ipsum mauris convallis urna sem ut. Volutpat blandit sit vel vel in. Enim pretium id lobortis vehicula a gravida. Quis vel nisi duis tortor odio velit sociis neque consectetur. Turpis duis risus arcu mauris. Morbi cursus eget proin mattis odio sagittis morbi vitae id. Lorem mattis nullam tristique sit donec in platea aliquet. Nisi tellus mattis nec eu sit sit congue egestas. Faucibus etiam tellus facilisi faucibus aliquam erat vulputate. Tortor quis ultrices semper sit tellus at viverra. Faucibus suscipit odio cursus ultrices in. Egestas tristique lorem leo sed duis fermentum in. Ornare diam volutpat risus viverra mauris.
                      Tortor pellentesque molestie nulla fermentum. At venenatis mattis mattis arcu egestas non. Mi diam elit pulvinar est morbi condimentum quam lacus. A dolor urna eget porta nullam. Aliquet nunc ultrices proin facilisis cras diam risus dignissim. "
        />
      </div>
      <div className="col-md-12 pb-4">
        <MyDropzone
          label="Image illustrant l’activité"
          setImages={setImages}
          maxFile={4}
        />
      </div>
    </>
  );
};
