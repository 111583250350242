import React, { useState } from "react";
import { useGetEventToComeOfParoisseQuery } from "../../../../../utils/api/activites/activites.api";
import { Wrapper } from "../../DetailChef/Shared";
import { IActivite } from "../../../../../utils/api/activites/activites.type";
import { formatDate, getImage } from "../../../../../utils/Utils";
import { BiCalendar } from "react-icons/bi";
import { GiAlarmClock } from "react-icons/gi";
import { MdLocationPin, MdOutlineModeEdit } from "react-icons/md";
import moment from "moment";
import { AlertInfo } from "../../../../common/Alert";
import { CustomPagination } from "../../../../common/CustomPagination";
import { NavLink } from "react-router-dom";
import { IParoisse } from "../../../../../utils/api/paroisses/paroisses.type";
import { useAppSelector } from "../../../../../redux/hook";
import {
  isResponsableParoissien,
  isSuperAdmin,
} from "../../../../../routes/routerUtils";
import { BsTrash3 } from "react-icons/bs";
import { UseDeleteActivite } from "../../requestForm/UseCrudActiviteForm";

export function DeleteActivite({ item }: { item: IActivite }) {
  const deleteMembre = UseDeleteActivite(item);

  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={deleteMembre}
      >
        <BsTrash3 />
      </button>
    </div>
  );
}

const IncomingEventCard = ({
  item,
  paroisse,
}: {
  item: IActivite;
  paroisse?: IParoisse;
}) => {
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  return (
    <div className="incomming-event-card position-relative">
      <div className="row">
        <span className="col-md-4 event-cover-container">
          <img src={getImage(item?.image)} alt="cover" className="cover" />
        </span>
        <div className="col-md-8 infos-content">
          <span className="name pb-3">{item?.titre}</span>
          <span className="date d-flex align-items-center gap-2 pb-3">
            <BiCalendar className="icon" />
            {formatDate(item?.date)}
          </span>
          <div className="hour-and-location d-flex align-items-center gap-4">
            <span className="hour d-flex align-items-center gap-2">
              <GiAlarmClock className="icon" />
              {moment(item?.heure, "HH:mm:ss").format("HH[h]mm")}
            </span>
            <span className="location d-flex align-items-center gap-2">
              <MdLocationPin className="icon" />
              {item?.lieu}
            </span>
          </div>
        </div>
      </div>
      {!!is_SuperAdmin || !!is_Responsable ? (
        <div className="custom-pos-btn-actions">
          <NavLink
            to={`/admin/paroisses/ajouter-une-activite`}
            className="btn btn-action-modal-icon with-tooltip"
            data-tooltip-content="Modifier"
            state={{ paroisse, item }}
          >
            <MdOutlineModeEdit />
          </NavLink>
          <DeleteActivite item={item} />
        </div>
      ) : null}
    </div>
  );
};

function EventToCome({ paroisse }) {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetEventToComeOfParoisseQuery({
      page,
      limit,
      slug: paroisse?.slug,
    });
  return (
    <div className="incomming-events">
      <h3 className="title">Événements à venir</h3>
      <Wrapper customClass="mt-2">
        <div className="row">
          {!!isLoading && <p>Chargement...</p>}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results.map((evt, key) => {
                return (
                  <div className="col-md-12 mb-3" key={evt?.id}>
                    <IncomingEventCard item={evt} paroisse={paroisse} />
                  </div>
                );
              })
            ) : (
              <AlertInfo message="Aucune donnée trouvée" />
            ))}
        </div>
        <div className="custom-pagination-container">
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
            perPage={limit}
          />
        </div>
      </Wrapper>
    </div>
  );
}

export default EventToCome;
