import React from "react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

function Calendar({ events }: { events: any }) {
	return (
		<div className="test-calendar">
			<FullCalendar
				locale={"fr"}
				timeZone="local"
				plugins={[dayGridPlugin, interactionPlugin]}
				initialView="dayGridMonth"
				editable={true}
				selectable={true}
				selectMirror={true}
				dayMaxEvents={true}
				// headerToolbar={{
				// 	left: "prev",
				// 	center: "title",
				// 	right: "next",
				// }}
				// themeSystem="boostrap"
				forceEventDuration={true}
				allDayClassNames="tester"
				eventTimeFormat={{
					hour: "numeric",
					minute: "numeric",
				}}
				titleFormat={{
					day: "2-digit",
					month: "long",
					year: "numeric",
				}}
				slotLabelFormat={{
					hour: "2-digit",
					minute: "2-digit",
				}}
				events={events || []}
				displayEventTime={false}
				moreLinkContent={function (args) {
					return "Voir";
				}}
				height={`auto`}
			/>
		</div>
	);
}

export default Calendar;