import React from "react";
import FormContent from "../FormContent";
import { useParoisseFromLocation } from "../../../../../utils/api/paroisses/paroisses.api";



function ChefDeDistrict(){
    const [item] = useParoisseFromLocation();
    return <FormContent type="chef_district" item={item?.chef_disrict} />;
}

export default ChefDeDistrict