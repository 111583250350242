import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError, closeModal } from "../../../../utils/Utils";
import { Theme, ThemeFormData } from "../../../../utils/api/theme/theme.type";
import {
  useAddOrEditThemeMutation,
  useDeleteThemeMutation,
} from "../../../../utils/api/theme/theme.api";

function UseCrudThemeForm(modalId: string, item?: Theme) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<ThemeFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditThemeMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Le thème a été ${item ? "modifié" : "ajouté"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        handleReset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: ThemeFormData) => {
    await sendData(data);
  };

  const handleReset = () => {
    if (!item) {
      reset();
    }
    closeModal(modalId);
  };

  useEffect(() => {
    if (item?.id) {
      setValue("slug", item?.slug);
      setValue("titre", item?.titre);
    }
  }, [item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudThemeForm;

export function UseDeleteTheme(slug: string) {
  const [deleteData] = useDeleteThemeMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce thème ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le thème a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
