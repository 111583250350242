import React from "react";
import { Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import UseCrudCarnetRose from "../request/UseCrudCarnetRose";
import { CarnetRose } from "../../../../utils/api/carnetRoses/carnetRoses.type";
import Modal from "react-bootstrap/Modal";

const AddCarnetRoseModal = ({
  modalAddCarnetRose,
  setModalAddCarnetRose,
  carnetRose,
}: {
  modalAddCarnetRose: any;
  setModalAddCarnetRose: any;
  carnetRose?: CarnetRose;
}) => {
  function closeModalAddCarnetRose() {
    setModalAddCarnetRose(false);
  }

  const {
    register,
    onSubmit,
    errors,
    content,
    isLoading,
    handleChangeContent,
    handleReset,
  } = UseCrudCarnetRose(carnetRose, setModalAddCarnetRose);

  return (
    <Modal
      show={modalAddCarnetRose}
      onHide={() => {
        handleReset();
        closeModalAddCarnetRose();
      }}
      animation={false}
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">Nouveau contenu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Titre"
                  id="titre"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors?.titre}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={content}
                  onChange={handleChangeContent}
                />
              </div>
              <div className="col-md-5 offset-md-7 auth-submit-btn-container d-flex justify-content-end pt-5">
                <button
                  type="submit"
                  className="btn btn-theme-admin"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>{carnetRose ? "Modification..." : "Ajout..."}</span>
                    </>
                  ) : carnetRose ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddCarnetRoseModal;
