import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../Utils";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { IModeles, ModelesFormData } from "./modeles.type";

export const ModelesApi = createApi({
  reducerPath: "modelesApi",
  tagTypes: ["modeles", "modelesList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditModeles: builder.mutation<
      IModeles,
      { slug?: string; data: ModelesFormData | FormData }
    >({
      invalidatesTags: ["modeles", "modelesList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `modeles/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `modeles/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getModeles: builder.query<PaginationResults<IModeles>, TypeQuery>({
      providesTags: ["modelesList"],
      query: (query) => QueryUrl("modeles/", query),
    }),

    deleteModeles: builder.mutation<ModelesFormData, string>({
      query: (slug) => ({
        url: `modeles/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["modeles", "modelesList"],
    }),
  }),
});

export const {
    useAddOrEditModelesMutation,
    useDeleteModelesMutation,
    useGetModelesQuery
} = ModelesApi;