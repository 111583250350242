import React from "react";
import Skeleton from "react-loading-skeleton";

function PasteurAdminSkeleton() {
  return (
    <>
      {Array(8)
        .fill(8)
        .map((i) => (
          <div
            className="col-lg-3 col-md-6 col-item-item text-center d-flex mb-3"
            key={i}
          >
            <div className="content-col-item-item w-100">
              <div className="content-img-profil-item py-3">
                <Skeleton circle className="pasteur-img-avatar" />
              </div>
              <Skeleton height={15} className="name-item-admin" />

              <div className="container-btn-viez-profil-item pt-3">
                <Skeleton width={110} height={35} />
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default PasteurAdminSkeleton;
