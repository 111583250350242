import React from "react";
import FormContent from "../FormContent";
import { useParoisseFromLocation } from "../../../../../utils/api/paroisses/paroisses.api";

function ChefDeZone() {
  const [item] = useParoisseFromLocation();
  return (
    <>
      <FormContent type="chef_zone" item={item?.chef_zone} />
    </>
  );
}

export default ChefDeZone;
