import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"
import * as yup from "yup"
import { Color } from "../../../../utils/theme"
import { cleannerError } from "../../../../utils/Utils"
import { 
    useAddOrEditCarnetRoseMutation,
    useDeleteCarnetRoseMutation,
} from "../../../../utils/api/carnetRoses/carnetRoses.api";
import { 
    CarnetRoseFormData 
} from "../../../../utils/api/carnetRoses/carnetRoses.type"
import { useAppSelector } from "../../../../redux/hook"


function UseCrudCarnetRose(
  carnetRose: any,
  setModalAddCarnetRose: any,
) {

  const validationSchema = yup.object().shape({
    titre: yup.string().required("Le titre est requis").label("Titre"),
    content: yup.string().label("content"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset
  } = useForm<CarnetRoseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [content, setContent] = useState<any>("");

  const [addOrUpdateCarnetRose, { isLoading, isSuccess, error, isError }] =
    useAddOrEditCarnetRoseMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Carnet rose ${carnetRose ? "modifiée" : "ajoutée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        if(!carnetRose) {
          reset();
          setContent("");
        }
        setModalAddCarnetRose();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err",errors)
    cleannerError(errors, clearErrors);
  }, [errors]);

  const {user} = useAppSelector((s) => s?.user);
  const userId = user?.id;

  const onSubmit = async(data: any) => {
    data["user"] = userId;
    await addOrUpdateCarnetRose({ slug: carnetRose?.slug, data });
  };

  const handleReset = () => {
    reset();
    setContent("")
  }

  useEffect(() => {
    if (carnetRose?.id) {
      let fields: (keyof CarnetRoseFormData)[] = ["titre", "content"];
      for (let field of fields) {
        register(field);
        setValue(field, carnetRose[field]);
        if (field === "content") {
          setContent(carnetRose?.content || "");
          setValue("content", carnetRose?.content)
        }
      }
    }
  }, [carnetRose]);

  const handleChangeContent = React.useCallback((text: string) => {
    setContent(text);
    setValue("content", text);
  }, [])

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    content,
    setContent,
    handleChangeContent,
  };
}

export function UseDeleteCarnetRose(slug: string) {
  const [deleteData] = useDeleteCarnetRoseMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce carnet rose ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Carnet rose supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}


export default UseCrudCarnetRose;
