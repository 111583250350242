import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { IParoisse } from "../../../../../utils/api/paroisses/paroisses.type";
import { useGetActivitesOfParoisseQuery } from "../../../../../utils/api/activites/activites.api";
import Calendar from "../../Calendar";
import { RencontreData } from "./TabsData";
import { AlertInfo } from "../../../../common/Alert";
import { formatDate, getImage } from "../../../../../utils/Utils";
import { BiCalendar } from "react-icons/bi";
import { MdAddCircleOutline, MdLocationPin, MdOutlineModeEdit } from "react-icons/md";
import { GiAlarmClock } from "react-icons/gi";
import moment from "moment";
import { CustomPagination } from "../../../../common/CustomPagination";
import { DeleteActivite } from "./EventToCome";
import { useAppSelector } from "../../../../../redux/hook";
import {
  isResponsableParoissien,
  isSuperAdmin,
} from "../../../../../routes/routerUtils";

function Activite({ paroisse }: { paroisse: IParoisse }) {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetActivitesOfParoisseQuery({
      slug: paroisse?.slug,
      limit,
      page,
    });
  const [events, setEvents] = React.useState<any>([]);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );

  useEffect(() => {
    if (data?.results?.length) {
      setEvents(
        data?.results?.map((item) => {
          return {
            date: item?.date,
            title: item?.titre,
          };
        })
      );
    }
  }, [data]);

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <NavLink
          to={"/admin/paroisses/ajouter-une-activite"}
          className="btn custom-btn-add-activity"
          state={{
            paroisse,
          }}
        >
          <MdAddCircleOutline className="add-icon" />
          Ajouter une activité
        </NavLink>
      </div>
      <h4 className="cs-title pb-3">Catéchisme</h4>
      <div className="text-content">
        Après le baptême, le catéchisme est une étape importante pour le jeune
        enfant. Cette étape est aussi un engagement des parents. Il est possible
        à un enfant non baptisé d'assister au catéchisme avec ses camarades de
        classe.
        <br /> <br />
        Chaque paroisse propose un éveil à la foi pour les enfants de 4 à 7 ans,
        en général au rythme d'une rencontre par mois. Les parents sont associés
        à ces rencontres. Ci dessous le calendrier des rencontres:
      </div>

      <div className="pt-4 pb-2 calendar_wrapper mb-4">
        <Calendar events={events} />
      </div>
      <div className="rencontres py-3">
        <h4 className="cs-title mb-3">Rencontres</h4>
        <div className="all-meeting-grid">
          {!!isLoading && <p>chargement...</p>}
          {!isLoading &&
            (data?.results?.length ? (
              data?.results?.map((item) => (
                <div className="p-0" key={item?.id}>
                  <div className="meet-cover-wrapper">
                    <img
                      src={getImage(item?.image)}
                      alt={item?.titre}
                      className="cover"
                    />
                    {/* <div className="title-activite">{item?.titre}</div> */}
                    <div className="infos-content title-activite">
                      <span className="name pb-3 fw-500">{item?.titre}</span>
                      <span className="date d-flex align-items-center gap-2 pb-3">
                        <BiCalendar className="icon" />
                        {formatDate(item?.date)}
                      </span>
                      <div className="hour-and-location ">
                        <span className="hour d-flex align-items-center gap-2 mb-2">
                          <GiAlarmClock className="icon" />
                          {moment(item?.heure, "HH:mm:ss").format("HH[h]mm")}
                        </span>
                        <span className="location gap-2">
                          <MdLocationPin className="icon pt-1 h-100" />
                          {item?.lieu}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="p-1 bg-white position-relative">
                    {!!is_SuperAdmin || !!is_Responsable ? (
                      <div className="custom-pos-btn-actions">
                        <NavLink
                          to={`/admin/paroisses/ajouter-une-activite`}
                          className="btn btn-action-modal-icon with-tooltip"
                          data-tooltip-content="Modifier"
                          state={{ paroisse, item }}
                        >
                          <MdOutlineModeEdit />
                        </NavLink>
                        <DeleteActivite item={item} />
                      </div>
                    ) : null}
                  </div>
                </div>
              ))
            ) : (
              <AlertInfo message="Aucune donnée disponible pour le moment" />
            ))}
        </div>
        <div className="custom-pagination-container">
          <CustomPagination
            nbPages={data?.count}
            page={page}
            onChange={(page, perPage) => {
              setLimit(perPage);
              setPage(page);
            }}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
}

export default Activite;

const Agenda = () => {};
