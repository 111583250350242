import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  Contact,
  ContactFormData,
  Response,
  ResponseFormData,
} from "./contact.type";

export const ContactApi = createApi({
  reducerPath: "contactApi",
  tagTypes: ["contacts", "response", "contact"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getContactMessages: builder.query<PaginationResults<Contact>, TypeQuery>({
      query: (query) => QueryUrl("contacts/", query),
      providesTags: ["contacts"],
    }),
    updateContact: builder.mutation<Contact, ContactFormData>({
      invalidatesTags: ["contacts"],
      query: ({ slug, ...data }) => {
        return {
          url: `contacts/${slug}/`,
          method: "PUT",
          body: data,
        };
      },
    }),
    getContact: builder.query<PaginationResults<Contact>, TypeQuery>({
      query: (query) => QueryUrl("contacts/", query),
      providesTags: ["contact"],
    }),
    deleteContact: builder.mutation<Contact, string>({
      query: (slug) => ({
        url: `contacts/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["contacts"],
    }),
    responseContact: builder.mutation<Response, ResponseFormData>({
      invalidatesTags: ["contacts"],
      query: (data) => {
        return {
          url: `contact/responses/`,
          method: "POST",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useDeleteContactMutation,
  useGetContactMessagesQuery,
  useResponseContactMutation,
  useUpdateContactMutation
} = ContactApi;
