/* eslint-disable @typescript-eslint/no-explicit-any */
import { useUpdateReadNotificationMutation } from "../../../../utils/api/notification/notification.api";
import { INotification } from "../../../../utils/api/notification/notification.type";

function UseAddOrUpdateNotification() {
  const [updateNotificationRead] = useUpdateReadNotificationMutation();
  const handleChangeStatutNotif = (data: INotification) => {
    updateNotificationRead({
      slug: data?.slug,
      read: true,
    });
  };

  return {
    handleChangeStatutNotif,
  };
}

export default UseAddOrUpdateNotification;
