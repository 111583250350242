import React, { ChangeEvent, useCallback, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import Swal from "sweetalert2"
import * as yup from "yup"
import { Color } from "../../../../utils/theme"
import { cleannerError, getImage, onHide } from "../../../../utils/Utils"
import { useDelete } from "../../../../utils/helpers";
import { FaTrash } from "react-icons/fa";
import { 
    useAddOrEditTemoignageMutation,
    useDeleteTemoignageMutation,
} from "../../../../utils/api/temoignage/temoignage.api";
import { 
    Temoignage, 
    TemoignageFormData 
} from "../../../../utils/api/temoignage/temoignage.type"
import { useAppSelector } from "../../../../redux/hook"


function UseCrudTemoignage(
  temoignage: any,
  setModalAddTemoignage: any,
) {

  const validationSchema = yup.object().shape({
    titre: yup.string().required("Le titre est requis").label("Titre"),
    content: yup.string().label("content"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError
  } = useForm<TemoignageFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [content, setContent] = useState<any>("");

  const [addOrUpdateTemoignage, { isLoading, isSuccess, error, isError }] =
    useAddOrEditTemoignageMutation();
    
  const [previewImage, setPreviewImage] = useState(
    temoignage?.image ? getImage(temoignage?.image) : null
  );

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Témoignage ${temoignage ? "modifiée" : "ajoutée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
          // reset();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err",errors)
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("image", {
        message: "Format d'image non valide !",
      });
    }
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      register("image");
      setValue("image", file);
    }
  }, []);

  const {user} = useAppSelector((s) => s?.user);
  const userId = user?.id;

  const onSubmit = async(data: any) => {
    data["user"] = userId;
    console.log("DATA SUBMITED", data);
    
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key] && data[key] !== undefined) {
        fd.append(key, data[key]);
      }
    }
    await addOrUpdateTemoignage({ slug: temoignage?.slug, data: fd });
  };

  const handleReset = () => {
    reset();
    setContent("")
  }

  useEffect(() => {
    if (temoignage?.id) {
      let fields: (keyof TemoignageFormData)[] = ["titre", "content"];
      for (let field of fields) {
        register(field);
        setValue(field, temoignage[field]);
        if (field === "content") {
          setContent(temoignage?.content || "");
          setValue("content", temoignage?.content)
        }
      }
    }
  }, [temoignage]);

  const handleChangeContent = React.useCallback((text: string) => {
    setContent(text);
    setValue("content", text);
  }, [])

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
    content,
    setContent,
    handleChangeContent,
    handelImageChange,
    previewImage
  };
}

export function UseDeleteTemoignage(slug: string) {
  const [deleteData] = useDeleteTemoignageMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet témoignage ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Témoignage supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}


export default UseCrudTemoignage;
