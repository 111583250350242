import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import {
  GradesFormData,
  IGrades,
} from "../../../../utils/api/grades/grades.type";
import {
  useAddOrEditGradesMutation,
  useDeleteGradesMutation,
} from "../../../../utils/api/grades/grades.api";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";

function UseCrudGrade(setModalAddGrade: any, grade?: IGrades, setGrade?: any) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Le titre"),
    sexe: yup.string().required().label("Le genre"),
    type: yup.string().required().label("Le type")
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<GradesFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [addOrUpdateGrade, { isLoading, isSuccess, error, isError }] =
    useAddOrEditGradesMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: grade
          ? "Grade modifié avec succès !"
          : "Grade ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        reset();
        setGrade();
        setModalAddGrade(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    addOrUpdateGrade({ slug: grade?.slug, data });
  };

  const handleReset = () => {
    reset();
    setGrade();
  };

  useEffect(() => {
    if (grade?.id) {
      const fields: (keyof GradesFormData)[] = ["titre", "sexe", "type"];

      for (let field of fields) {
        register(field);
        setValue(field, grade[field]);
      }
    }
  }, [grade]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleReset,
  };
}

export default UseCrudGrade;

export function UseDeleteGrade(slug: string) {
  const [deleteData] = useDeleteGradesMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce grade ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Grade supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
