import React, { useState } from "react";
import "./AffectationAdmin.css";
import BreadCrumb from "../../shared/BreadCrumb";
import AffectationCountryTable from "./tables/AffectationCountryTable";


function AffectationDetail() {
    let [country, setCountry] = useState<string>("Senegal")
    return (
        <>
            <div className="dashbord-admin-component">
                <div className="dash-admin-page-content-container mb-3">
                    <BreadCrumb 
                        pageName="Affectations" 
                        secondPage={`Affectation au ${country}`} 
                    />
                </div>
                <div className="custom-content">
                    <AffectationCountryTable country={country} />
                </div>
            </div>
        </>
    )
}

export default AffectationDetail;