import React, { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import BreadCrumb from "../../shared/BreadCrumb";
import "./FinancesAdmin.css";
import ResponsablesTab from "./FinanceTabs/ResponsablesTab";
import FidelesTab from "./FinanceTabs/FidelesTab";
import BanquesTab from "./FinanceTabs/BanquesTab";
import { useGetCotisationsQuery } from "../../../utils/api/cotisation/cotisation.api";
import Select from "react-select";
import { useGetPaysQuery } from "../../../utils/api/pays/pays.api";

const steps = [
  { id: "responsable_paroissien", Component: ResponsablesTab },
  { id: "fidele", Component: FidelesTab },
  { id: "banques", Component: BanquesTab },
];

const tabs = ["Responsables", "Fidèles", "Banques"];

function FinancesAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );
  const [option, setOption] = useState<{ label: string; value: string } | null>(
    null
  );
  const [inputValue, setInputValue] = useState("");
  const { data: pays = { results: [] } } = useGetPaysQuery({
    q: inputValue,
    limit: 1000,
  });
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState("");
  const [type, setType] = useState("");
  const {
    data = {
      results: [],
      count: 0,
      nb_contributions: 0,
      total_amount: 0,
      remaning_contributions: 0,
    },
    isLoading,
  } = useGetCotisationsQuery({
    page,
    limit,
    q: query,
    user_type: step?.id,
    type,
    pays: option?.value || "",
  });

  useEffect(() => {
    if (pays?.results?.length) {
      setOptions(
        pays?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.slug,
          };
        })
      );
    }
  }, [pays]);

  useEffect(() => {
    setPage(1);
    setLimit(10);
    setQuery("");
    setType("");
    // setOption(null);
  }, [index]);

  const props = {
    data,
    isLoading,
    setPage,
    setLimit,
    setQuery,
    setType,
    page,
    limit,
  };

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Finances" />
        </div>
        <div className="content-graph-admin mb-2">
          <div className="content-select-filter-form pb-3">
            {/* <select className="form-control form-control-select">
              <option value="pays">Sénégal</option>
            </select> */}
            <div className="w-30">
              <Select
                options={options || []}
                onChange={(val: any) => setOption(val)}
                value={option}
                onInputChange={(val) => setInputValue(val)}
                isClearable
                placeholder="Pays"
              />
            </div>
          </div>
          <ul className="tabs-container tabs-container-admin pb-0 mb-0 pt-3">
            {tabs.map((tab: any, key: any) => (
              <li
                className={`tab-item  ${
                  index === key && "tab-active-item-admin"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="tabs-component-container-finance">
              <Component {...props} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancesAdmin;
