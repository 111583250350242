import React, { useEffect } from "react";
import "../ParoisseAdmin.css";
import BreadCrumb from "../../../shared/BreadCrumb";
import { Wrapper } from "../DetailChef/Shared";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import Propos from "./tabs/Propos";
import Membre from "./tabs/Membre";
import Activite from "./tabs/Activite";
import Fidele from "./tabs/Fidele";
import { getImage } from "../../../../utils/Utils";
import { useParoisseFromLocation } from "../../../../utils/api/paroisses/paroisses.api";
import moment from "moment";
import Communaute from "./tabs/Communaute";
import { useSearchParams } from "react-router-dom";
import EventToCome from "./tabs/EventToCome";

const steps = [
  { id: "a-propos", Component: Propos },
  { id: "membres", Component: Membre },
  { id: "activite-communaute", Component: Activite },
  { id: "communaute", Component: Communaute },
  { id: "fideles", Component: Fidele },
];

const tabs = ["À propos", "Membres", "Activités", "Communauté", "Fidèles"];

function DetailParoisse() {
  // const paroisse = useLocationState<IParoisse>(undefined);
  const [paroisse] = useParoisseFromLocation();
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = { paroisse };
  const [params] = useSearchParams();

  useEffect(() => {
    if (params.get("tab")) {
      go(params.get("tab"));
    }
  }, [params])

  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb pageName="Paroisses" secondPage={paroisse?.nom} />
      </div>

      <div className="row row-detail-paroisse">
        <div className="col-md-7 detail-paroisse-wrapper">
          <h3 className="title">{paroisse?.nom}</h3>
          <Wrapper customClass="mt-2 p-0">
            <div className="paroisse-cover-content mb-4">
              <img src={getImage(paroisse?.image)} alt="paroisse" srcSet="" />
            </div>
            <div className="detail-paroisse-tabs-container">
              <div className=" mb-0">
                <ul className="paroisse-tabs">
                  {tabs.map((tab: any, key: any) => (
                    <li
                      className={`tab-item  ${
                        index === key && "tab-active-item"
                      }`}
                      key={key}
                      onClick={() => go(key)}
                      role="button"
                    >
                      {tab}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="paroisse-tabs-container pt-3 px-2">
                <Component {...props} />
              </div>
            </div>
          </Wrapper>
          {/* {index === 2 && (
            <div className="rencontres pt-3">
              <h4 className="cs-title mb-3">Rencontres</h4>
              <div className="all-meeting-grid">
                {!!RencontreData &&
                  RencontreData.map((cover, key) => (
                    <div className="meet-cover-wrapper">
                      <img src={cover.cover} alt="cover" className="cover" />
                    </div>
                  ))}
              </div>
            </div>
          )} */}
        </div>
        {/* left col -- end */}
        {/* right col -- start */}
        <div className="col-md-5 detail-paroisse-wrapper">
          <div className="usefull-infos mb-4">
            <h3 className="title">Infos utiles</h3>
            <Wrapper customClass="mt-2 px-3">
              <div className="row mb-2">
                <div className="col-md-6">
                  <h4 className="custom-sm-title pb-2">Contacts</h4>
                  <div className="custom-pp-info pb-2">
                    <span>Email: </span>
                    <br />
                    <div className="pp-color">{paroisse?.email}</div>
                  </div>
                  <div className="custom-pp-info">
                    <span>Téléphone: </span>
                    <br />
                    <div className="pp-color">{paroisse?.telephone}</div>
                  </div>
                  <div className="custom-pp-info">
                    <span>Adresse: </span>
                    <br />
                    <div className="pp-color">{paroisse?.adresse}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <h4 className="custom-sm-title pb-2">Horaire des messes</h4>
                  <div className="custom-pp-info">
                    <span>
                      <strong>Semaine</strong>
                    </span>{" "}
                    <br />
                    <div className="hour-planning">
                      {moment(paroisse?.horaire_matin, "HH:mm:ss").format(
                        "HH:mm"
                      )}
                      ;{" "}
                      {moment(paroisse?.horaire_soir, "HH:mm:ss").format(
                        "HH:mm"
                      )}
                    </div>
                  </div>
                  <div className="custom-pp-info pt-3">
                    <span>
                      <strong>Dimanche</strong>
                    </span>{" "}
                    <br />
                    <div className="hour-planning">
                      {moment(
                        paroisse?.horaire_dimanche_matin,
                        "HH:mm:ss"
                      ).format("HH:mm")}
                      ;{" "}
                      {moment(
                        paroisse?.horaire_dimanche_soir,
                        "HH:mm:ss"
                      ).format("HH:mm")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <h4 className="custom-sm-title">QR Code</h4>
                <div className="qr-code-container">
                  <img src={getImage(paroisse?.qrcode)} alt="qr" />
                </div>
              </div>
            </Wrapper>
          </div>
          <EventToCome paroisse={paroisse}/>
        </div>
        {/* right col -- start */}
      </div>
    </>
  );
}

export default DetailParoisse;
