import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import "./ProfilAdmin.css";
import Profil from "../../../assets/appImages/profil1.png";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import InfosPersonnelles from "./Tabs/InfosPersonnelles";
import ChangerPassword from "./Tabs/ChangerPassword";
import Notification from "./Tabs/Notification";
import { HiOutlineCamera } from "react-icons/hi2";
import { useAppSelector } from "../../../redux/hook";
import UseCrudProfil from "./requestForm/UseCrudProfil";
import { getAvatar } from "../../../utils/Utils";

const steps = [
  { id: "infos-perso", Component: InfosPersonnelles },
  { id: "password", Component: ChangerPassword },
  { id: "notification", Component: Notification },
];

const tabs = ["Mon profil", "Changer mon mot de passe", "Notifications"];

function ProfilAdmin() {
  const { user } = useAppSelector((state) => state.user);

  const { handleChangeAvatar } = UseCrudProfil();
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const props = {
    user,
  };
  console.log("user", user)
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Mon profil" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">Mon profil</div>
        </div>
        <div className="row">
          <div className="col-md-3 mb-3">
            <div className="medsain-mon-profil-img-container">
              <div className="medsain-mon-profil-img-title mb-3">Photo</div>
              <div className="container-img-profil-user mb-4">
                <div className="d-flex justify-content-center align-items-center mb-4 leader-auto-user-avatar-container">
                  <label htmlFor="input-test" className="input-testt">
                    <img
                      src={getAvatar(user?.avatar)}
                      alt="Profil"
                      className="custom-img-profil-user-admin medsain-mon-profil-img"
                    />
                    <div className="leader-auto-user-avatar-edit-hover-deux-profil">
                      <HiOutlineCamera className="edit-avatar-camera-icon" />
                    </div>
                  </label>
                  <form className="mt-4">
                    <input
                      type="file"
                      className="input-add-avatar"
                      id="input-test"
                      accept="image/*"
                      onChange={handleChangeAvatar}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="medsain-dash-tabs">
              <ul className="tabs-container tabs-container-admin pt-4 pb-2 px-4">
                {tabs.map((tab: any, key: any) => (
                  <li
                    className={`tab-item  ${
                      index === key && "tab-active-item-admin"
                    }`}
                    key={key}
                    onClick={() => go(key)}
                    role="button"
                  >
                    {tab}
                  </li>
                ))}
              </ul>
            </div>

            <div className="hippocrate-dash-admin-page-content-container mb-1">
              <div className="hippocrate-tabs-locataire-container">
                <div className="tabs-component-container">
                  <Component {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfilAdmin;
