import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import { useNavigate } from "react-router-dom";
import { useGetListThemeQuery } from "../../../../utils/api/theme/theme.api";
import { useAppSelector } from "../../../../redux/hook";
import { Collecte } from "../../../../utils/api/cotisation/cotisation.type";
import { CollecteFormData } from "../../../../utils/api/collecte/collecte.type";
import {
  useAddOrEditCollecteMutation,
  useDeleteCollecteMutation,
} from "../../../../utils/api/collecte/collecte.api";

function UseCrudCollecteForm(item?: Collecte) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Titre"),
    description: yup
      .string()
      .required()
      .label("La description")
      .nullable()
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    image: yup.mixed().label("L'image").nullable(),
    montant: yup.string().required().label("L'objectif").nullable(),
    type_collecte: yup
      .string()
      .required()
      .label("Le type de collecte")
      .nullable(),
  });

  const [contenu, setContenu] = useState("");
  const [image, setImage] = useState<File | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    control,
  } = useForm<CollecteFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditCollecteMutation();

  useEffect(() => {
    if (image?.name) {
      setValue("image", image);
    }
  }, [image]);

  const handleChange = (val) => {
    setValue("description", val);
    setContenu(val);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `La collecte a été ${
          item ? "modifiée" : "ajoutée"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        navigate("/admin/collectes");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: CollecteFormData) => {
    if (!item) {
      data["user"] = user?.id;
    }
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key] as any);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    await sendData({ slug: item?.slug, data: fd });
  };

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof CollecteFormData)[] = [
        "titre",
        "description",
        "montant",
        "type_collecte",
      ];

      for (let field of fields) {
        register(field);
        if (field !== "user") {
          setValue(field, item[field]);
        } else {
          setValue(field, item[field]?.id);
        }
      }

      setContenu(item?.description);
    }
  }, [item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChange,
    image,
    setImage,
    contenu,
  };
}

export default UseCrudCollecteForm;

export function UseDeleteCollecte(slug: string, redirectUrl?: string) {
  const [deleteData] = useDeleteCollecteMutation();
  const navigate = useNavigate();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette collecte ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `La collecte a été supprimée avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            if (redirectUrl) {
              navigate(redirectUrl);
            }
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}
