import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {
  Contact,
  ResponseFormData,
} from "../../../../utils/api/contact/contact.type";
import {
  useResponseContactMutation,
  useUpdateContactMutation,
} from "../../../../utils/api/contact/contact.api";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hook";

function UseResponseForm(message: Contact) {
  const [response, setResponse] = useState<any>(null);
  const refInput = useRef<any>();
  const validationSchema = yup.object().shape({
    response: yup
      .string()
      .required()
      .label("La réponse")
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
  });
  const [responseContact, { isSuccess, isError, error, isLoading }] =
    useResponseContactMutation();
  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
    setError,
  } = useForm<ResponseFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors);
    }
  }, [errors]);
  useEffect(() => {
    if (isSuccess) {
      refInput.current.editor.setText("");
      Swal.fire({
        icon: "success",
        title: "Réponse envoyée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        setResponse(null);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: `${
          err?.message
            ? err?.data?.message
            : `Une erreur de statut ${err?.status || "inconnue"} est survenue`
        }`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {}, []);

  const handleChange = (value: any) => {
    setResponse(value);
    setValue("response", value);
  };

  const onSubmit = async (data: ResponseFormData) => {
    console.log(data, refInput);
    data["message"] = message?.id;
    data["user"] = user?.id;
    await responseContact(data);
  };

  return {
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading: isLoading,
    response,
    handleChange,
    refInput,
  };
}

export default UseResponseForm;

export function UseEditStatutMessage(item: Contact) {
  const [sendData] = useUpdateContactMutation();
  const onEdit = async () => {
    await Swal.fire({
      title: `Statut du message`,
      input: "select",
      inputValue: item?.status,
      inputOptions: {
        new: "Nouveau",
        in_progress: "En cours",
        traited: "Terminer",
      },
      inputPlaceholder: "Selectionner un statut",
      showCancelButton: true,
      confirmButtonText: "Modifier",
      cancelButtonText: "Annuler",
      reverseButtons: true,
      confirmButtonColor: Color.default,
      showLoaderOnConfirm: true,

      preConfirm: (status) => {
        console.log("statut", status);
        if (!status) {
          return Swal.showValidationMessage(`Veuillez sélectionner un statut`);
        }
        return sendData({
          slug: item?.slug,
          status: status,
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `Le statut du message a été modifié avec succès!`,
            iconColor: Color.success,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onEdit;
}
