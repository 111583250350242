import React from 'react'
import { Wrapper } from './Shared';
import DocumentTable from './tables/DocumentTable';

function DocumentList() {
  return (
    <Wrapper customClass="mt-2">
      <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
        <div className="custom-wrapper-title">Documents</div>
        <button className="btn btn-theme-admin">Upload un document</button>
      </div>
      <div className="custom-table-container pt-4">
        <DocumentTable />
      </div>
    </Wrapper>
  );
}

export default DocumentList