import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import Select from "react-select";
import MyDropzone, { ImageItem } from "../../common/MyDropzone";
import ReactQuill from "react-quill";
import { useObjetReligieuxFromLocation } from "../../../utils/api/objetReligieux/objetReligieux.api";
import AddOrUpdateForm from "./form/AddOrUpdateForm";
import { useNavigate } from "react-router-dom";

function AddOrUpdateObjet() {
  const [objetReligieux] = useObjetReligieuxFromLocation();
  const { register, errors, onSubmit, isLoading, image, setImage } =
    AddOrUpdateForm(objetReligieux);
  const navigate = useNavigate();

  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          {!!objetReligieux ? (
            <BreadCrumb
              pageName="Objets religieux"
              secondPage="Modifier un objet"
            />
          ) : (
            <BreadCrumb
              pageName="Objets religieux"
              secondPage="Ajouter un nouvel objet"
            />
          )}
        </div>
        <div className="btn-action-container">
          <span className="egs__text">Ajouter un objet religieux</span>
        </div>
        <div className="form-content mt-2 bg-white p-3 pb-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors?.nom}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Image de l'objet{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                </label>
                <MyDropzone
                  setImages={setImage}
                  acceptFile={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    "image/gif": [],
                  }}
                  maxFile={1}
                />
                {image || objetReligieux?.image ? (
                  <ImageItem image={image || objetReligieux?.image} />
                ) : null}
                <FormError error={errors?.image} />
              </div>
            </div>
            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
              <button
                disabled={isLoading}
                className="btn btn-theme-cancel w-100"
                type="button"
                onClick={() => navigate(-1)}
              >
                Retour
              </button>
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>
                      {objetReligieux ? "Modification..." : "Ajout..."}
                    </span>
                  </>
                ) : objetReligieux ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddOrUpdateObjet;
