import { useEffect } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../utils/api/auth/auth.type";
import { useAppDispatch } from "../../../redux/hook";
import { useLoginUserMutation } from "../../../utils/api/auth/auth.api";
import { onSetUserToken } from "../../../redux/slice/User.slice";
import { cleannerError } from "../../../utils/Utils";

function UseLoginForm() {
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("L'email"),
    password: yup.string().required().label("Le mot de passe"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginUser, { isLoading }] = useLoginUserMutation();

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: LoginFormData) => {
    const res = await loginUser(data);

    if ("data" in res) {
      dispatch(onSetUserToken({ user: res.data.data, token: res.data.token }));
      let path: string = "/admin/dashboard";
      navigate(path);
    }
    if ("error" in res) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const err = res.error as any;
      let message = err?.data?.message
        ? err?.data?.message
        : err?.status < 500
        ? "Email ou mot de passe incorrect"
        : `Une erreur de statut ${err?.status} est survenue.`;
      setError("email", {
        message: message,
      });
    }
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
  };
}

export default UseLoginForm;
