import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { DocumentationFormData, IDocumentation } from "./documentations.type";

export const DocumentationsApi = createApi({
  reducerPath: "documentationsApi",
  tagTypes: ["documentations", "documentationsList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditDocumentations: builder.mutation<
      IDocumentation,
      { slug?: string; data: DocumentationFormData | FormData }
    >({
      invalidatesTags: ["documentations", "documentationsList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `documentations/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `documentations/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getDocumentations: builder.query<PaginationResults<IDocumentation>, TypeQuery>({
      providesTags: ["documentationsList"],
      query: (query) => QueryUrl("documentations/", query),
    }),

    deleteDocumentation: builder.mutation<DocumentationFormData, string>({
      query: (slug) => ({
        url: `documentations/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["documentations", "documentationsList"],
    }),
  }),
});

export const {
    useAddOrEditDocumentationsMutation,
    useDeleteDocumentationMutation,
    useGetDocumentationsQuery
} = DocumentationsApi;
