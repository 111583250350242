import React from "react";
import BreadCrumb from "../../../shared/BreadCrumb";
import { Wrapper } from "../DetailChef/Shared";
import QRCode from "../../../../assets/appImages/qrcode.png";
import fidelePhoto from "../../../../assets/appImages/fidele_photo.png";
import { Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import FideleCotisationTable from "../tables/FideleCotisationTable";
import { useUserFromLocation } from "../../../../utils/api/user/user.api";
import { getAvatar, getImage } from "../../../../utils/Utils";
import moment from "moment";
import ShowCNIUserModal from "../../../shared/ShowCNIUserModal";
import { SelectInput } from "../../../common/SelectInput";
import ShowCertificatUserModal from "../../../shared/ShowCertificatUserModal";
import { NavLink } from "react-router-dom";

function DetailFidele() {
  const [item] = useUserFromLocation();
  return (
    <>
      <div className="dash-admin-page-content-container mb-3">
        <BreadCrumb
          pageName="Fidèles"
          to="/admin/paroisses"
          secondPage={item?.prenom + " " + item?.nom}
          state="fideles"
        />
      </div>

      <div className="row row-detail-fidele">
        <div className="col-md-3 photo-and-qrcode">
          <Wrapper>
            <div className="photo-content">
              <h3 className="title">Photo</h3>
              <div className="fidele-photo-container">
                <img src={getAvatar(item?.avatar)} alt="photo" />
              </div>
            </div>

            {!!item?.qrcode && (
              <div className="qrcode-content pt-4">
                <h3 className="title">QR Code</h3>
                <div className="fidele-photo-container">
                  <img src={getImage(item?.qrcode)} alt="photo" />
                </div>
              </div>
            )}
          </Wrapper>
        </div>
        <div className="col-md-9 general-information-bio-cotisation">
          <Wrapper>
            <div className="general-information-content">
              <div className="d-flex justify-content-between mb-3 align-items-center">
                <div className="row w-100">
                  <div className="col-md-6 mb-3">
                    <h3 className="title">Information générale</h3>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    <div className="d-flex flex-column flex-md-row gap-2">
                      {!!item?.last_certificat?.file && (
                        <ShowCertificatUserModal
                          modalId={`certificatModal${item?.slug}`}
                          file={item?.last_certificat?.file}
                        />
                      )}
                      {!!item?.cni && (
                        <ShowCNIUserModal
                          modalId={`cniModal${item?.slug}`}
                          file={item?.cni}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-content">
                <form action="">
                  <div className="row">
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Prénom"
                        type="text"
                        placeholder=""
                        value={item?.prenom}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Nom"
                        type="text"
                        placeholder=""
                        value={item?.nom}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Sexe"
                        type="number"
                        placeholder={item?.sexe === "femme" ? "Femme" : "Homme"}
                        value={item?.sexe === "femme" ? "Femme" : "Homme"}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Téléphone"
                        type="text"
                        placeholder=""
                        value={item?.telephone}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Email"
                        type="email"
                        placeholder=""
                        value={item?.email}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Adresse"
                        type="text"
                        placeholder=""
                        value={item?.adresse}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Paroisse"
                        type="text"
                        placeholder=""
                        value={item?.paroisse_id?.nom}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Fonction"
                        type="text"
                        placeholder=""
                        value={item?.fonction}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Date de baptême"
                        type="text"
                        placeholder=""
                        value={
                          item?.date_bapteme
                            ? moment(item?.date_bapteme).format("DD/MM/YYYY")
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Grade"
                        type="text"
                        placeholder="Grade"
                        value={item?.grade?.titre}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Date de nomination"
                        type="text"
                        placeholder=""
                        value={
                          item?.date_nomination
                            ? moment(item?.date_nomination).format("DD/MM/YYYY")
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Date du dernier grade"
                        type="text"
                        placeholder=""
                        value={
                          item?.date_dernier_grade
                            ? moment(item?.date_dernier_grade).format(
                                "DD/MM/YYYY"
                              )
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Nationalité"
                        type="text"
                        placeholder="Nationalité"
                        value={item?.nationalite}
                      />
                    </div>
                    <div className="col-md-4">
                      <Input
                        readOnly
                        label="Pays"
                        type="text"
                        placeholder="Pays"
                        value={item?.pays?.nom}
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Input
                        readOnly
                        label="Onction"
                        type="text"
                        placeholder={
                          item?.onction === "prophete"
                            ? "Prophete"
                            : item?.onction === "clerge"
                            ? "Clerge"
                            : item?.onction === "honorable"
                            ? "Honorable"
                            : item?.onction === "membre"
                            ? "Membre"
                            : ""
                        }
                        value={
                          item?.onction === "prophete"
                            ? "Prophete"
                            : item?.onction === "clerge"
                            ? "Clerge"
                            : item?.onction === "honorable"
                            ? "Honorable"
                            : item?.onction === "membre"
                            ? "Membre"
                            : ""
                        }
                      />
                    </div>
                    <div className="col-md-4 mb-3">
                      <Input
                        readOnly
                        label="Modèle de certificat"
                        type="text"
                        placeholder={
                          item?.modele?.couleur === "bleu"
                            ? "Bleu"
                            : item?.modele?.couleur === "vert"
                            ? "Vert"
                            : item?.modele?.couleur === "beige" 
                            ? "Beige"
                            : item?.modele?.couleur === "violet" 
                            ? "Violet" : ""
                        }
                        value={
                          item?.modele?.couleur === "bleu"
                            ? "Bleu"
                            : item?.modele?.couleur === "vert"
                            ? "Vert"
                            : item?.modele?.couleur === "beige" 
                            ? "Beige"
                            : item?.modele?.couleur === "violet" 
                            ? "Violet" : ""
                        }
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="bio-content pt-3">
              <h3 className="title">Bio</h3>
              <div className="bio-content-quill">
                <ReactQuill value={item?.bio || ""} readOnly />
              </div>
            </div>
            <div className="bio-content pt-4">
              <h3 className="title">Cotisations</h3>
              <div className="table-cotisation-wrapper">
                <FideleCotisationTable item={item} />
              </div>
            </div>
          </Wrapper>
        </div>
      </div>
    </>
  );
}

export default DetailFidele;
