import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import MyDropzoneFile, { RenderFile } from "../../common/MyDropzoneFile";
import useCrudConstitutionBleu from "./useForm/UseCrudConstitutionBleu";
import { useConstitutionFromLocation } from "../../../utils/api/constitutionBleu/constitutionBleu.api";

function AddOrUpdateConstitutionBleu() {
  const [item] = useConstitutionFromLocation();
  const { onSubmit, errors, register, isLoading, setFichier, fichier } =
    useCrudConstitutionBleu(item);
  console.log("constitution: ", item);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb
            pageName="Constitution bleue"
            secondPage={
              !item ? "Ajouter une constitution" : "Modifier une constitution"
            }
          />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-12 mb-3">
                  <Input
                    type="text"
                    label="Titre"
                    id="titre"
                    placeholder="Titre"
                    {...register("titre")}
                    error={errors?.titre}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="contenu"
                    className="form-label form-label-modal-custom"
                  >
                    Ajouter un document{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>
                  <MyDropzoneFile
                    label="Fichiers constitution bleue"
                    setFiles={setFichier}
                    maxFile={1}
                    isPdfFile
                  />
                  {!!fichier && <RenderFile file={fichier?.name || fichier} isPdfFile={true} />}
                  <FormError error={errors?.fichier} />
                </div>
                <div className="col-md-12 mb-3">
                  {/* <FileGrid files={files} deleteFile={deleteFile} /> */}
                </div>
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn btn-theme-admin w-100"
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>{item ? "Modification..." : "Ajout..."}</span>
                      </>
                    ) : item ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdateConstitutionBleu;
