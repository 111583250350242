import React, { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { AppRoutes } from "./routes/router";
import { useAppDispatch, useAppSelector } from "./redux/hook";
import { useMeQuery } from "./utils/api/user/user.api";
import { onSetUser, onlogout } from "./redux/slice/User.slice";

function ScrollToTop() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
}

function FetchUser() {
  const { user } = useAppSelector((s) => s?.user);
  const { isSuccess, data, isError } = useMeQuery();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isSuccess) {
      console.log("user", data);
      if (data?.id === user?.id) {
        dispatch(onSetUser(data));
      }
    }

    if (isError) {
      dispatch(onlogout());
    }
  }, [isSuccess, data, isError]);

  return null;
}
function App() {
  const routes = useRoutes(AppRoutes);
  const isConnected = useAppSelector((s) => !!s.user?.token);

  return (
    <div className="app-routing-container">
      <ScrollToTop />
      {isConnected && <FetchUser />}
      {routes}
    </div>
  );
}

export default App;
