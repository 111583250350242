import React, { useState } from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { Input } from "../../common/Input";
import ReactQuill from "react-quill";
import { AiOutlinePlus } from "react-icons/ai";
import { useLocationState } from "../../../utils/Utils";
import { ICondition } from "../../../utils/api/contenu/condition.type";
import UseCrudPolicy from "./requestForm/UseCrudPolicy";
import ErrorMessage from "../../common/ErrorMessage";

function AddOrUpdatePolitiques() {
  const policy = useLocationState<ICondition>(undefined);
  const {
    register,
    errors,
    onSubmit,
    setValue,
    isLoading,
    reset,
    description,
    setDescription,
    handleChange,
  } = UseCrudPolicy(policy);

  React.useEffect(() => {
    if (policy) {
      setValue("type", policy?.type);
      setValue("titre", policy?.titre);
      setValue("slug", policy?.slug);
      setValue("text", policy?.text);
      setDescription(policy?.text || "");
    }
  }, [policy]);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Paramètres" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="medsain-page-title">
            Ajouter politiques de confidentialités
          </div>
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-12">
                  <Input
                    type="text"
                    label="Titre"
                    id="titre"
                    placeholder="Titre"
                    {...register("titre")}
                    // error={errors.titre}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="description"
                    className="form-label form-label-modal-custom"
                    aria-labelledby="description"
                  >
                    Description{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>
                  <ReactQuill
                    className="editor-cgu"
                    theme="snow"
                    value={description}
                    onChange={handleChange}
                  />
                  {errors.text && (
                    <ErrorMessage message={errors.text?.message} />
                  )}
                </div>
                {/* <div className="col-md-12">
                  <button className="btn custom-ajout-chapitre-btn">
                    <AiOutlinePlus /> Ajouter un autre chapitre
                  </button>
                </div> */}
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    className="btn btn-theme-admin w-100"
                    disabled={isLoading}
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>{policy ? "Modification..." : "Ajout..."}</span>
                      </>
                    ) : policy ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdatePolitiques;
