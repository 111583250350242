import React, { useState } from "react";
import "./AffectationAdmin.css";
import { NavLink } from "react-router-dom";
import AffectationCountryTable from "./tables/AffectationCountryTable";


function AffectationAdmin() {
    let [country, setCountry] = useState<string>("Senegal")
    return (
        <>
            <div className="dashbord-admin-component">
                <div className="dash-admin-page-content-container d-flex align-items-center justify-content-between mb-4">
                    <h2 className="chart-item-admin-title m-0">
                        Affectation
                    </h2>
                    <NavLink
                        to={`/admin/affectation/add-affectation`}
                        className="btn btn-theme-admin"
                    >
                        <span>Ajouter une affectation</span>
                    </NavLink>
                </div>

                <div className="custom-content">
                    <AffectationCountryTable country={country} />
                </div>
                {/* <div className="big-map-container">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d63218495.12795645!2d-23.793376344075547!3d14.737974199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sfr!2ssn!4v1693319418545!5m2!1sfr!2ssn" 
                        className="frame-map" 
                        style={{border: 0}} 
                        allowFullScreen={true} 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade"
                    >
                    </iframe>
                </div> */}
            </div>
        </>
    )
}

export default AffectationAdmin;