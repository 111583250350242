import React, { useState } from "react";
import "./DashboardAdmin.css";
import LineChart from "./LineChat/LineChart";
import Icon1 from "../../../assets/icons/icon1.svg";
import Icon2 from "../../../assets/icons/icon2.svg";
import Icon3 from "../../../assets/icons/icon3.svg";
import Icon4 from "../../../assets/icons/icon4.svg";
import { useGetAdminDataQuery } from "../../../utils/api/user/user.api";
import { useSearch } from "../../../utils/hooks";

const DashboardAdmin = () => {
  const { search, handleSearch } = useSearch("fidele");
  const [type, setType] = useState("year");
  const { data } = useGetAdminDataQuery({
    word: search,
    q_date: type === "custom" ? "" : type,
  });
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view ">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Nombre de fidèles
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <p className="chiffre-item-stat-dashboad-view mb-1">
                    {data?.fideles}
                  </p>
                  <img
                    src={Icon1}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Fidèles baptisés
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <p className="chiffre-item-stat-dashboad-view mb-1">
                    {data?.fideles_baptises}
                  </p>
                  <img
                    src={Icon2}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Activités
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <p className="chiffre-item-stat-dashboad-view mb-1">
                    {data?.activites}
                  </p>
                  <img
                    src={Icon3}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-3 dis-flex">
            <div className="item-stat-dashboad-view">
              <div className="content-stat-dashboard-view">
                <div className="content-text-item-stat-dashboad-view">
                  <div className="icon-item-stat-dashboad-view">
                    <p className="titre-item-stat-dashboad-view mb-1">
                      Finances
                    </p>
                  </div>
                </div>
                <div className="content-item-icon-stat-dashboard-view">
                  <p className="chiffre-item-stat-dashboad-view mb-1">
                    {data?.finances}
                  </p>
                  <img
                    src={Icon4}
                    alt="Dashboard"
                    className="img-icon-dash-view"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-graph-admin mt-5 mb-3">
          <LineChart
            graph={data?.graph}
            handleSearch={handleSearch}
            search={search}
            setType={setType}
            type={type}
          />
        </div>
      </div>
    </div>
  );
};

export default DashboardAdmin;
