export const DocumnentTableData = [
    {
        id: 1,
        document: "Carte d’identification du chrétien céleste",
        date: "12 Mai 2023",
        uploaded_by: "Dobria Steph",
    },
    {
        id: 2,
        document: "Carte d’identification du chrétien céleste",
        date: "12 Mai 2023",
        uploaded_by: "Dobria Steph",
    },
    {
        id: 3,
        document: "Carte d’identification du chrétien céleste",
        date: "12 Mai 2023",
        uploaded_by: "Dobria Steph",
    },
    {
        id: 4,
        document: "Carte d’identification du chrétien céleste",
        date: "12 Mai 2023",
        uploaded_by: "Dobria Steph",
    },
    {
        id: 5,
        document: "Carte d’identification du chrétien céleste",
        date: "12 Mai 2023",
        uploaded_by: "Dobria Steph",
    },
    {
        id: 6,
        document: "Carte d’identification du chrétien céleste",
        date: "12 Mai 2023",
        uploaded_by: "Dobria Steph",
    },

]