import { FiAlertCircle } from "react-icons/fi"
type PropsType = {
  message: string
}
export function AlertInfo({ message }: PropsType) {
  return (
		<div className="eglise-alert eglise-alert-info p-3 mt-3" role="alert">
			<FiAlertCircle style={{ fontSize: 24 }} />
			<h4>{message}</h4>
		</div>
  );
}