import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { IPreambule, PreambuleFormData } from "./preambule.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const PreambuleApi = createApi({
  reducerPath: "preambuleApi",
  tagTypes: ["preambule", "preambuleList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditPreambule: builder.mutation<
      IPreambule,
      { slug?: string; data: PreambuleFormData | FormData }
    >({
      invalidatesTags: ["preambule", "preambuleList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `preambules/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `preambules/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getPreambule: builder.query<PaginationResults<IPreambule>, TypeQuery>({
      providesTags: ["preambuleList"],
      query: (query) => QueryUrl("preambules/", query),
    }),

    deletePreambule: builder.mutation<PreambuleFormData, string>({
      query: (slug) => ({
        url: `preambules/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["preambule", "preambuleList"],
    }),
  }),
});

export const {
    useAddOrEditPreambuleMutation,
    useDeletePreambuleMutation,
    useGetPreambuleQuery,
  } = PreambuleApi;
