import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { IPays, PaysFormData } from "./pays.type";

export const PaysApi = createApi({
  reducerPath: "paysApi",
  tagTypes: ["pays", "paysList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditPays: builder.mutation<
      IPays,
      { slug?: string; data: PaysFormData | FormData }
    >({
      invalidatesTags: ["pays", "paysList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `pays/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `pays/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getPays: builder.query<PaginationResults<IPays>, TypeQuery>({
      providesTags: ["paysList"],
      query: (query) => QueryUrl("pays/", query),
    }),

    deletePays: builder.mutation<PaysFormData, string>({
      query: (slug) => ({
        url: `pays/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["pays", "paysList"],
    }),
  }),
});

export const {
    useAddOrEditPaysMutation,
    useDeletePaysMutation,
    useGetPaysQuery
  } = PaysApi;
