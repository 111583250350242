import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input, animatedComponents } from "../../common/Input";
import Select from "react-select";
import MyDropzone, { ImageItem } from "../../common/MyDropzone";
import ReactQuill from "react-quill";
import UseCrudActualiteForm from "./form/UseCrudActualiteForm";
import { useActualiteFromLocation } from "../../../utils/api/actualite/actualite.api";
import { useNavigate } from "react-router-dom";

function AddOrUpdateActualite() {
  const [item] = useActualiteFromLocation();
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    control,
    Controller,
    image,
    setImage,
    handleSelect,
    options,
    option,
    contenu,
    handleChange,
    setQuery,
  } = UseCrudActualiteForm(item);
  const navigate = useNavigate();

  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          <BreadCrumb
            pageName="Actualités"
            secondPage={!item ? "Nouvelle actualité" : "Modifier l'actualité"}
          />
        </div>
        <div className="btn-action-container">
          <span className="egs__text">
            {!item ? "Ajouter" : "Modifier"} une actualité
          </span>
        </div>
        <div className="form-content mt-2 bg-white p-3 pb-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <Input
                  label="Titre"
                  type="text"
                  placeholder="Titre"
                  required
                  {...register("titre")}
                  error={errors?.titre}
                />
              </div>

              <div className="col-md-6 mb-3">
                <label
                  htmlFor="theme"
                  className="form-label form-label-modal-custom"
                >
                  Thème
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                {/* <Select /> */}
                <Controller
                  name="theme"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        // isMulti
                        options={options}
                        classNamePrefix="react-select-custom"
                        onChange={handleSelect}
                        value={option}
                        onInputChange={(val) => setQuery(val)}
                      />
                    );
                  }}
                />
                <FormError error={errors?.theme} />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Bannière
                </label>
                <MyDropzone
                  setImages={setImage}
                  acceptFile={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    "image/gif": [],
                  }}
                  maxFile={1}
                />
                {image || item?.image ? (
                  <ImageItem image={image || item?.image} />
                ) : null}
                <FormError error={errors?.image} />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Description
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={contenu}
                  onChange={handleChange}
                />
                <FormError error={errors?.content} />
              </div>
            </div>
            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
              <button
                disabled={isLoading}
                className="btn btn-theme-cancel w-100"
                type="button"
                onClick={() => navigate(-1)}
              >
                Retour
              </button>
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{item ? "Modification..." : "Ajout..."}</span>
                  </>
                ) : item ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddOrUpdateActualite;
