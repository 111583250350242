import React, { ChangeEvent, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError, getImage } from "../../../../utils/Utils";
import { useDelete } from "../../../../utils/helpers";
import { FaTrash } from "react-icons/fa";
import {
  useAddOrEditNecrologieMutation,
  useDeleteNecrologieMutation,
} from "../../../../utils/api/necrologie/necrologie.api";
import {
  Necrologie,
  NecrologieFormData,
} from "../../../../utils/api/necrologie/necrologie.type";
import { useGetPaysQuery } from "../../../../utils/api/pays/pays.api";
import { useGetParoissesQuery } from "../../../../utils/api/paroisses/paroisses.api";

function useCrudNecrologie(
  setModalAddNecrologie: any,
  necrologie?: Necrologie
) {
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Le nom").nullable(),
    prenom: yup.string().required().label("Le prenom").nullable(),
    age: yup
      .number()
      .required()
      .label("L'age")
      .transform((val: any) => (isNaN(val) ? null : val))
      .nullable(),
    date: yup
      .date()
      .required()
      .transform((value, originalValue) => {
        return originalValue === "" ? undefined : value;
      })
      .max(new Date(), "La date de nécrologie ne peut pas être dans le futur")
      .nullable(),
    sexe: yup.string().required().label("Le sexe").nullable(),
    pays: yup.string().required().label("Le pays"),
    paroisse: yup.string().required().label("La paroisse").nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
    setError,
    control,
  } = useForm<NecrologieFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [pays, setPays] = useState<{ label: string; value: number }[]>([]);
  const [selected, setSelected] = useState<{ label: string; value: number }>();
  const [inputValue, setInputValue] = useState("");
  const { data = { results: [] } } = useGetPaysQuery({
    limit: 1000,
  });
  const { data: paroisses = { results: [] } } = useGetParoissesQuery({
    limit: 100,
    q: inputValue,
  });
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditNecrologieMutation();

  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [option, setOption] = useState<
    { label: string; value: number } | undefined
  >();
  const [previewImage, setPreviewImage] = useState(
    necrologie?.image ? getImage(necrologie?.image) : null
  );

  const handleChangePays = (val) => {
    setSelected(val);
    setValue("pays", val?.value);
  };
  const handleSelectParoisse = (val) => {
    setOption(val);
    setValue("paroisse", val?.value);
  };

  useEffect(() => {
    if (paroisses?.results?.length) {
      setOptions(
        paroisses?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [paroisses]);

  useEffect(() => {
    if (data?.results?.length) {
      setPays(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Nécrologie ${necrologie ? "modifiée" : "ajoutée"} avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        handleReset();
        setModalAddNecrologie(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("image", {
        message: "Format d'image non valide !",
      });
    }
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      register("image");
      setValue("image", file);
    }
  }, []);

  const onSubmit = async (data: NecrologieFormData) => {
    console.log("DATA SUBMITED", data);

    if (data.date) {
      const date = new Date(data.date);
      if (!isNaN(date.getTime())) {
        data.date = date.toISOString().slice(0, 10);
      } else {
        data.date = "";
      }
    }

    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key] && data[key] !== undefined) {
        fd.append(key, data[key]);
      }
    }

    await sendData({ slug: necrologie?.slug, data: fd });
  };

  const handleReset = () => {
    if (!necrologie) {
      reset();
      setSelected(undefined);
      setOption(undefined);
    }
  };

  useEffect(() => {
    if (necrologie?.id) {
      let fields: (keyof NecrologieFormData)[] = [
        "slug",
        "nom",
        "prenom",
        "sexe",
        "pays",
        "age",
        "date",
        "paroisse",
      ];
      for (let field of fields) {
        register(field);
        if (field === "date" && necrologie[field]) {
          const dateString = necrologie[field];
          const date = new Date(dateString);

          if (
            dateString &&
            !isNaN(date.getTime()) &&
            dateString !== "Invalid Date"
          ) {
            setValue(field, date.toISOString().slice(0, 10)); // formatter la date YYYY-MM-DD
          } else {
            setValue(field, "");
          }
        } else if (field !== "pays" && field !== "paroisse") {
          setValue(field, necrologie[field]);
        } else {
          if (necrologie[field]) setValue(field, necrologie[field]?.id);
        }
      }
      if (necrologie?.pays) {
        setSelected({
          label: necrologie?.pays?.nom,
          value: necrologie?.pays?.id,
        });
      }

      if (necrologie?.paroisse) {
        setOption({
          label: necrologie?.paroisse?.nom,
          value: necrologie?.paroisse?.id,
        });
      }
    }
  }, [necrologie]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    pays,
    selected,
    option,
    handleChangePays,
    previewImage,
    setPreviewImage,
    handelImageChange,
    handleReset,
    handleSelectParoisse,
    options,
    Controller,
    control,
  };
}

export function DeleteNecrologie({ item }: { item: Necrologie }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteNecrologieMutation();
  const onDelete = useDelete<NecrologieFormData>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette nécrologie",
    successMessage: "Nécrologie  supprimée",
  });
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={onDelete}
      >
        <FaTrash />
      </button>
    </div>
  );
}

export default useCrudNecrologie;
