import React, { useState } from "react";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import "./Messagerie.css";
import Profil from "../../../assets/appImages/patient.png";
import BreadCrumb from "../../shared/BreadCrumb";
import { BiSearchAlt2 } from "react-icons/bi";
import { useGetContactMessagesQuery } from "../../../utils/api/contact/contact.api";
import { AlertInfo } from "../../common/Alert";
import {
  formatDate,
  getAvatar,
  getName,
  showModal,
} from "../../../utils/Utils";
import { Contact } from "../../../utils/api/contact/contact.type";
import { CustomPagination } from "../../common/CustomPagination";
import DetailsMessageModal from "./modal/DetailsMessageModal";
import { UseEditStatutMessage } from "./form/UseResponseForm";
import Skeleton from "react-loading-skeleton";

export const getType = (type: "mise_en_relation" | "plainte") => {
  let text = "Contact";

  if (type === "plainte") {
    text = "Signalement";
  }

  return text;
};

export const getStatut = (type: Contact["status"]) => {
  let text = "Nouveau";

  if (type === "in_progress") {
    text = "En cours";
  }

  if (type === "traited") {
    text = "Terminé";
  }

  return text;
};

const MessageItem = ({ item }: { item: Contact }) => {
  const onEdit = UseEditStatutMessage(item);
  return (
    <>
      <div className="content-checkbox-message-item mb-4">
        <label className="checkbox" htmlFor="message">
          <div className="content-label-message w-100 row">
            <div
              className="col-md-4"
              onClick={() => showModal(`messageModal${item?.slug}`)}
            >
              <p className="text-libelle-message mb-2">
                {item?.message?.slice(0, 50) + "..."}
              </p>
              <p className="text-date-message mb-0">
                {formatDate(item?.created_at)}
              </p>
            </div>
            <div
              className="text-center col-md-2"
              onClick={() => showModal(`messageModal${item?.slug}`)}
            >
              <p
                className={`text-date-message mb-0 badge ${item?.contact_type}`}
              >
                {getType(item?.contact_type)}
              </p>
            </div>
            <div className="text-center col-md-2">
              <p
                className={`text-date-message mb-0 badge ${item?.status}`}
                onClick={() => onEdit()}
              >
                {getStatut(item?.status)}
              </p>
            </div>
            <div
              className="flex-r col-md-4"
              onClick={() => showModal(`messageModal${item?.slug}`)}
            >
              <div className="content-label-img-profil-mmessage">
                <div className="content-img-profil-mmessage">
                  <img
                    src={getAvatar(item?.user?.avatar)}
                    alt={item?.user?.nom + item?.id}
                    className="img-profil-mmessage"
                  />
                </div>
                <p className="text-name-profil-message mb-0">
                  {getName(item?.user)}
                </p>
              </div>
            </div>
          </div>
        </label>
      </div>
      <DetailsMessageModal
        message={item}
        modalId={`messageModal${item?.slug}`}
      />
    </>
  );
};

const MessageSkeleton = () => {
  return (
    <>
      {[...Array(10)]?.map((item, i) => (
        <div className="content-checkbox-message-item mb-4" key={i}>
          <label className="checkbox" htmlFor="message">
            <div className="content-label-message w-100 row">
              <div className="col-md-4">
                <Skeleton className="text-libelle-message mb-2" />
                <Skeleton className="text-date-message mb-0" width={`50%`} />
              </div>
              <div className="text-center col-md-2">
                <Skeleton
                  className={`text-date-message mb-0 badge`}
                  width={`60%`}
                />
              </div>
              <div className="text-center col-md-2">
                <Skeleton
                  className={`text-date-message mb-0 badge`}
                  width={`60%`}
                />
              </div>
              <div className="flex-r col-md-4">
                <div className="content-label-img-profil-mmessage">
                  <div className="content-img-profil-mmessage">
                    <Skeleton circle className="img-profil-mmessage" />
                  </div>
                  <Skeleton
                    className="text-name-profil-message mb-0"
                    width={120}
                  />
                </div>
              </div>
            </div>
          </label>
        </div>
      ))}
    </>
  );
};

const Messagerie = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetContactMessagesQuery({
      page,
      limit,
      q: query,
    });

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Messages" />
        </div>
        <div className="row">
          <div className="col-12 col-right-messagerie d-flex mb-3">
            <div className="content-graph-admin w-100">
              <div className="">
                <div className="content-form-filter mt-4 mb-5 d-flex align-items-center gap-2 w-100">
                  <div className="w-30">
                    <div className="content-form-search-bloc position-relative">
                      <input
                        type="search"
                        className="form-control form-control-search"
                        placeholder="Tapez pour rechercher"
                        onChange={(e) => setQuery(e?.target?.value)}
                      />
                      <span className="container-icon-searh-form">
                        <BiSearchAlt2 />
                      </span>
                    </div>
                  </div>
                  {/* <button className="btn btn-icon-filter">
                    <BsFillFilterSquareFill />
                  </button> */}
                </div>

                <div className="bloc-message-body py-4">
                  <div className="checkbox-container">
                    <div className="content-checkbox-message-header mb-4">
                      <div className="checkbox-div">
                        <div className="content-label-message w-100 row">
                          <div className="col-md-4">
                            <p className="text-name-profil-message mb-2">
                              Message
                            </p>
                          </div>
                          <div className="text-center col-md-2">
                            <p className="text-name-profil-message mb-0">
                              Type de message
                            </p>
                          </div>
                          <div className="text-center col-md-2">
                            <p className={`text-name-profil-message mb-0`}>
                              Statut
                            </p>
                          </div>
                          <div className="flex-r col-md-4">
                            <div className="content-label-img-profil-mmessage">
                              <div className="content-img-profil-mmessage"></div>
                              <p className="text-name-profil-message mb-0">
                                Utilisateur
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {!!isLoading && <MessageSkeleton />}
                    {!isLoading &&
                      (data?.results?.length ? (
                        data?.results?.map((item, i) => (
                          <MessageItem item={item} key={item?.slug} />
                        ))
                      ) : (
                        <AlertInfo message="Aucune donnée trouvée" />
                      ))}
                  </div>
                  <div className="custom-pagination-container">
                    <CustomPagination
                      nbPages={data?.count}
                      page={page}
                      onChange={(page, perPage) => {
                        setLimit(perPage);
                        setPage(page);
                      }}
                      perPage={limit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messagerie;

const Data = [
  {
    id: 1,
    nom: "Paul Gomis",
    img: Profil,
    message:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lmagna aliqua.",
    date: "12:21",
  },
  {
    id: 1,
    nom: "Paul Gomis",
    img: Profil,
    message:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lmagna aliqua.",
    date: "12:21",
  },
  {
    id: 1,
    nom: "Paul Gomis",
    img: Profil,
    message:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lmagna aliqua.",
    date: "12:21",
  },
  {
    id: 1,
    nom: "Paul Gomis",
    img: Profil,
    message:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lmagna aliqua.",
    date: "12:21",
  },
  {
    id: 1,
    nom: "Paul Gomis",
    img: Profil,
    message:
      "Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut lmagna aliqua.",
    date: "12:21",
  },
];
