import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { INotification } from "../notification/notification.type";
import { prepareHeaders } from "../user/user.api";
import { NotificationFormData } from "./notification.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const NotificationApi = createApi({
  tagTypes: ["notifications"],
  reducerPath: "NotificationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    updateReadNotification: builder.mutation<
      INotification,
      NotificationFormData
    >({
      query: ({ slug, ...data }) => ({
        url: `notifications/${slug}/`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["notifications"],
    }),
    getNotification: builder.query<PaginationResults<INotification>, TypeQuery>({
      providesTags: ["notifications"],
      query: (query) => QueryUrl("notifications/", query),
    }),
  }),
});

export const {
  useGetNotificationQuery,
  useUpdateReadNotificationMutation,
} = NotificationApi;
