import React, { useState } from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import ReactQuill from "react-quill";
import UseCrudPreambule from "./requestForm/UseCrudPreambule";
import { useLocationState } from "../../../utils/Utils";
import { IPreambule } from "../../../utils/api/preambule/preambule.type";

function AddOrUpdatePreambuleAdmin() {
  const preambule = useLocationState<IPreambule>(undefined);
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    content,
    handleChangeContent,
  } = UseCrudPreambule(preambule);

  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Préambule" secondPage={preambule ? `Mise à jour` : `Ajouter`} />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <div className="form-add-container">
            <form className="mt-4" onSubmit={onSubmit}>
              <div className="row row-add-student my-3">
                <div className="col-md-12 mb-3">
                  <Input
                    type="text"
                    label="Titre"
                    id="titre"
                    placeholder="Titre"
                    {...register("titre")}
                    error={errors.titre}
                    required
                  />
                </div>
                <div className="col-md-12">
                  <label
                    htmlFor="contenu"
                    className="form-label form-label-modal-custom"
                  >
                    Contenu{" "}
                    <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                  </label>
                  <ReactQuill
                    className="editor-cgu"
                    theme="snow"
                    value={content}
                    onChange={handleChangeContent}
                  />
                  <FormError error={errors?.content} />
                </div>
                <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
                  <button
                    disabled={isLoading}
                    className="btn btn-theme-admin w-100"
                    type="submit"
                  >
                    {isLoading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                          role="status"
                        ></span>
                        <span>{preambule ? "Modification..." : "Ajout..."}</span>
                      </>
                    ) : preambule ? (
                      "Modifier"
                    ) : (
                      "Ajouter"
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddOrUpdatePreambuleAdmin;
