import React from "react";
import FormContent from "../FormContent";
import { useParoisseFromLocation } from "../../../../../utils/api/paroisses/paroisses.api";

function ChefDeDiocese() {
  const [item] = useParoisseFromLocation()
  return (
    <>
      <FormContent type="chef_diocese" item={item?.chef_diocese} />
    </>
  );
}

export default ChefDeDiocese;
