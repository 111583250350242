import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import cover from "../../../assets/appImages/actualite.png";
import { useActualiteFromLocation } from "../../../utils/api/actualite/actualite.api";
import moment from "moment";
import { createMarkup, getImage, getName } from "../../../utils/Utils";

function ActualiteDetails() {
  const [item] = useActualiteFromLocation();
  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          <BreadCrumb pageName="Actualités" secondPage="Détails" />
        </div>
        <div className="btn-action-container">
          <span className="egs__text">Détails d'une actualité</span>
        </div>
        <div className="custom-content mt-2">
          <div className="bg_colgate actu_detail__wrapper">
            <div className="actu-header d-flex align-items-center justify-content-between">
              <div className="title-and-other-infos">
                <p className="m-0 sm_title">{"Lorem ipsum dolor sit amet"}</p>
                <p className="m-0 d-flex align-items-center gap-2 pt-2 small_text publication-date-and-author">
                  <span className="publication-date author">
                    {moment(item?.created_at).fromNow()}&nbsp;·&nbsp;Par &nbsp;
                    {getName(item?.user)}
                  </span>
                </p>
              </div>
              <div className="theme egs__text">{item?.theme?.titre}</div>
            </div>
            <div className="big_content pt-4">
              <p className="m-0 sm_small_description">{item?.titre}</p>
              <p className="m-0 cover-wrapper py-3">
                <img src={getImage(item?.image)} alt="cover" className="cover" />
              </p>
              <div
                className="long-description"
                dangerouslySetInnerHTML={createMarkup(item?.content)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActualiteDetails;
