import React from "react";
import { Wrapper } from "./Shared";
import { Authorisations } from "../../../../utils/constante";
import { FormError, Input, animatedComponents } from "../../../common/Input";
import Select from "react-select";
import { IUser, typeUser } from "../../../../utils/api/user/user.type";
import UseAddOrEditChefForm from "./form/UseAddOrEditChefForm";
import { getAvatar } from "../../../../utils/Utils";
import { TiCamera } from "react-icons/ti";
import PhoneInput from "react-phone-input-2";
import GoogleInput from "../../../common/GoogleInput";

function FormContent({ type, item }: { type: typeUser; item?: IUser }) {
  const {
    register,
    errors,
    onSubmit,
    phone,
    handleChangeAvatar,
    handleChangePhone,
    avatar,
    onChangeAddress,
    address,
    Controller,
    control,
    isLoading,
    option,
    onSelectGrade,
    options,
    setSearch,
  } = UseAddOrEditChefForm(type,item);

  return (
    <form onSubmit={onSubmit}>
      <Wrapper>
        {/* <div className="office-grid-cover-and-name">
          <img className="photo" src={photo} alt="photo" srcSet="" />
          <span className="name">Saint Joseph</span>
        </div> */}
        <div className="office-grid-cover-and-name">
          <div className="container-img-profil-user mb-0">
            <div className="d-flex justify-content-center align-items-center mb-0 leader-auto-user-avatar-container">
              <label htmlFor="input-test" className="input-testt">
                <img
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : getAvatar(item?.avatar)
                  }
                  alt="Profil"
                  className="custom-img-profil-user-admin photo"
                />
                <div className="leader-auto-user-avatar-edit-hover-deux">
                  <TiCamera className="edit-avatar-camera-icon" />
                </div>
              </label>
              <input
                type="file"
                className="input-add-avatar"
                id="input-test"
                accept="image/*"
                onChange={(e) => handleChangeAvatar(e)}
              />
            </div>
          </div>
          <span className="name">
            {(item?.prenom || "") + " " + (item?.nom || "")}
          </span>
        </div>
      </Wrapper>

      <Wrapper customClass="mt-2">
        <div className="content-btn-theme-title custom-wrapper-title-container pb-5">
          <div className="custom-wrapper-title">Informations personnelles</div>
          {/* <NavLink to="/admin/office-pastoral" className="btn btn-theme-admin">
            Modifier
          </NavLink> */}
        </div>
        <div className="form-content">
          <div>
            <div className="row row-infos-perso">
              <div className="col-md-6">
                <Input
                  label="Nom"
                  type="text"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors.nom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Prénom"
                  type="text"
                  placeholder="Prénom"
                  {...register("prenom")}
                  error={errors.prenom}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Email"
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                  error={errors.email}
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"telephone"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"telephone"}
                  >
                    Téléphone
                  </label>

                  <PhoneInput
                    defaultMask={".. ... .. .."}
                    containerClass="phone-input-admin"
                    inputClass="form-control custom-padding"
                    country={"sn"}
                    placeholder=""
                    inputProps={{
                      name: "telephone",
                      required: true,
                      autoFocus: true,
                    }}
                    countryCodeEditable={false}
                    enableAreaCodes={true}
                    prefix="+"
                    value={phone}
                    onChange={(phone, country: any) => {
                      handleChangePhone(phone, country);
                    }}
                    inputStyle={{
                      width: "100%",
                      paddingBottom: "22px",
                      borderRadius: "10px",
                      paddingTop: "22px",
                      height: "54px",
                      backgroundColor: "#F2F6FA",
                      border: "none",
                    }}
                    dropdownStyle={{
                      backgroundColor: "#F2F6FA",
                    }}
                    enableLongNumbers={true}
                  />
                  <FormError error={errors?.telephone?.message} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Adresse
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <GoogleInput
                    className={`form-control form-control-modal-custom`}
                    placeholder="Adresse"
                    value={address}
                    onChange={onChangeAddress}
                  />
                  <FormError error={errors.adresse} />
                </div>
              </div>
              <div className="col-md-6">
                <Input
                  label="Boite postale"
                  type="text"
                  placeholder="Boite postale"
                  {...register("boite_postale")}
                  error={errors.boite_postale}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Fonction"
                  type="text"
                  placeholder="Fonction"
                  {...register("fonction")}
                  error={errors.fonction}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Date du 1er baptême"
                  type="date"
                  placeholder="|"
                  {...register("date_bapteme")}
                  error={errors.date_bapteme}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Date de nomination"
                  type="date"
                  placeholder="|"
                  {...register("date_nomination")}
                  error={errors.date_nomination}
                />
              </div>
              <div className="col-md-6 d-flex flex-column gap-1 mb-3">
                <label
                  htmlFor=""
                  className="form-label form-label-modal-custom"
                >
                  Grade{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <Controller
                  name="grade"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        {...field}
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        options={options}
                        value={option}
                        onInputChange={(val) => setSearch(val)}
                        onChange={(val) => onSelectGrade(val)}
                        className="custom-select-items"
                      />
                    );
                  }}
                />

                <FormError error={errors.grade} />
              </div>
              <div className="col-md-6">
                <Input
                  label="Date du dernier grade"
                  type="date"
                  placeholder="|"
                  {...register("date_dernier_grade")}
                  error={errors.date_dernier_grade}
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      <Wrapper customClass="mt-2">
        <div className="custom-wrapper-title pb-5">Habilitations</div>
        <div className="container-checkbox-input">
          <div className="form-checkbox-input checkbox-input">
            <div className="custom-permission-container">
              {Authorisations?.map((item) => (
                <label key={item?.field} className={item?.field === "dashboard" ? "disabled" : ""}>
                  <input
                    type="checkbox"
                    {...register(`${item?.field}`)}
                    id={`checkbox-${item?.field}`}
                    disabled={
                      item?.field === "dashboard"
                    }
                  />
                  <span>{item?.label}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
        <div className="col-md-4 offset-md-8 pt-5">
          <button
            className="btn btn-theme-admin w-100"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                  role="status"
                ></span>
                <span>{"Enregistrement..."}</span>
              </>
            ) : (
              "Enregistrer"
            )}
          </button>
        </div>
      </Wrapper>
    </form>
  );
}

export default FormContent;
