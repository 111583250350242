export const regions = [
  {
    label: "Dakar",
    value: "Dakar",
  },
  {
    label: "Diourbel",
    value: "Diourbel",
  },
  {
    label: "Fatick",
    value: "Fatick",
  },
  {
    label: "Kaffrine",
    value: "Kaffrine",
  },
  {
    label: "Kaolack",
    value: "Kaolack",
  },
  {
    label: "kédougou",
    value: "kédougou",
  },
  {
    label: "Kolda",
    value: "Kolda",
  },
  {
    label: "Louga",
    value: "Louga",
  },
  {
    label: "Matam",
    value: "Matam",
  },
  {
    label: "Saint-Louis",
    value: "Saint-Louis",
  },
  {
    label: "Sédhiou",
    value: "Sédhiou",
  },
  {
    label: "Tambacounda",
    value: "Tambacounda",
  },
  {
    label: "Thiès",
    value: "Thiès",
  },
  {
    label: "Ziguinchor",
    value: "Ziguinchor",
  },
];

type Authorization = {
  field: FieldType;
  label: string;
  path: string;
};

export type FieldType =
  | "dashboard"
  | "preambules"
  | "contitution_bleues"
  | "pasteurs"
  | "conseil_pastoral"
  | "conseil_administration"
  | "office_pastoral"
  | "paroisses"
  | "affectations"
  | "formations"
  | "documentations"
  | "carnet_roses"
  | "finances"
  | "témoignages"
  | "necrologies"
  | "messages"
  | "fideles"
  | "demandes"
  | "actualites"
  | "parametres"
  | "cotisations"
  | "responsable_paroissien"
  | "ceremonies"
  | "objet_religieux"
  | "collectes"
  | "responsable_diocesien";
export const Authorisations: Authorization[] = [
  {
    field: "dashboard",
    label: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    field: "preambules",
    label: "Preambules",
    path: "/admin/preambule",
  },
  {
    field: "contitution_bleues",
    label: "Constitution bleue",
    path: "/admin/constitution-bleu",
  },
  {
    field: "pasteurs",
    label: "Pasteurs",
    path: "/admin/pasteurs",
  },
  {
    field: "conseil_pastoral",
    label: "Conseil pastoral",
    path: "/admin/conseil-pastoral",
  },
  {
    field: "conseil_administration",
    label: "Conseil administration",
    path: "/admin/conseil-administration",
  },
  {
    field: "office_pastoral",
    label: "Office pastoral",
    path: "/admin/office-pastoral",
  },
  {
    field: "responsable_paroissien",
    label: "Responsable paroissial",
    path: "/admin/responsable-paroissien",
  },
  {
    field: "responsable_diocesien",
    label: "Responsable diocèsien",
    path: "/admin/responsable-dioceses",
  },
  {
    field: "paroisses",
    label: "Paroisses",
    path: "/admin/paroisses",
  },
  {
    field: "fideles",
    label: "Fideles",
    path: "/admin/paroisses",
  },
  {
    field: "affectations",
    label: "Affectations",
    path: "/admin/affectation",
  },
  {
    field: "formations",
    label: "Formations",
    path: "/admin/formations",
  },
  {
    field: "ceremonies",
    label: "Cérémonies",
    path: "/admin/ceremonies",
  },
  {
    field: "documentations",
    label: "Documentations",
    path: "/admin/documentations",
  },
  {
    field: "carnet_roses",
    label: "Carnet roses",
    path: "/admin/carnet-rose",
  },
  {
    field: "objet_religieux",
    label: "Objets religieux",
    path: "/admin/objets-religieux",
  },
  {
    field: "finances",
    label: "Finances",
    path: "/admin/finances",
  },
  {
    field: "collectes",
    label: "Collectes",
    path: "/admin/collectes",
  },
  {
    field: "témoignages",
    label: "Témoignages",
    path: "/admin/temoignages",
  },
  {
    field: "necrologies",
    label: "Nécrologies",
    path: "/admin/necrologies",
  },
  {
    field: "messages",
    label: "Messages",
    path: "/admin/messages",
  },
  {
    field: "demandes",
    label: "Demandes",
    path: "/admin/demandes",
  },
  {
    field: "actualites",
    label: "Actualites",
    path: "/admin/actualites",
  },
  {
    field: "cotisations",
    label: "Cotisations",
    path: "/admin/cotisations",
  },
  {
    field: "parametres",
    label: "Parametres",
    path: "/admin/parametres",
  },
];
