import React, { Dispatch, SetStateAction } from "react";
import Modal from "react-bootstrap/Modal";
import { FormError, Input, animatedComponents } from "../../../common/Input";
import useCrudNecrologie from "../useForm/UseCrudNecrologie";
import ErrorMessage from "../../../common/ErrorMessage";
import Select from "react-select";
import { Necrologie } from "../../../../utils/api/necrologie/necrologie.type";

const AddNecrologieModal = ({
  modalAddNecrologie,
  setModalAddNecrologie,
  necrologie,
}: {
  modalAddNecrologie: boolean;
  setModalAddNecrologie: Dispatch<SetStateAction<boolean>>;
  necrologie?: Necrologie;
}) => {
  function closeModalAddNecrologie() {
    setModalAddNecrologie(false);
  }

  const {
    register,
    onSubmit,
    errors,
    handleChangePays,
    previewImage,
    handelImageChange,
    isLoading,
    handleReset,
    selected,
    option,
    options,
    handleSelectParoisse,
    pays,
    Controller,
    control,
  } = useCrudNecrologie(setModalAddNecrologie, necrologie);

  return (
    <Modal
      show={modalAddNecrologie}
      onHide={() => {
        handleReset();
        closeModalAddNecrologie();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      size="lg"
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">
          {necrologie ? "Modifier le décès" : "Ajouter un décès"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row mt-4">
          <form onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Nom"
                  id="nom"
                  placeholder="Nom"
                  {...register("nom")}
                  error={errors?.nom}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="text"
                  label="Prenom"
                  id="Prenom"
                  placeholder="Prenom"
                  {...register("prenom")}
                  error={errors?.prenom}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label form-label-modal-custom">
                  Photo du défunt
                </label>
                {handelImageChange && (
                  <input
                    type="file"
                    id="necro_image"
                    onChange={(e) => handelImageChange(e)}
                    className="form-control form-control-modal-custom"
                    accept="image/*"
                  />
                )}
                {errors?.image && (
                  <ErrorMessage message={errors?.image?.message} />
                )}
                {previewImage && (
                  <div className="preview-image pt-2">
                    <img src={previewImage} alt="image" />
                  </div>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="number"
                  min={0}
                  label="Age"
                  id="Age"
                  placeholder="Age"
                  {...register("age")}
                  error={errors?.age}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <Input
                  type="date"
                  label="Date de nécrologie"
                  id="date_necrologie"
                  placeholder="Date de nécrologie"
                  {...register("date")}
                  error={errors?.date}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label className="form-label form-label-modal-custom">
                  Sexe
                </label>
                <select
                  className="form-control form-control-modal-custom"
                  style={{ appearance: "auto" }}
                  {...register("sexe")}
                >
                  <option value={""}>selectionner</option>
                  <option value="homme">homme</option>
                  <option value="femme">femme</option>
                </select>
              </div>
              <div className="col-md-6 mb-3">
                {/* <label className="form-label form-label-modal-custom">
                  Pays
                </label>
                <select
                  className="form-control form-control-modal-custom"
                  style={{ appearance: "auto" }}
                  {...register("pays")}
                >
                  <option disabled>Pays</option>
                  <option value="Senegal">Senegal</option>
                  <option value="Finlande">Finlande</option>
                </select> */}
                <div className="mb-4">
                  <label
                    htmlFor={"pays"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"pays"}
                  >
                    Pays{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>

                  <Controller
                    name="pays"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={pays}
                          classNamePrefix="react-select-custom"
                          onChange={handleChangePays}
                          value={selected}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.pays} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                {/* <label className="form-label form-label-modal-custom">
                  Paroisse
                </label>
                <select
                  className="form-control form-control-modal-custom"
                  style={{ appearance: "auto" }}
                >
                  <option value="lien">Paroisse</option>
                </select> */}
                <div className="mb-4">
                  <label
                    htmlFor={"paroisse"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"paroisse"}
                  >
                    Paroisse{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>

                  <Controller
                    name="paroisse"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={options}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelectParoisse}
                          value={option}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.paroisse} />
                </div>
              </div>
            </div>
            <div className="flex-r auth-submit-btn-container text-end pt-5">
              <button
                type="submit"
                className="btn btn-theme-admin"
                disabled={isLoading}
              >
                {!isLoading ? (
                  !necrologie ? (
                    "Ajouter"
                  ) : (
                    "Modifier"
                  )
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{necrologie ? "Modification..." : "Ajout..."}</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddNecrologieModal;
