import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { AlertInfo } from "../../../common/Alert";
import TableSkeleton from "../../../common/TableSkeleton";
import { NavLink, useNavigate } from "react-router-dom";
import { useGetMembresOfficePastoralQuery } from "../../../../utils/api/membre/membre.api";
import { CustomPagination } from "../../../common/CustomPagination";
import { getAvatar } from "../../../../utils/Utils";
import { IMembre } from "../../../../utils/api/membre/membre.type";
import { UseDeleteMembre } from "../requestForm/UseCrudOfficePastoral";
import { useAppSelector } from "../../../../redux/hook";
import { isSuperAdmin } from "../../../../routes/routerUtils";

function OfficePastoralTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const { data, isLoading } = useGetMembresOfficePastoralQuery({
    limit: perPage,
    page,
    q_type: "office_pastoral",
  });

  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <NavLink
              to={`/admin/office-pastoral/${row.slug}`}
              className="btn btn-action-modal-icon with-tooltip"
              data-tooltip-content="Voir"
              state={row}
            >
              <AiFillEye />
            </NavLink>
          </div>
          <DeleteMembre item={row} />
        </div>
      </>
    );
  };
  const nameFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex gap-3 align-items-center">
        <div className="content-img-profil-itemt-table">
          <img
            src={getAvatar(row?.avatar)}
            alt="Profil"
            className="img-profil-itemt-table"
          />
        </div>
        <p className="name-profil-patient-table mb-0">
          {row?.prenom + " " + row?.nom}
        </p>
      </div>
    );
  };
  const columns = [
    {
      dataField: "nom",
      text: `Nom`,
      formatter: nameFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: () => {
        return { width: "250px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "telephone",
      text: "Téléphone",
    },
    {
      dataField: "role",
      text: "Fonction",
    },
    {
      dataField: "id",
      text: "Action",
      formatter: actionFormatter,
    },
  ];
  const selectRow = {
    mode: "checkbox",
  };

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            // selectRow={selectRow}
            // rowEvents={rowEvents}
            rowClasses="custom-table-row-class"
            condensed
            hover
            wrapperClasses="table-responsive admin-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvéé" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </>
  );
}

export default OfficePastoralTable;

export function DeleteMembre({ item }: { item: IMembre }) {
  const deleteMembre = UseDeleteMembre(item);
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={deleteMembre}
      >
        <BsTrash3 />
      </button>
    </div>
  );
}
