import React from "react";
import Icon from "../../../../assets/icons/icon.svg"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

function LineChartBanque() {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
    },
  };


  const labels = [
    "Jan", 
    "Fev ", 
    "Mar", 
    "Avr", 
    "Mai", 
    "Juin", 
    "Jui",
    "Aou", 
    "Sep", 
    "Oct", 
    "Nov", 
    "Dec", 
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Patients 2020",
        data: [0, 0.5, 1, 1.4, 1.5, 1, 2, 2.2, 2.8, 3, 3.1, 2.6 ],
        borderColor: "#9203D7",
        backgroundColor: "#a8bfd51f",
        fill: true,
        lineTension: 0.3,
      },
    ],
  };

  return (
    <div className="chart-item-container">
      <div className="content-chart-title mb-2">
        <h2 className="chart-item-admin-title mb-0">
        Cotisation
        </h2>
        <div className="content-btn-date-graph">
          <button className="btn btn-filter-graph active">Année</button>
          <button className="btn btn-filter-graph">Mois</button>
          <button className="btn btn-filter-graph">Semaine</button>
        </div>
      </div>
      <div className="content-tex-progress pb-3">
        <img src={Icon} alt="Graph" className="icon-progress-graph" />
        <span className="text-progress-graph ps-2">+6,79%</span>
      </div>
      <Line options={options} data={data} />
    </div>
  );
}

export default LineChartBanque;
