import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { IMembre, MembreFormData } from "./membre.type";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";

export const MembreApi = createApi({
  reducerPath: "membreApi",
  tagTypes: ["membre", "membreList", "paroisseMembre"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditMembre: builder.mutation<
      IMembre,
      { slug?: string; data: MembreFormData | FormData; field?: string; }
    >({
      invalidatesTags: ["membre", "membreList", "paroisseMembre"],
      query: ({ slug, data, field }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: field === "responsable_paroissien" ? `responsable/create/` : `membre/create/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getMembresOfficePastoral: builder.query<
      PaginationResults<IMembre>,
      TypeQuery
    >({
      providesTags: ["membreList"],
      query: (query) => QueryUrl("office_pastoral/membres/", query),
    }),
    getMembresConseilAdminidtration: builder.query<
      PaginationResults<IMembre>,
      TypeQuery
    >({
      providesTags: ["membreList"],
      query: (query) => QueryUrl("conseil_administration/membres/", query),
    }),
    getMembresConseilPastoral: builder.query<
      PaginationResults<IMembre>,
      TypeQuery
    >({
      providesTags: ["membreList"],
      query: (query) => QueryUrl("conseil_pastoral/membres/", query),
    }),
    getMembresOfParoisse: builder.query<PaginationResults<IMembre>, TypeQuery>({
      providesTags: ["paroisseMembre"],
      query: ({ slug, ...query }) => QueryUrl(`paroisse/${slug}/membres/`, query),
    }),

    deleteMembre: builder.mutation<MembreFormData, string>({
      query: (slug) => ({
        url: `users/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["membre", "membreList", "paroisseMembre"],
    }),
    getMembresResponsableParoissien: builder.query<
    PaginationResults<IMembre>,
    TypeQuery
  >({
    providesTags: ["membreList"],
    query: (query) => QueryUrl("responsables/", query),
  }),

  addChefDiocese: builder.mutation<
      IMembre,
      { slug?: string; data: MembreFormData | FormData; }
    >({
      invalidatesTags: ["membre", "membreList", "paroisseMembre"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `users/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: "chefs_dioceses/",
          method: "POST",
          body: data,
        };
      },
    }),
    getMembresChefsDiocese: builder.query<
    PaginationResults<IMembre>,
    TypeQuery
  >({
    providesTags: ["membre", "membreList", "paroisseMembre"],
    query: (query) => QueryUrl("chefs_dioceses/", query),
  }),
    getFreeChefsDiocese: builder.query<
    PaginationResults<IMembre>,
    TypeQuery
  >({
    providesTags: ["membreList"],
    query: (query) => QueryUrl("free/chefs_dioceses/", query),
  }),
    getAssignedChefsDiocese: builder.query<
    PaginationResults<IMembre>,
    TypeQuery
  >({
    providesTags: ["membreList"],
    query: (query) => QueryUrl("assigned/chefs_dioceses/", query),
  }),

  }),
});

export const {
  useAddOrEditMembreMutation,
  useDeleteMembreMutation,
  useGetMembresOfficePastoralQuery,
  useGetMembresConseilAdminidtrationQuery,
  useGetMembresConseilPastoralQuery,
  useGetMembresOfParoisseQuery,
  useGetMembresResponsableParoissienQuery,
  useAddChefDioceseMutation,
  useGetMembresChefsDioceseQuery,
  useGetFreeChefsDioceseQuery,
  useGetAssignedChefsDioceseQuery
} = MembreApi;
