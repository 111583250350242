import React from 'react'
import ParametreModelesTable from '../ParametreTable/ParametreModelesTable'

function ParametreModeleAdmin({
    modalAddModele,
    setModalAddModele,
    openModalAddModele,
    modalViewModele,
    setModalViewModele,
    openModalViewModele,
  }) {
  return (
    <div className="content-graph-admin">
      <div className="admin-table">
        <ParametreModelesTable
          modalAddModele={modalAddModele}
          setModalAddModele={setModalAddModele}
          openModalAddModele={openModalAddModele}
          modalViewModele={modalViewModele}
          setModalViewModele={setModalViewModele}
          openModalViewModele={openModalViewModele}
        />
      </div>
    </div>
  )
}

export default ParametreModeleAdmin