/* eslint-disable @typescript-eslint/no-use-before-define */
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useForm, Controller } from "react-hook-form";
import {
  FidelesFormData,
  IFideles,
} from "../../../../utils/api/fideles/fideles.type";
import { useGetPaysQuery } from "../../../../utils/api/pays/pays.api";
import { useAddOrEditFideleMutation, useDeleteFideleMutation } from "../../../../utils/api/fideles/fidele.api";
import { useParoisseByPaysQuery } from "../../../../utils/api/paroisses/paroisses.api";
import { useGetGradesFideleQuery } from "../../../../utils/api/grades/grades.api";
import { Data } from "../../../common/GoogleInput";
import { useGetModelesQuery } from "../../../../utils/api/modeles/modeles.api";

function UseCrudFideleForm(fidele?: IFideles) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required().label("Nom"),
    prenom: yup.string().required().label("Prénom"),
    email: yup.string().email().required().label("L'email"),
    telephone: validatePhone(yup, code),
    paroisse_id: yup.string().required().label("La paroisse").nullable(),
    sexe: yup.string().required().label("Le sexe").nullable(),
    pays: yup.string().required().label("Pays").nullable(),
    grade: yup.string().label("Le grade").nullable(),
    adresse: yup.string().required().label("L'adresse").nullable(),
    type_fidele: yup.string().required().label("Le type de fidèle").nullable(),
    modele: yup.string().label("Le model de certificat").nullable(),
    fonction: yup.string().label("La paroisse").nullable(),
    avatar: yup.mixed().nullable().label("Photo"),
    onction: yup.string().label("L'onction'").nullable(),
    nationalite: yup.string().label("La nationalité").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
    setError
  } = useForm<FidelesFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [previewAvatar, setPreviewAvatar] = useState("");
  const [type, setType] = useState<string>("");
  const [address, setAddress] = useState("");
  const [paroisses, setParoisses] = useState<
    { label: string; value: number }[]
  >([]);
  const [selected, setSelected] = useState<{ label: string; value: number }>();
  const [options, setOptions] = useState<{ label: string; value: number }[]>(
    []
  );
  const [optionsModeles, setOptionsModeles] = useState<
  { label: string; value: number, imageUrl: string }[]
>([]);
const [optionModele, setOptionModele] = useState<{
  label: string;
  value: number;
  imageUrl: string
}>();
  const [option, setOption] = useState<{ label: string; value: number }>();
  const [pays, setPays] = useState<{ label: string; value: number; slug?: string }[]>([]);
  const [selectedPays, setSelectedPays] = useState<{ label: string; value: number; slug?: string  }>();
  const [showParoisseError, setShowParoisseError] = useState(false);
  const { data: countries = { results: [] } } = useGetPaysQuery({
    limit: 1000,
  });
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState("");
  const { data = { results: [] }, isLoading: isLoadParoisse } = useParoisseByPaysQuery({
    limit: 1000,
    q: query,
    slug: selectedPays?.slug
  });
 
  const { data: grades = { results: [] } } = useGetGradesFideleQuery({
    limit: 1000,
    q: search,
  });

  const { data: modeles = { results: [] }, isLoading: isLoadCertificat } = useGetModelesQuery({
    limit: 1000,
  });

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditFideleMutation();

  useEffect(() => {
    if (data?.results?.length > 0) {
      setParoisses(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    } else {
      setParoisses([])
      setSelected(undefined);
    }

    if (countries?.results?.length) {
      setPays(
        countries?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
            slug: el?.slug,
          };
        })
      );
    }

    if (grades?.results?.length) {
      setOptions(
        grades?.results?.map((el) => {
          return {
            label: el?.titre,
            value: el?.id,
          };
        })
      );
    }
    if (modeles?.results?.length) {
      setOptionsModeles(
        modeles?.results?.map((el) => {
          return {
            label:
              el?.couleur === "vert"
                ? "Vert"
                : el?.couleur === "bleu"
                ? "Bleu"
                : el?.couleur === "beige"
                ? "Beige"
                : el?.couleur === "violet"
                ? "Violet"
                : "",
            value: el?.id,
            imageUrl: el?.image
          };
        })
      );
    }
  }, [data, grades, countries, modeles]);
  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
  };

  const onSelectPays = (e) => {
    setSelectedPays(e);
    setValue("pays", e?.value);
  };

  const onSelectGrade = (e) => {
    setOption(e);
    setValue("grade", e?.value);
  };

  const onSelectModele = (e) => {
    setOptionModele(e);
    setValue("modele", e?.value);
  };

  const onSelectParoisse = (e) => {
    if (!selectedPays) {
      setShowParoisseError(true);
      return;
    }
    setShowParoisseError(false);
    setSelected(e);
    setValue("paroisse_id", e?.value);
  };

  const handleParoisseFocus = () => {
    if (!selectedPays) {
      setShowParoisseError(true);
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: fidele
          ? "Fidèle modifié avec succès !"
          : "Fidèle ajouté avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        handleReset();
        navigate("/admin/paroisses",{state: "fideles"});
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("l'utilisateur avec cet email existe déjà.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet e-mail existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Error status ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
     console.log("error", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onChangeType = (e) => {
    setType(e?.target?.value)
    setValue("type_fidele", e?.target?.value)
  }

  const onSubmit = async (data: any) => {
    // return console.log("data submitted", data);
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await sendData({ slug: fidele?.slug, data: fd });
  };

  const handelAvatarChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files ? e.target.files[0] : null;

    if (
      file?.type !== "image/jpeg" &&
      file?.type !== "image/png" &&
      file?.type !== "image/jpg" &&
      file?.type !== "image/gif"
    ) {
      return setError("avatar", { message: "Image format is incorrect." });
    }
    if (file) {
      setPreviewAvatar(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);

  const handleReset = () => {
    if (!fidele) {
      reset();
      setOption(undefined);
      setSelected(undefined);
    }
  };

  useEffect(() => {
    if (fidele?.id) {
      let fields: (keyof IFideles)[] = [
        "id",
        "slug",
        "nom",
        "telephone",
        "email",
        "adresse",
        "paroisse_id",
        "grade",
        "type_fidele",
        "prenom",
        "pays",
        "sexe",
        "date_bapteme",
        "date_dernier_grade",
        "date_nomination",
        "onction",
        "modele",
        "fonction",
        "nationalite",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "paroisse_id" && field !== "grade" && field !== "pays" && field !== "modele") {
          if (fidele[field]) setValue(field, fidele[field]);
        } else {
          if (fidele[field]) {
            setValue(field, fidele[field]?.id);
          }
        }
      }
      setType(fidele?.type_fidele)
      if (fidele?.adresse) {
        setAddress(fidele?.adresse);
      }

      if (fidele?.telephone) {
        setPhone(fidele?.telephone);
      }
      if (fidele?.paroisse_id) {
        setSelected({
          label: fidele.paroisse_id?.nom,
          value: fidele.paroisse_id?.id,
        });
      }

      if (fidele?.pays) {
        setSelectedPays({
          label: fidele.pays?.nom,
          value: fidele.pays?.id,
          slug: fidele.pays?.slug,
        });
      }

      if (fidele?.grade) {
        setOption({
          label: fidele.grade?.titre,
          value: fidele.grade?.id,
        });
      }
    }

    if (fidele?.modele) {
      setOptionModele({
        label:
          fidele?.modele?.couleur === "vert"
            ? "Vert"
            : fidele?.modele?.couleur === "bleu"
            ? "Bleu"
            : fidele?.modele?.couleur === "beige"
            ? "Beige"
            : fidele?.modele?.couleur === "violet"
            ? "Violet"
            : "",
        value: fidele?.modele?.id,
        imageUrl: fidele?.modele?.image
      });
    }
  }, [fidele]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    type,
    handleReset,
    paroisses,
    onSelectParoisse,
    selected,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
    control,
    Controller,
    setQuery,
    setSearch,
    isSuccess,
    option,
    options,
    onSelectGrade,
    onChangeType,
    pays,
    selectedPays,
    onSelectPays,
    handelAvatarChange,
    previewAvatar,
    isLoadParoisse,
    optionsModeles,
    optionModele,
    onSelectModele,
    isLoadCertificat,
    handleParoisseFocus,
    showParoisseError
  };
}

export default UseCrudFideleForm;

export function UseDeleteFidele(item: IFideles) {
  const [deleteData] = useDeleteFideleMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce fidèle ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Fidèle supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
