import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl } from "../../Utils";
import { ActiviteFormData, IActivite } from "./activites.type";

export const ActivitesApi = createApi({
  reducerPath: "activitesApi",
  tagTypes: [
    "activites",
    "activitesList",
    "paroisseActivite",
    "evenements_a_venir",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditActivite: builder.mutation<
      IActivite,
      { slug?: string; data: ActiviteFormData | FormData }
    >({
      invalidatesTags: [
        "activites",
        "activitesList",
        "paroisseActivite",
        "evenements_a_venir",
      ],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `activites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `activites/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getActivite: builder.query<PaginationResults<IActivite>, TypeQuery>({
      providesTags: ["activitesList"],
      query: (query) => QueryUrl("activites/", query),
    }),
    getActivitesOfParoisse: builder.query<
      PaginationResults<IActivite>,
      TypeQuery
    >({
      providesTags: ["paroisseActivite"],
      query: ({ slug, ...query }) =>
        QueryUrl(`paroisse/${slug}/activites/`, query),
    }),
    getEventToComeOfParoisse: builder.query<
      PaginationResults<IActivite>,
      TypeQuery
    >({
      providesTags: ["paroisseActivite"],
      query: ({ slug, ...query }) =>
        QueryUrl(`paroisse/${slug}/evenements_a_venir/`, query),
    }),
    deleteActivite: builder.mutation<ActiviteFormData, string>({
      query: (slug) => ({
        url: `activites/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: [
        "activites",
        "activitesList",
        "evenements_a_venir",
        "paroisseActivite",
      ],
    }),
  }),
});

export const {
  useAddOrEditActiviteMutation,
  useDeleteActiviteMutation,
  useGetActiviteQuery,
  useGetActivitesOfParoisseQuery,
  useGetEventToComeOfParoisseQuery
} = ActivitesApi;
