import React from 'react'
import Skeleton from 'react-loading-skeleton'

function ArchiveSkeleton() {
  return (
    <>
      {Array(4)
        .fill(4)
        .map((i) => (
        <div className="col-lg-6 col-md-12 mb-3" key={i}>
              <div className="content-testimonial-item">
                <div className="d-flex align-items-center justify-content-between">
                <Skeleton className="name-user-archive mb-1" height={15} width={150} />
                <Skeleton className="" width={25} height={15} />
                </div>
                <div className="content-body-testimonial pt-3">
                    <Skeleton height={15} className="text-preambule-admin" />
                    <Skeleton height={15} className="text-preambule-admin" />
                    <Skeleton height={15} className="text-preambule-admin" />
                    <Skeleton height={15} className="text-preambule-admin" />
                    <Skeleton
                    width={300}
                    height={15}
                    className="text-preambule-admin"
                    />
                    <Skeleton height={15} className="text-preambule-admin" />
                    <Skeleton
                    width={330}
                    height={15}
                    className="text-preambule-admin"
                    />
                </div>
                <div className="display-info-text-archive">
                  <div className="item-display-info-text-archive">
                    <Skeleton className="text-libelle-info-archive" height={15} width={35} />
                    <Skeleton className="text-value-info-archive" height={15} width={45} />
                   
                  </div>
                  <div className="item-display-info-text-archive">
                    <div className="content-img-pofil-user-archive">
                    <Skeleton className="img-pofil-user-archive" circle />
                    </div>
                    <Skeleton className="text-value-info-archive" height={15} width={100} />
               
                  </div>
                </div>
              </div>
            </div>
        ))}
    </>
  )
}

export default ArchiveSkeleton