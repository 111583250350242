import React, { useState } from "react";
import ParoisseTable from "../tables/ParoisseTable";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsFillFilterSquareFill } from "react-icons/bs";
import { MdAddCircleOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";



const steps = [
  { id: "paroisse-table", Component: ParoisseTable },
//   { id: "add-paroisse-form", Component: AddParoisseForm },
];

function Paroisse() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [query, setQuery] = useState("")

  const props = {
    q: query
  }
  return (
    <>
      <div className="btn-action-and-search d-flex justify-content-between align-items-center pt-4 pb-5 flex-wrap gap-2">
        <div className="btn-actions">
          {index === 0 && (
            <NavLink
              to={"/admin/paroisses/ajouter-une-paroisse"}
              //   onClick={() => go("add-paroisse-form")}
              className="btn custom-add-btn"
            >
              Ajouter une paroisse
              <MdAddCircleOutline className="add-icon" />
            </NavLink>
          )}
          {index === 1 && (
            <button
              onClick={() => go("paroisse-table")}
              className="btn custom-add-btn"
            >
              Retour a la liste
            </button>
          )}
        </div>
        {index === 0 ? (
          <div className="content-form-filter d-flex align-items-center gap-2">
            <form>
              <div className="content-form-search-bloc position-relative">
                <input
                  type="search"
                  className="form-control form-control-search"
                  placeholder="Tapez pour rechercher"
                  onChange={(e) => setQuery(e?.target?.value)}
                />
                <span className="container-icon-searh-form">
                  <BiSearchAlt2 />
                </span>
              </div>
            </form>
            {/* <button className="btn btn-icon-filter">
              <BsFillFilterSquareFill />
            </button> */}
          </div>
        ) : (
          <div className="appareance"></div>
        )}
      </div>

      <div className="tabs-component-paroisse pt-4">
        <Component {...props} />
      </div>
    </>
  );
}
export default Paroisse;
