import React from "react";
import { FiSearch } from "react-icons/fi";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import Profil from "../../../../assets/appImages/patient.png";
import {  Link} from "react-router-dom";

const NewChatModal = ({ modalNewChat, setModalNewChat  }: any) => {

  function closeModalNewChat() {
    setModalNewChat(false);
  }


  return (
    <Modal
      isOpen={modalNewChat}
      onRequestClose={closeModalNewChat}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="ChangePasswordModalLabel">
          Nouvelle discussion
        </h5>
        <CloseModalBtnV2 onClick={closeModalNewChat} />
      </div>
      {/* <div className="content-btn-send-message flex-r">
        <button className="btn btn-send-message-modal disabled">Suivant</button>
      </div> */}
      <div className="pt-3">
        <div className="form-search-user-container position-relative">
          <input
            type="text"
            className="form-search-user form-control"
            placeholder="Rechercher des personnes"
          />
          <FiSearch
            style={{
              color: "#919EAB",
              fontSize: 22,
              position: "absolute",
              top: "25%",
              left: "2%",
            }}
          />
        </div>
      </div>
      <ul className="user-for-send-message-container mt-3  px-2">

          <li
          className="user-for-send-message  mb-3 px-3 py-1"
          data-bs-dismiss="modal"
          // onClick={() => onCreateOrShowConversation(user?.id, item?.id)}
          >
            <Link
                to={`/admin/message/:slug`}
                className="no-link"
              >
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img
                      src={Profil}
                      alt="user-avatar"
                      className="w-fluid img-profil-user-message"
                    />
                  </div>
                  <div className="user-for-send-message-infos">
                    <h3 className="mb-0">Adama Diakhaté</h3>
                    <h4 className="mb-0">Administrateur</h4>
                  </div>
                </div>
              </Link>
          </li>
        <li
          className="user-for-send-message  mb-3 px-3 py-1"
          data-bs-dismiss="modal"
          >
          <div className="d-flex align-items-center gap-2">
            <div>
              <img
                src={Profil}
                alt="user-avatar"
                className="w-fluid img-profil-user-message"
              />
            </div>
            <div className="user-for-send-message-infos">
              <h3 className="mb-0">Serena Doutsona</h3>
              <h4 className="mb-0">Online - Last seen, 2.02pm</h4>
            </div>
          </div>
        </li>
        <li
          className="user-for-send-message  mb-3 px-3 py-1"
          data-bs-dismiss="modal"
        >
          <div className="d-flex align-items-center gap-2">
            <div>
              <img
                src={Profil}
                alt="user-avatar"
                className="w-fluid img-profil-user-message"
              />
            </div>
            <div className="user-for-send-message-infos">
              <h3 className="mb-0">Serena Doutsona</h3>
              <h4 className="mb-0">Online - Last seen, 2.02pm</h4>
            </div>
          </div>
        </li>
        <li
          className="user-for-send-message  mb-3 px-3 py-1"
          data-bs-dismiss="modal"
        >
          <div className="d-flex align-items-center gap-2">
            <div>
              <img
                src={Profil}
                alt="user-avatar"
                className="w-fluid img-profil-user-message"
              />
            </div>
            <div className="user-for-send-message-infos">
              <h3 className="mb-0">Serena Doutsona</h3>
              <h4 className="mb-0">Online - Last seen, 2.02pm</h4>
            </div>
          </div>
        </li>
        <li
          className="user-for-send-message  mb-3 px-3 py-1"
          data-bs-dismiss="modal"
        >
          <div className="d-flex align-items-center gap-2">
            <div>
              <img
                src={Profil}
                alt="user-avatar"
                className="w-fluid img-profil-user-message"
              />
            </div>
            <div className="user-for-send-message-infos">
              <h3 className="mb-0">Serena Doutsona</h3>
              <h4 className="mb-0">Online - Last seen, 2.02pm</h4>
            </div>
          </div>
        </li>
      </ul>
    </Modal>
  );
};

export default NewChatModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    height:"80%",
    zIndex: 99999,
  },
};
