import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiFillEye } from "react-icons/ai";
import { BsTrash3 } from "react-icons/bs";
import { useGetListThemeQuery } from "../../../../utils/api/theme/theme.api";
import { MdEdit } from "react-icons/md";
import { Theme } from "../../../../utils/api/theme/theme.type";
import TableSkeleton from "../../../common/TableSkeleton";
import { AlertInfo } from "../../../common/Alert";
import { CustomPagination } from "../../../common/CustomPagination";
import CrudThemeModal from "../modal/CrudThemeModal";
import { showModal } from "../../../../utils/Utils";
import { UseDeleteTheme } from "../form/UseCrudThemeForm";

function DeleteBtn({ slug }: { slug: string }) {
    const onDelete = UseDeleteTheme(slug);

  return (
    <button
      className="btn with-tooltip btn-action-icon-delete"
      data-tooltip-content="Supprimer"
      style={{ color: "#E53E3E" }}
        onClick={() => onDelete()}
    >
      <BsTrash3 />
    </button>
  );
}
function ThemeTable() {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const { data, isLoading } = useGetListThemeQuery({
    limit: perPage,
    page,
  });

  const nameFormatter = (cell: string) => {
    return (
      <div className="content-cover-paroisse-and-name d-flex gap-3 align-items-center">
        <span className="custom-paroisse-name mb-0">{cell}</span>
      </div>
    );
  };

  const actionFormatter = (cell: string, row: Theme) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex gap-3">
          <div className="container-btn-action-icon-modal">
            <button
              className="btn btn-action-icon-edit with-tooltip"
              data-tooltip-content="Modifier"
              onClick={() => showModal(`editThemeModal${cell}`)}
            >
              <MdEdit />
            </button>
          </div>
          <div className="container-btn-action-icon-modal">
            <DeleteBtn slug={cell} />
          </div>
        </div>
        <CrudThemeModal modalId={`editThemeModal${cell}`} item={row} />
      </>
    );
  };

  const columns = [
    {
      dataField: "titre",
      text: "Titre",
      formatter: nameFormatter,
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: actionFormatter,
      headerStyle: () => {
        return { width: "150px", whiteSpace: "normal" };
      },
    },
  ];
  return (
    <div>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results}
            columns={columns}
            condensed
            hover
            wrapperClasses="table-responsive custom-eg-table"
            noDataIndication={() => (
              <AlertInfo message="Aucune donnée trouvée" />
            )}
          />
          <div className="custom-pagination-container">
            <CustomPagination
              nbPages={data?.count}
              page={page}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
              perPage={perPage}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default ThemeTable;
