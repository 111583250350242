import React from "react";
import { Theme } from "../../../../utils/api/theme/theme.type";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import { Input } from "../../../common/Input";
import UseCrudThemeForm from "../form/UseCrudThemeForm";

function CrudThemeModal({ item, modalId }: { item?: Theme; modalId: string }) {
  const { register, errors, onSubmit, isLoading, handleReset } = UseCrudThemeForm(
    modalId,
    item
  );
  return (
    <div
      className="modal fade custom-modal"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id={`${modalId}Label`}>
              {item ? "Modifier" : "Ajouter"} un thème
            </h1>
            <CloseModalBtnV2 onClick={() => handleReset()} />
          </div>
          <div className="modal-body">
            <div className="row">
              <form className="mt-4" onSubmit={onSubmit}>
                <div className="row row-add-student my-3">
                  <div className="col-md-12 mb-3">
                    <Input
                      type="text"
                      label="Titre"
                      id="titre"
                      placeholder="Titre"
                      {...register("titre")}
                      error={errors?.titre}
                      required
                    />
                  </div>

                  <div className="flex-r auth-submit-btn-container text-end pt-5">
                    <button
                      type="submit"
                      className="btn btn-theme-admin"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>Enregistrement...</span>
                        </>
                      ) : (
                        "Enregistrer"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrudThemeModal;
