import React from "react";
import Modal from "react-bootstrap/Modal";
import { FormError, Input, animatedComponents } from "../../../common/Input";
import { IPays } from "../../../../utils/api/pays/pays.type";
import UseCrudPays from "../requestForm/UseCrudPays";
import GoogleInput from "../../../common/GoogleInput";
import Select from "react-select";

const AddPaysModal = ({
  modalAddPays,
  setModalAddPays,
  pays,
  setPays,
}: {
  modalAddPays: any;
  setModalAddPays: any;
  pays?: IPays;
  setPays?: any;
}) => {
  function closeModalAddPays() {
    setModalAddPays(false);
  }

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handleReset,
    handleChangeAddress,
    address,
    handleSelectResponsable,
    Controller,
    control,
    option,
    options,
  } = UseCrudPays(setModalAddPays, pays, setPays);
  return (
    <Modal
      show={modalAddPays}
      onHide={() => {
        handleReset();
        closeModalAddPays();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
      // size="lg"
      centered
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">
          {pays ? "Modifier le pays" : "Nouveau pays"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="mt-4" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Nom{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>

                  <GoogleInput
                    placeholder="Nom"
                    types={["country"]}
                    className={`form-control form-control-modal-custom`}
                    value={address}
                    onChange={handleChangeAddress}
                  />
                  <FormError error={errors?.nom?.message} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Latitude"
                  id="latitude"
                  placeholder="Latitude"
                  {...register("latitude")}
                  error={errors.latitude}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Longitude"
                  id="longitude"
                  placeholder="Longitude"
                  {...register("longitude")}
                  error={errors.longitude}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <div className="mb-4">
                  <label
                    htmlFor={"paroisse"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"paroisse"}
                  >
                    Responsable{" "}
                      <span className="text-danger" style={{ fontSize: 15 }}>
                        *
                      </span>
                  </label>

                  <Controller
                    name="responsable"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          // isMulti
                          options={options}
                          classNamePrefix="react-select-custom"
                          onChange={handleSelectResponsable}
                          value={option}
                        />
                      );
                    }}
                  />
                  <FormError error={errors.responsable} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div className="mb-4">
                  <Input
                    type="text"
                    label="Adjoint"
                    id="adjoint"
                    placeholder="Adjoint"
                    {...register("adjoint")}
                    error={errors.adjoint}
                  />
                </div>
              </div>
              <div className="col-md-6 offset-md-6 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>{pays ? "Modification..." : "Ajout..."}</span>
                    </>
                  ) : pays ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPaysModal;
