import React, { useState } from "react";
import { Input } from "../../../common/Input";
import { CiEdit } from "react-icons/ci";
import { useAppSelector } from "../../../../redux/hook";
import UseCrudProfil from "../requestForm/UseCrudProfil";

function InfosPersonnelles() {
  const { user } = useAppSelector((state) => state.user);

  const { register, onSubmit, errors, isLoading, setValue } = UseCrudProfil();
  return (
    <div className="medsain-profil-tab-item">
      <div className="medsain-profil-tab-title">Information générale</div>
      <form className="mt-4" onSubmit={onSubmit}>
        <div className="row row-add-student my-3">
          <div className="col-md-4 position-relative">
            <Input
              type="text"
              label="Prénom"
              id="prenom"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("prenom")}
              error={errors?.prenom}
            />
            <span className="icon-edit-info-profil">
              <CiEdit />
            </span>
          </div>
          <div className="col-md-4 position-relative">
            <Input
              type="text"
              label="Nom"
              id="nom"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("nom")}
              error={errors?.nom}
            />
            <span className="icon-edit-info-profil">
              <CiEdit />
            </span>
          </div>
          <div className="col-md-4 position-relative">
            <Input
              type="text"
              label="Téléphone"
              id="telephone"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("telephone")}
              error={errors?.telephone}
            />
            <span className="icon-edit-info-profil">
              <CiEdit />
            </span>
          </div>
          <div className="col-md-6 position-relative">
            <Input
              type="email"
              label="Email"
              id="email"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("email")}
              error={errors?.email}
            />
            <span className="icon-edit-info-profil">
              <CiEdit />
            </span>
          </div>
          <div className="col-md-6 position-relative">
            <Input
              type="text"
              label="Adresse"
              id="adresse"
              customClassLabel={"input-modal-label-form-add"}
              customClassInput="input-modal-form-add"
              {...register("adresse")}
              error={errors?.adresse}
            />
            <span className="icon-edit-info-profil">
              <CiEdit />
            </span>
          </div>
          <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-5">
            <button
              className="btn btn-theme-admin"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Mis à jour...</span>
                </>
              ) : (
                "Mettre à jour"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default InfosPersonnelles;
