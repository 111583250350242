import React from "react";
import BreadCrumb from "../../shared/BreadCrumb";
import { FormError, Input } from "../../common/Input";
import MyDropzone, { ImageItem } from "../../common/MyDropzone";
import { useCeremonieFromLocation } from "../../../utils/api/ceremonies/ceremonies.api";
import UseAddOrEditCeremonieForm from "./form/UseAddOrEditCeremonieForm";
import { NavLink } from "react-router-dom";

function AddOrUpdateCeremonie() {
  const [item] = useCeremonieFromLocation();
  const { register, errors, onSubmit, isLoading, image, setImage } =
    UseAddOrEditCeremonieForm(item);

  return (
    <>
      <div className="dashbord-admin-component">
        <div className="dash-admin-page-content-container mb-3">
          {!!item ? (
            <BreadCrumb
              pageName="Cérémonies"
              secondPage="Modifier une cérémonie"
            />
          ) : (
            <BreadCrumb pageName="Cérémonies" secondPage="Nouvelle cérémonie" />
          )}
        </div>
        <div className="btn-action-container">
          <span className="egs__text">
            {!item ? "Ajouter" : "Modifier"} une cérémonie
          </span>
        </div>
        <div className="form-content mt-2 bg-white p-3 pb-5">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Input
                  label="Titre"
                  type="text"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors?.titre}
                  required
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Lien"
                  type="url"
                  placeholder="Lien"
                  {...register("lien")}
                  error={errors?.lien}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Definir une bannière{" "}
                  <span className="text-danger" style={{ fontSize: 15 }}>
                    *
                  </span>
                </label>
                <MyDropzone
                  setImages={setImage}
                  acceptFile={{
                    "image/jpeg": [],
                    "image/png": [],
                    "image/jpg": [],
                    "image/gif": [],
                  }}
                  maxFile={1}
                />
                {image || item?.image ? (
                  <ImageItem image={image || item?.image} />
                ) : null}
                <FormError error={errors?.image} />
              </div>
            </div>
            <div className="col-md-4 offset-md-8 auth-submit-btn-container text-end pt-3">
              <NavLink
                className="btn btn-theme-cancel w-100 no-link"
                to={"/admin/ceremonies"}
              >
                Retour
              </NavLink>
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{item ? "Modification..." : "Ajout..."}</span>
                  </>
                ) : item ? (
                  "Modifier"
                ) : (
                  "Ajouter"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddOrUpdateCeremonie;
