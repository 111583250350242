import React, { useEffect, useState } from "react";
import { useGetConditionsQuery } from "../../../../utils/api/contenu/condition.api";
import { AlertInfo } from "../../../common/Alert";
import { createMarkup } from "../../../../utils/Utils";
import { BiEditAlt } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { IoMdTrash } from "react-icons/io";
import { UseDeleteCondition } from "../requestForm/UseCrudCGU";
import { ICondition } from "../../../../utils/api/contenu/condition.type";

function ConditionsUtilisation() {
  const [cgu, setCgu] = useState<any>();
  const { data, isLoading } = useGetConditionsQuery({});
  useEffect(() => {
    let filterdeData = data?.results?.filter(
      (item) => item?.type === "cgu"
    );
    setCgu(filterdeData);
  }, [data]);
  return (
    <div className="content-graph-admin">
      <ol>
      {cgu?.length === 0 && <AlertInfo message='Pas de données' />}
      {cgu?.map(condition => (
        <li key={condition?.id} className="mb-5 cond-utilisation-ol-custom-li">
          <span>
            {condition?.titre && condition?.titre}
          </span>
          <div className="cond-utilisation-ol-item-container">
            <p className="text-cgu-admin pb-2"
            dangerouslySetInnerHTML={createMarkup(condition?.text)}
            />
          </div>
          <div className='eglise-cond-utilisation-ol-btn-container'>
            <NavLink
                to="/admin/ajouter-conditions-utilisations"
                className='btn eglise-cond-utilisation-ol-btn-edit'
                state={condition}
            >
                <BiEditAlt />
            </NavLink>
            <DeleteCondition item={condition} />
        </div>
        </li>
      ))}
     
        
      </ol>
    </div>
  );
}

export default ConditionsUtilisation;

export function DeleteCondition({ item }: { item: ICondition }) {
	const deleteCondition = UseDeleteCondition(item);
	return (
        <button 
            className='btn eglise-cond-utilisation-ol-btn-delete'
            onClick={deleteCondition}
        >
            <IoMdTrash />
        </button>
	);
}

export const Datas = [
  {
    id: 1,
    title: "Introduction",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio ."
  },
  {
    id: 1,
    title: "Introduction",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio ."
  },
  {
    id: 1,
    title: "Introduction",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio ."
  },
  {
    id: 1,
    title: "Introduction",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisis neque id volutpat vulputate ante. Accumsan pharetra, elementum amet tempor ante. Tristique scelerisque iaculis donec pellentesque convallis sed. Mattis cursus sed gravida turpis lacus, amet, ut est. Elementum pellentesque bibendum Egestas nibh ac sit pretium. Sed diam sit dignissim et facilisis. Bibendum sit dictumst pharetra felis. Nulla euismod porttitor quisque sed ipsum dictum nunc diam. Tortor nulla odio amet nunc in. Lorem faucibus ridiculus integer aliquet massa ac consectetur amet. Aliquam adipiscing congue pellentesque eu euismod ut mollis dolor auctor. Integer et interdum eget et. Sem bibendum quis faucibus enim a. Habitasse at egestas dolor mauris fusce. Maecenas scelerisque donec commodo ullamcorper non. Lobortis odio ."
  },
]