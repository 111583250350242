import React, { useEffect } from "react";
import Modal from "react-modal";
import { CloseModalBtnV2 } from "../../../shared/CloseModalBtn";
import { INotification } from "../../../../utils/api/notification/notification.type";
import UseAddOrUpdateNotification from "../requestForm/UseAddOrUpdateNotification";

const ShowNotificationModal = ({
  modalNotification,
  setModalNotification,
  notification,
}: {
  modalNotification: any;
  setModalNotification: any;
  notification?: INotification;
}) => {
  function closeModalNotification() {
    setModalNotification(false);
  }

  const { handleChangeStatutNotif } = UseAddOrUpdateNotification();

  useEffect(() => {
    if (notification) {
      handleChangeStatutNotif(notification);
    }
  }, [notification]);

  return (
    <Modal
      isOpen={modalNotification}
      onRequestClose={closeModalNotification}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-header pe-2 py-1">
        <h5 className="modal-title" id="ChangePasswordModalLabel">
          Detail d'une notification
        </h5>
        <CloseModalBtnV2 onClick={closeModalNotification} />
      </div>
      <div className="pt-3">
        <div className="row">
          <div className="col-md-12">
            <label
              htmlFor="content"
              className="form-label form-label-modal-custom"
              aria-labelledby="description"
            >
              Description
            </label>
            {notification?.content}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShowNotificationModal;
export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    zIndex: 99999,
  },
};
