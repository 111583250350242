import React from "react";
import Skeleton from "react-loading-skeleton";

function ReunionSkeleton() {
  return (
    <>
      {Array(3)
        .fill(3)
        .map((i) => (
          <div className="content-notif-meeting-item mb-3" key={i}>
            <div className="content-text-notif-meeting">
              <Skeleton
                className="text-date-meeting-notif mb-1"
                height={15}
                width={150}
              />
              <Skeleton
                className="text-bold-date-meeting-notif mb-0"
                height={15}
                width={150}
              />
            </div>
            <div className="content-icon-notif-meeting align-items-end">
              <Skeleton
                className="text-date-meeting-notif mb-1"
                height={25}
                width={25}
              />
              <Skeleton
                height={15}
                width={15}
              />
            </div>
          </div>
        ))}
    </>
  );
}

export default ReunionSkeleton;
