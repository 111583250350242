import React from "react";
import { NavLink } from "react-router-dom";
import { GiDeadlyStrike } from "react-icons/gi";
import { RxDashboard } from "react-icons/rx";
import { CiLocationOn, CiMail } from "react-icons/ci";
import { AiTwotoneSetting } from "react-icons/ai";
import { IoDocumentTextOutline, IoLogOutOutline } from "react-icons/io5";
import Logo from "../../../../assets/appImages/logo-nav.png";
import { BsFileEarmarkText, BsInfoCircle } from "react-icons/bs";
import { FiUsers } from "react-icons/fi";
import { MdAssignmentAdd, MdOutlineChurch } from "react-icons/md";
import { RiMoneyPoundCircleLine } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { onlogout } from "../../../../redux/slice/User.slice";
import { hasAccess } from "../../../../utils/Utils";
function SidebarAdmin() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((s) => s?.user);
  const onLogout = () => {
    dispatch(onlogout());
    // window.location.href = "/";
  };
  return (
    <div className="navbar-vertical-customisation-v2 no-view-mobile navbar-vertical-customisation-admin">
      <div className="hoverable mt-3" id="scrollbar">
        <div className="content">
          <div className="move-left-side-menu"></div>
          <div className="container-logo-sidebar-nav">
            <img src={Logo} alt="Logo" className="logo-sidebar-nav" />
          </div>
          <ul className="nav nav-items-custom flex-column">
            <li className="nav-item nav-item-vertical-custom">
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/dashboard"
                data-tooltip-content="Dashboard"
              >
                <span className="icon-container-nav-link-vertival">
                  <RxDashboard />
                </span>
                <span className="hiddenable-navlink-label">Dashboard</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "preambules")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/preambule"
                data-tooltip-content="Préambule"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">Préambule</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "contitution_bleues")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/constitution-bleu"
                data-tooltip-content="Constitution bleue"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">
                  Constitution bleue
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "pasteurs")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/pasteurs"
                data-tooltip-content="Pasteurs"
              >
                <span className="icon-container-nav-link-vertival">
                  <FiUsers />
                </span>
                <span className="hiddenable-navlink-label">Pasteurs</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "conseil_pastoral")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/conseil-pastoral"
                data-tooltip-content="Conseil pastoral"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">
                  Conseil pastoral
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "conseil_administration")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/conseil-administration"
                data-tooltip-content="Conseil d’administration"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">
                  Conseil d’administration
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "office_pastoral")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/office-pastoral"
                data-tooltip-content="Office pastoral"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">
                  Office pastoral
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "responsable_paroissien")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/responsable-paroissien"
                data-tooltip-content="Responsable paroissial"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">
                  Responsable paroissial
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "responsable_diocesien")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/responsable-dioceses"
                data-tooltip-content="Responsable diocèsien"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">
                  Responsable diocèsien
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "country")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/pays"
                data-tooltip-content="Pays"
              >
                <span className="icon-container-nav-link-vertival">
                  <CiLocationOn />
                </span>
                <span className="hiddenable-navlink-label">Pays</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "paroisses")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/paroisses"
                data-tooltip-content="Paroisses"
              >
                <span className="icon-container-nav-link-vertival">
                  <MdOutlineChurch />
                </span>
                <span className="hiddenable-navlink-label">Paroisses</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "affectations")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/affectation"
                data-tooltip-content="Affectation"
              >
                <span className="icon-container-nav-link-vertival">
                  <MdAssignmentAdd />
                </span>
                <span className="hiddenable-navlink-label">Affectation</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "formations")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/formations"
                data-tooltip-content="Formations"
              >
                <span className="icon-container-nav-link-vertival">
                  <BsInfoCircle />
                </span>
                <span className="hiddenable-navlink-label">Formations</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "ceremonies")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/ceremonies"
                data-tooltip-content="Cérémonies"
              >
                <span className="icon-container-nav-link-vertival">
                  <BsInfoCircle />
                </span>
                <span className="hiddenable-navlink-label">Cérémonies</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "documentations")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/documentations"
                data-tooltip-content="Documentations"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">Documentations</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "actualites")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/actualites"
                data-tooltip-content="Actualités"
              >
                <span className="icon-container-nav-link-vertival">
                  <IoDocumentTextOutline />
                </span>
                <span className="hiddenable-navlink-label">Actualités</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "carnet_roses")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/carnet-rose"
                data-tooltip-content="Carnet rose"
              >
                <span className="icon-container-nav-link-vertival">
                  <BsFileEarmarkText />
                </span>
                <span className="hiddenable-navlink-label">Carnet rose</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "objet_religieux")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/objets-religieux"
                data-tooltip-content="Objets religieux"
              >
                <span className="icon-container-nav-link-vertival">
                  <BsFileEarmarkText />
                </span>
                <span className="hiddenable-navlink-label">
                  Objets religieux
                </span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "finances")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/finances"
                data-tooltip-content="Finances"
              >
                <span className="icon-container-nav-link-vertival">
                  <RiMoneyPoundCircleLine />
                </span>
                <span className="hiddenable-navlink-label">Finances</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "collectes")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/collectes"
                data-tooltip-content="Collectes"
              >
                <span className="icon-container-nav-link-vertival">
                  <RiMoneyPoundCircleLine />
                </span>
                <span className="hiddenable-navlink-label">Collectes</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "temoignages")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/temoignages"
                data-tooltip-content="Témoignages"
              >
                <span className="icon-container-nav-link-vertival">
                  <BsInfoCircle />
                </span>
                <span className="hiddenable-navlink-label">Témoignages</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "necrologies")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/necrologies"
                data-tooltip-content="Nécrologies"
              >
                <span className="icon-container-nav-link-vertival">
                  <GiDeadlyStrike />
                </span>
                <span className="hiddenable-navlink-label">Nécrologies</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "messages")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/messages"
                data-tooltip-content="Messages"
              >
                <span className="icon-container-nav-link-vertival">
                  <CiMail />
                </span>
                <span className="hiddenable-navlink-label">Messages</span>
              </NavLink>
            </li>
            <li
              className="nav-item nav-item-vertical-custom"
              hidden={hasAccess(user, "parametres")}
            >
              <NavLink
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                to="/admin/parametres"
                data-tooltip-content="Paramètres"
              >
                <span className="icon-container-nav-link-vertival">
                  <AiTwotoneSetting />
                </span>
                <span className="hiddenable-navlink-label">Paramètres</span>
              </NavLink>
            </li>
            <li className="nav-item nav-item-vertical-custom">
              <button
                className="nav-link nav-link-vertival-custom-admin with-tooltip-menu"
                data-tooltip-content="Déconnexion"
                onClick={() => onLogout()}
              >
                <span className="icon-container-nav-link-vertival">
                  <IoLogOutOutline />
                </span>
                <span className="hiddenable-navlink-label">Déconnexion</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
