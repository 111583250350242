import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { QueryUrl } from "../../Utils";
import { GradesFormData, IGrades } from "./grades.type";
import { PaginationResults, TypeQuery } from "../user/user.type";

export const GradesApi = createApi({
  reducerPath: "gradesApi",
  tagTypes: ["grades", "gradesList", "fideleGrades", "clergeGrades"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditGrades: builder.mutation<
      IGrades,
      { slug?: string; data: GradesFormData | FormData }
    >({
      invalidatesTags: ["grades", "gradesList", "fideleGrades", "clergeGrades"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `grades/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `grades/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getGrades: builder.query<PaginationResults<IGrades>, TypeQuery>({
      providesTags: ["gradesList"],
      query: (query) => QueryUrl("grades/", query),
    }),
    getGradesFidele: builder.query<PaginationResults<IGrades>, TypeQuery>({
      providesTags: ["fideleGrades"],
      query: (query) => QueryUrl("fidele/grades/", query),
    }),
    getGradesClerge: builder.query<PaginationResults<IGrades>, TypeQuery>({
      providesTags: ["clergeGrades"],
      query: (query) => QueryUrl("clerge/grades/", query),
    }),

    deleteGrades: builder.mutation<GradesFormData, string>({
      query: (slug) => ({
        url: `grades/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["grades", "gradesList"],
    }),
  }),
});

export const {
  useAddOrEditGradesMutation,
  useDeleteGradesMutation,
  useGetGradesQuery,
  useGetGradesClergeQuery,
  useGetGradesFideleQuery
} = GradesApi;
