import React, { useState } from "react";
import { IParoisse } from "../../../../../utils/api/paroisses/paroisses.type";
import { useGetMembresOfParoisseQuery } from "../../../../../utils/api/membre/membre.api";
import { AlertInfo } from "../../../../common/Alert";
import { formatDate, getAvatar } from "../../../../../utils/Utils";
import { CustomPagination } from "../../../../common/CustomPagination";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hook";
import {
  isResponsableParoissien,
  isSuperAdmin,
} from "../../../../../routes/routerUtils";
import { MdAddCircleOutline, MdOutlineModeEdit } from "react-icons/md";
import { UseDeleteMembre } from "../../../OfficePastoral/requestForm/UseCrudOfficePastoral";
import { IMembre } from "../../../../../utils/api/membre/membre.type";
import { BsTrash3 } from "react-icons/bs";
import { IFideles } from "../../../../../utils/api/fideles/fideles.type";

function DeleteMembre({ item }: { item: IMembre | IFideles }) {
  const deleteMembre = UseDeleteMembre(item);

  const handleDeleteClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    deleteMembre();
  };

  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={handleDeleteClick}
      >
        <BsTrash3 />
      </button>
    </div>
  );
}

const MembreCard = ({ item }: { item: any }) => {
  return (
    <NavLink
      to={`/admin/paroisses/detail-fidele/${item.slug}`}
      state={item}
      className="custom-membre-card "
    >
      <span className="membre-cover-container">
        <img src={getAvatar(item?.avatar)} alt="photo" srcSet="" />
      </span>
      <span className="name">{item?.prenom + " " + item?.nom}</span>
      <span className="role">{item?.fonction}</span>
      <div className="separator"></div>
      <span className="start-date pt-4">
        Date de début:{" "}
        <strong>
          {!!item?.date_nominationn && formatDate(item?.date_nomination)}
        </strong>
      </span>
      <span className="grade pt-4">
        Grade: <strong>{item?.grade?.titre}</strong>
      </span>
    </NavLink>
  );
};

function Membre({ paroisse }: { paroisse: IParoisse }) {
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const is_Responsable = useAppSelector((s) =>
    isResponsableParoissien(s.user?.user)
  );
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(9);
  const { data = { results: [], count: 0 }, isLoading } =
    useGetMembresOfParoisseQuery({
      slug: paroisse?.slug,
      page,
      limit,
    });
  return (
    <div className="row row-paroisse-members">

    {!!is_SuperAdmin || !!is_Responsable ? (
        <div className="d-flex justify-content-end mb-3">
          <NavLink
            to={`/admin/paroisses/${paroisse?.slug}/ajouter-un-member`}
            className="btn custom-btn-add-activity"
            // state={{
            //   paroisse,
            // }}
            state={"paroisses"}
          >
            <MdAddCircleOutline className="add-icon" />
            Ajouter un membre
          </NavLink>
        </div>
      ) : null}
      {!!isLoading && <p>chargement...</p>}
      {!isLoading && data?.results?.length ? (
        data?.results.map((membre) => (
          <div
            className="col-md-4 membre-card-wrapper mb-3 position-relative"
            key={membre?.id}
          >
            <MembreCard item={membre} />
            <div className="custom-pos-btn-actions">
              <NavLink
                to={`/admin/paroisses/${paroisse?.slug}/modifier-un-member`}
                className="btn btn-action-modal-icon with-tooltip"
                data-tooltip-content="Modifier"
                state={membre}
              >
                <MdOutlineModeEdit />
              </NavLink>
              <DeleteMembre item={membre} />
            </div>
          </div>
        ))
      ) : (
        <div className="py-4">
          <AlertInfo message="Aucune donnée trouvée" />
        </div>
      )}
      <div className="custom-pagination-container">
        <CustomPagination
          nbPages={data?.count}
          page={page}
          onChange={(page, perPage) => {
            setLimit(perPage);
            setPage(page);
          }}
          perPage={limit}
        />
      </div>
    </div>
  );
}

export default Membre;
