import React, { useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../utils/type";
import BreadCrumb from "../../shared/BreadCrumb";
import { NavLink } from "react-router-dom";
import ConditionsUtilisation from "./ParametreTabs/ConditionsUtilisation";
import "./ParametreAdmin.css";
import PolitiquesConfidentialite from "./ParametreTabs/PolitiquesConfidentialite";
import ParametreGradesAdmin from "./ParametreTabs/ParametreGradesAdmin";
import AddGradeModal from "./modals/AddGradeModal";
import SousAdmin from "./ParametreTabs/SousAdmin";
import ParametreModeleAdmin from "./ParametreTabs/ParametreModeleAdmin";
import AddModeleModal from "./modals/AddModeleModal";

const steps = [
  { id: "admin", Component: SousAdmin },
  { id: "condition-utilisation", Component: ConditionsUtilisation },
  { id: "politique-confidentialite", Component: PolitiquesConfidentialite },
  { id: "grade", Component: ParametreGradesAdmin },
  { id: "modele", Component: ParametreModeleAdmin },
];

const tabs = [
  "Administrateurs",
  "Conditions générales  d’utilisation",
  "Politique de confidentialité",
  "Grades",
  "Modèles certificat",
];

function ParametreAdmin() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [modalAddGrade, setModalAddGrade] = useState(false);
  const [modalAddModele, setModalAddModele] = useState(false);
  const [modalViewModele, setModalViewModele] = useState(false);

  function openModalAddGrade() {
    setModalAddGrade(true);
  }
  function openModalAddModele() {
    setModalAddModele(true);
  }
  function openModalViewModele() {
    setModalViewModele(true);
  }
  const props = {
    modalAddGrade,
    setModalAddGrade,
    openModalAddGrade,
    modalAddModele,
    setModalAddModele,
    openModalAddModele,
    modalViewModele, 
    setModalViewModele,
    openModalViewModele
  };
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Paramètres" />
        </div>
        <div className="content-graph-admin medsain-page-title-container mb-2">
          <ul className="tabs-container tabs-container-admin mb-0 pt-4 pb-2 px-4">
            {tabs.map((tab: any, key: any) => (
              <li
                className={`tab-item  ${
                  index === key && "tab-active-item-admin"
                }`}
                key={key}
                onClick={() => go(key)}
                role="button"
              >
                {tab}
              </li>
            ))}
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="content-graph-admin medsain-page-title-container mb-2">
              {index === 0 ? (
                <div className="container-display-title-theme">
                  <NavLink
                    to="/admin/ajouter-sous-admin"
                    className="btn btn-theme-admin"
                  >
                    <span>Ajouter</span>
                  </NavLink>
                </div>
              ) : index === 1 ? (
                <div className="container-display-title-theme">
                  <NavLink
                    to="/admin/ajouter-conditions-utilisations"
                    className="btn btn-theme-admin"
                  >
                    <span>Ajouter</span>
                  </NavLink>
                </div>
              ) : index === 2 ? (
                <div className="container-display-title-theme">
                  <NavLink
                    to="/admin/ajouter-politique-confidentialite"
                    className="btn btn-theme-admin"
                  >
                    <span>Ajouter</span>
                  </NavLink>
                </div>
              ) : index === 3 ? (
                <div className="container-display-title-theme">
                  <button
                    onClick={(e) => openModalAddGrade()}
                    className="btn btn-theme-admin"
                  >
                    <span>Ajouter un grade</span>
                  </button>
                  <AddGradeModal
                    modalAddGrade={modalAddGrade}
                    setModalAddGrade={setModalAddGrade}
                  />
                </div>
              ) : (
                <div className="container-display-title-theme">
                  <button
                    onClick={(e) => openModalAddModele()}
                    className="btn btn-theme-admin"
                  >
                    <span>Ajouter un modele</span>
                  </button>
                  <AddModeleModal
                    modalAddModele={modalAddModele}
                    setModalAddModele={setModalAddModele}
                  />
                </div>
              )}
            </div>

            <div className="hippocrate-dash-admin-page-content-container mb-1">
              <div className="hippocrate-tabs-locataire-container">
                <div className="tabs-component-container">
                  <Component {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParametreAdmin;
