import React from 'react'
import Modal from "react-bootstrap/Modal";
import { IModeles } from '../../../../utils/api/modeles/modeles.type';
import { getImage } from '../../../../utils/Utils';

function ViewModeleModale({
    modalViewModele,
    setModalViewModele,
    modele,
    setModele,
  }: {
    modalViewModele: any;
    setModalViewModele: any;
    modele?: IModeles;
    setModele?: any;
  }) {
    function closeModalViewModele() {
        setModalViewModele(false);
      }
  return (
    <Modal
          show={modalViewModele}
          onHide={() => {
            closeModalViewModele();
          }}
          aria-labelledby="Show Fidel Affectation Modal"
          animation={false}
          size="lg"
          centered
        >
          <Modal.Header className="modal-header border-0 p-3" closeButton>
            <Modal.Title className="modal-title">Nouveau modele</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="w-100 mb-3">
                    <img src={getImage(modele?.image)} alt="preview certificat" className="w-100" />
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
  )
}

export default ViewModeleModale