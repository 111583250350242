import React from "react";
import BreadCrumb from "../../../shared/BreadCrumb";
import { FormError, Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import MyDropzone, { ImageItem } from "../../../common/MyDropzone";
import { MdAdd } from "react-icons/md";
import UseCrudActiviteForm from "../requestForm/UseCrudActiviteForm";
import GoogleInput from "../../../common/GoogleInput";
import { SelectInput } from "../../../common/SelectInput";
import { useLocationState } from "../../../../utils/Utils";

function AddActiviteParoisse() {
  // const [image, setImages] = React.useState<File | []>();
  const {
    register,
    errors,
    onSubmit,
    isLoading,
    description,
    handleChangeDescription,
    image,
    setImage,
    address,
    onChangeAddress,
  } = UseCrudActiviteForm("activite");
  return (
    <div className="dashbord-admin-component">
      <div className="dash-admin-page-content-container mb-3">
        <div className="row mb-4">
          <BreadCrumb pageName="Paroisses" secondPage="Ajouter une activité" />
        </div>
      </div>
      <div className="content-graph-admin">
        <div className="form-content">
          <form action="" onSubmit={onSubmit}>
            <div className="row row-add-paroisse-form">
              {/* <div className="col-md-6">
              <Input
                label="Nom"
                type="text"
                placeholder="Nom"
                id="nom"
                {...register("nom")}
                error={errors.nom}
              />
            </div> */}

              <div className="col-md-6">
                <Input
                  label="Activité"
                  type="text"
                  placeholder="Titre de l'activité"
                  required
                  {...register("titre")}
                  error={errors.titre}
                />
              </div>
              <div className="col-md-6">
                <SelectInput
                  label="Type d'activité"
                  options={[
                    { label: "Activité", value: "activite" },
                    { label: "Évènement", value: "evenement" },
                  ]}
                  required
                  {...register("type")}
                  error={errors.type}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Date de l’activité"
                  type="date"
                  placeholder="|"
                  required
                  {...register("date")}
                  error={errors.date}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Heure de l'activité"
                  type="time"
                  placeholder="|"
                  required
                  {...register("heure")}
                  error={errors.heure}
                />
              </div>
              <div className="col-md-6">
                {/* <Input
                  label="Lieu de l’activité"
                  type="text"
                  placeholder="Lieu de l'activité"
                  required
                  {...register("lieu")}
                  error={errors.lieu}
                /> */}
                <div className="mb-3">
                  <label
                    htmlFor={"address"}
                    className={`form-label form-label-modal-custom`}
                    aria-labelledby={"address"}
                  >
                    Lieu de l'activité
                    <span className="text-danger" style={{ fontSize: 15 }}>
                      *
                    </span>
                  </label>
                  <GoogleInput
                    className={`form-control form-control-modal-custom`}
                    placeholder="Lieu de l'activité"
                    value={address}
                    onChange={onChangeAddress}
                  />
                  <FormError error={errors.lieu} />
                </div>
              </div>
              <div className="col-md-12 pt-3 pb-4">
                <label
                  htmlFor="description"
                  className="form-label form-label-modal-custom"
                >
                  Description de l’activité
                </label>
                <ReactQuill
                  value={description}
                  onChange={handleChangeDescription}
                />
                <FormError error={errors?.description} />
              </div>
              <div className="col-md-12 pb-4">
                <MyDropzone
                  label="Image illustrant l’activité"
                  setImages={setImage}
                  required
                  // maxFile={1}
                />
                <FormError error={errors?.image} />
              </div>
              <div className="col-md-12">
                <div className="row">
                  {!!image && <ImageItem image={image} />}
                </div>
              </div>
              {/* <div className="col-md-12">
                <button className="other-activity-big-btn">
                  <MdAdd style={{ fontSize: 20 }} />
                  <span>Ajouter une autre activité</span>
                </button>
              </div> */}
            </div>
            <div className="col-md-3 offset-md-9 auth-submit-btn-container text-end pt-5">
              <button
                disabled={isLoading}
                className="btn btn-theme-admin w-100"
                type="submit"
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>{"Enregistrement..."}</span>
                  </>
                ) : (
                  "Enregistrer"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddActiviteParoisse;
