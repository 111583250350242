import { useState } from "react";

export function useSearch(defaultValue?: string) {
	const [search, setSearch] = useState<string | undefined>(defaultValue);
	const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value && e.currentTarget.value.length >= 3) {
			setSearch(e.currentTarget.value);
		} else {
			setSearch("");
		}
	};

	return {
		search,
		handleSearch,
	};
}

export function useSelectSearch() {
	const [search, setSearch] = useState<string>();
	const handlefilter = (e: React.FormEvent<HTMLSelectElement>) => {
		setSearch(e.currentTarget.value);
	};
	return {
		search,
		handlefilter,
	};
}