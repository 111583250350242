import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { UserFormData } from "../../../../utils/api/user/user.type";
import { useAddOrEditAdminMutation } from "../../../../utils/api/user/user.api";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { Color } from "../../../../utils/theme";

function UseCrudProfil() {
  const [code, setCode] = useState<string>("");
  const validationSchema = yup.object().shape({
    nom: yup.string().required("Nom est obligatoire"),
    prenom: yup.string().required("Prenom est obligatoire"),
    telephone: validatePhone(yup, code).required("Téléphone est obligatoire"),
    email: yup.string().email().required("Email est obligatoire"),
  });
  
  const { user, token } = useAppSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<UserFormData>({
    resolver: yupResolver(validationSchema),
  });
  const dispatch = useAppDispatch();
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError, data }] =
    useAddOrEditAdminMutation();

  const handleChangeAvatar = (e) => {
    let files = e.target.files[0];
    let fd = new FormData();
    fd.append("nom", user?.nom);
    fd.append("prenom", user?.prenom);
    fd.append("email", user?.email);
    fd.append("avatar", files);

    addOrUpdateUser({
      slug: user?.slug,
      data: fd,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.id) {
        if (user?.id === data?.id) {
          dispatch(onSetUser(data));
        }
      }

      Swal.fire({
        icon: "success",
        title: !user
          ? "Admin ajouté avec succès"
          : "Votre profil a été modifié avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("l'utilisateur avec cet email existe déjà.")) {
        Swal.fire({
          icon: "error",
          title: "Un utilisateur avec cet email exite déjà!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Erreur de statut ${err?.status}`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  useEffect(() => {
    if (user?.id) {
      let fields: (keyof UserFormData)[] = [
        "nom",
        "prenom",
        "telephone",
        "email",
        "adresse",
      ];

      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
    }
  }, [user]);

  const onSubmit = (data: UserFormData) => {
    !user && (data["user_type"] = "admin");
    addOrUpdateUser({
      slug: user?.slug,
      data: data,
    });
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    handleChangeAvatar,
  };
}

export default UseCrudProfil;
