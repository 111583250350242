import React from "react";
import Skeleton from "react-loading-skeleton";

function CompteRenduSkeleton() {
  return (
    <>
      {Array(4)
        .fill(4)
        .map((i) => (
          <div className="col-md-6 mb-4" key={i}>
            <div className="container-meeting-bloc-item">
              <div className="d-flex justify-content-between align-items-start">
                <Skeleton className="title-bloc-meeting-item" height={15} />
              </div>
              <div className="content-text-bloc-meeting-item my-3">
                <Skeleton className="text-bloc-meeting-item" height={15} />
                <Skeleton className="text-bloc-meeting-item" height={15} />
                <Skeleton className="text-bloc-meeting-item" height={15} />
                <Skeleton className="text-bloc-meeting-item" height={15} />
                <Skeleton
                  className="text-bloc-meeting-item"
                  height={15}
                  width={150}
                />
              </div>
              <div className="bloc-meeting-date">
                <Skeleton
                  className="text-bloc-meeting-item"
                  height={15}
                  width={90}
                />
                <Skeleton
                  className="text-bold-bloc-meeting-item"
                  height={15}
                  width={70}
                />
              </div>
              <div className="bloc-meeting-date">
                <Skeleton
                  className="text-bloc-meeting-item"
                  height={15}
                  width={50}
                />
                <Skeleton
                  className="text-bloc-meeting-item"
                  height={15}
                  width={10}
                />
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default CompteRenduSkeleton;
