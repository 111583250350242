import React from 'react'
import SousAdminTable from '../ParametreTable/SousAdminTable'

function SousAdmin() {
  return (
    <div className="content-graph-admin">
      <div className="admin-table mt-4">
            <SousAdminTable />
        </div>
    </div>
  )
}

export default SousAdmin