import React, { useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import FideleTable from "../tables/FideleTable";
import { Input } from "../../../common/Input";
import { BtnSubmit } from "../../../common/Button";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import Select from "react-select";
import { BiSearchAlt2 } from "react-icons/bi";
import { BsFillFilterSquareFill } from "react-icons/bs";
import UseCrudFideleForm from "../requestForm/UseCrudFideleForm";
import { NavLink } from "react-router-dom";

const AddFidelForm = () => {
  const { register, errors, isLoading, phone, handleChangePhone } =
    UseCrudFideleForm();
  return (
    <>
      <div className="form-content">
        <form action="">
          <div className="row row-add-fidel-form">
            <div className="col-md-6">
              <Input label="Nom" type="text" placeholder="Nom" />
            </div>
            <div className="col-md-6">
              <Input label="Prénom" type="text" placeholder="Prénom" />
            </div>
            <div className="col-md-6">
              <Input label="Email" type="email" placeholder="Email" />
            </div>
            <div className="col-md-6">
              <Input label="Téléphone" type="numeric" placeholder="Téléphone" />
            </div>
            <div className="col-md-6">
              <Input label="Adresse" type="text" placeholder="Adress" />
            </div>
            <div className="col-md-6">
              <Input
                label="Boite postale"
                type="text"
                placeholder="Boite postale"
              />
            </div>
            <div className="col-md-6">
              <Input label="Fonction" type="text" placeholder="Fonction" />
            </div>
            <div className="col-md-6">
              <Input label="Date du 1er baptême" type="date" placeholder="|" />
            </div>
            <div className="col-md-6">
              <Input label="Date de nomination" type="date" placeholder="|" />
            </div>
            <div className="col-md-6 d-flex flex-column gap-1 mb-3">
              <label htmlFor="" className="form-label form-label-modal-custom">
                Grade
              </label>
              <Select
                // options={}
                className="custom-select-items"
              />
            </div>
            <div className="col-md-6">
              <Input
                label="Date du dernier grade"
                type="date"
                placeholder="|"
              />
            </div>
          </div>
          <div className="d-flex flex-r mt-3">
            <div className="col-md-3">
              <BtnSubmit label="Ajouter" isLoading={false} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const steps = [
  { id: "fidel-table", Component: FideleTable },
  //   { id: "add-fidel-form", Component: AddFidelForm },
];

function Fidele() {
  const {
    step,
    navigation: { go },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;
  const [query, setQuery] = useState("");

  const props = {
    q: query,
  };

  return (
    <>
      <div className="btn-action-and-search d-flex justify-content-between align-items-center pt-4 pb-5 flex-wrap gap-2">
        <div className="btn-actions">
          {index === 0 && (
            <NavLink
              to={"/admin/paroisses/ajouter-un-fidele"}
              className="btn custom-add-btn"
            >
              Ajouter un fidèle
              <MdAddCircleOutline className="add-icon" />
            </NavLink>
          )}
          {/* {index === 1 && (
            <button
              onClick={() => go("fidel-table")}
              className="btn custom-add-btn"
            >
              Retour a la liste
            </button>
          )} */}
        </div>
        {index === 0 ? (
          <div className="content-form-filter d-flex align-items-center gap-2">
            <form>
              <div className="content-form-search-bloc position-relative">
                <input
                  type="search"
                  className="form-control form-control-search"
                  placeholder="Tapez pour rechercher"
                  onChange={(e) => setQuery(e?.target?.value)}
                />
                <span className="container-icon-searh-form">
                  <BiSearchAlt2 />
                </span>
              </div>
            </form>
            {/* <button className="btn btn-icon-filter">
              <BsFillFilterSquareFill />
            </button> */}
          </div>
        ) : (
          <div className="appareance"></div>
        )}
      </div>

      <div className="tabs-component-fidele pt-4">
        <Component {...props} />
      </div>
    </>
  );
}
export default Fidele;
