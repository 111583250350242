import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { QueryUrl, useLocationState } from "../../Utils";
import { ActualiteFormData, Actualite } from "./actualite.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ActualiteApi = createApi({
  reducerPath: "actualiteApi",
  tagTypes: ["actualite", "actualitesList"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    addOrEditActualite: builder.mutation<
      Actualite,
      { slug?: string; data: ActualiteFormData | FormData }
    >({
      invalidatesTags: ["actualite", "actualitesList"],
      query: ({ slug, data }) => {
        if (slug) {
          return {
            url: `actualites/${slug}/`,
            method: "PUT",
            body: data,
          };
        }
        return {
          url: `actualites/`,
          method: "POST",
          body: data,
        };
      },
    }),

    getListActualite: builder.query<PaginationResults<Actualite>, TypeQuery>({
      providesTags: ["actualitesList"],
      query: (query) => QueryUrl("actualites/", query),
    }),
    getActualite: builder.query<Actualite, string>({
      providesTags: ["actualite"],
      query: (slug) => `actualites/${slug}/`,
    }),
    deleteActualite: builder.mutation<ActualiteFormData, string>({
      query: (slug) => ({
        url: `actualites/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["actualite", "actualitesList"],
    }),
  }),
});

export const {
  useAddOrEditActualiteMutation,
  useDeleteActualiteMutation,
  useGetActualiteQuery,
  useLazyGetActualiteQuery,
  useGetListActualiteQuery,
} = ActualiteApi;

export function useActualiteFromLocation(): [
  Actualite,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<Actualite | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findById, { data, isError, isLoading }] = useLazyGetActualiteQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findById(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as Actualite, isLoading, slug as string, findById];
}
