import React from "react";
import { MdAddCircleOutline } from "react-icons/md";
import ThemeTable from "../table/ThemeTable";
import CrudThemeModal from "../modal/CrudThemeModal";
import { showModal } from "../../../../utils/Utils";

function ThemesStep() {
  return (
    <div>
      <div className="btn-action-and-search d-flex justify-content-between align-items-center pt-4 pb-5">
        <div className="btn-actions">
          <button
            className="btn custom-add-btn"
            onClick={() => showModal("addThemeModal")}
          >
            Ajouter un thème
            <MdAddCircleOutline className="add-icon" />
          </button>
        </div>
      </div>

      <div className="tabs-component-paroisse pt-4">
        <ThemeTable />
      </div>
      <CrudThemeModal modalId="addThemeModal" />
    </div>
  );
}

export default ThemesStep;
