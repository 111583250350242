import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { 
    CarnetRose, 
    CarnetRoseFormData 
} from "./carnetRoses.type";


export const CarnetRoseApi = createApi({
  reducerPath: "CarnetRoseApi",
  tagTypes: ["carnetRose", "carnetRoseApi"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditCarnetRose: build.mutation<
    CarnetRose,
      { 
        slug?: string; 
        data: CarnetRoseFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? 
            `carnet_roses/${slug}/` 
            : 
            `carnet_roses/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["carnetRose"],
    }),

    getListCarnetRose: build.query<PaginationResults<CarnetRose>, TypeQuery>({
      query: (query) => QueryUrl("carnet_roses/", query),
      providesTags: ["carnetRose"],
    }),
    
    deleteCarnetRose: build.mutation<CarnetRoseFormData, string>({
      query: (slug) => ({
        url: `carnet_roses/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["carnetRose", "carnetRoseApi"],
    }),

    
  }),
});

export const {
    useAddOrEditCarnetRoseMutation,
    useDeleteCarnetRoseMutation,
    useGetListCarnetRoseQuery,
} = CarnetRoseApi;
