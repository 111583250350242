import React from "react";
import Modal from 'react-bootstrap/Modal';
import { FormError, Input } from "../../../common/Input";
import ReactQuill from "react-quill";
import UseCrudDocumentation from "../requestForm/UseCrudDocumentation";
import { IDocumentation } from "../../../../utils/api/documentations/documentations.type";

const AddArchivesModal = ({
  documentation,
  modalAddArchives,
  setModalAddArchives,
  typeDocumentations,
  setTypeDocumentations,
}: {
  documentation?: IDocumentation;
  modalAddArchives: any;
  setModalAddArchives: any;
  typeDocumentations: string;
  setTypeDocumentations: any;
}) => {
  function closeModalAddArchives() {
    setModalAddArchives(false);
  }

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    content,
    handleReset,
    handleChangeContent,
  } = UseCrudDocumentation(
    setModalAddArchives,
    typeDocumentations,
    setTypeDocumentations,
    documentation
  );

  return (
    <Modal
      show={modalAddArchives}
      onHide={() => {
        handleReset();
        closeModalAddArchives();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">Nouveau contenu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="mt-4" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Titre"
                  id="titre"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors.titre}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <ReactQuill
                  className="editor-cgu"
                  theme="snow"
                  value={content}
                  onChange={handleChangeContent}
                />
                <FormError error={errors?.content} />
              </div>
              <div className="col-md-7 offset-md-5 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>
                        {documentation ? "Modification..." : "Ajout..."}
                      </span>
                    </>
                  ) : documentation ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddArchivesModal;
