import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../Utils";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import {
  ConstitutionBleu,
  ConstitutionBleuFormData,
} from "./constitutionBleu.type";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ConstitutionBleuApi = createApi({
  reducerPath: "ConstitutionBleuApi",
  tagTypes: ["constitutionBleu", "constitutionBleuApi", "constitutionBySlug"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    addOrEditConstitutionBleu: build.mutation<
      ConstitutionBleu,
      { slug?: string; data: ConstitutionBleuFormData | FormData }
    >({
      query: ({ slug, data }) => ({
        url: slug ? `constitution_bleus/${slug}/` : `constitution_bleus/`,
        method: slug ? "PUT" : "POST",
        body: data,
      }),
      invalidatesTags: ["constitutionBleu"],
    }),

    getListConstitutionBleu: build.query<
      PaginationResults<ConstitutionBleu>,
      TypeQuery
    >({
      query: (query) => QueryUrl("constitution_bleus/", query),
      providesTags: ["constitutionBleu"],
    }),

    getConstitutionBleu: build.query<
      PaginationResults<ConstitutionBleu>,
      TypeQuery
    >({
      query: (query) => QueryUrl("constitution_bleus/", query),
      providesTags: ["constitutionBleu"],
    }),
    constitutionBySlug: build.query<ConstitutionBleu, string>({
      query: (slug) => `constitution_bleus/${slug}/`,
      providesTags: ["constitutionBySlug"],
    }),

    deleteConstitutionBleu: build.mutation<ConstitutionBleuFormData, string>({
      query: (slug) => ({
        url: `constitution_bleus/${slug}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["constitutionBleu", "constitutionBleuApi"],
    }),
  }),
});

export const {
  useAddOrEditConstitutionBleuMutation,
  useDeleteConstitutionBleuMutation,
  useGetConstitutionBleuQuery,
  useGetListConstitutionBleuQuery,
  useLazyConstitutionBySlugQuery,
} = ConstitutionBleuApi;

export function useConstitutionFromLocation(): [
  ConstitutionBleu,
  boolean,
  string,
  (id: string) => any
] {
  const itemState = useLocationState<ConstitutionBleu | undefined>(undefined);
  const [item, setItem] = useState(itemState);
  const { slug } = useParams<{ slug: string }>();
  const [findBySlug, { data, isError, isLoading }] =
    useLazyConstitutionBySlugQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string);
    }
  }, [slug]);
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1);
    }
  }, [isError]);
  useEffect(() => {
    if (data) {
      console.log("data", data);
      setItem(data);
    }
  }, [data]);

  return [item as ConstitutionBleu, isLoading, slug as string, findBySlug];
}
