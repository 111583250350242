/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Input } from "../../../common/Input";
import MyDropzone from "../../../common/MyDropzone";
import UseCrudBibliotheque from "../requestForm/UseCrudBibliotheque";
import { get_url_extension } from "../../../../utils/Utils";
import { ApiBaseUrl } from "../../../../utils/http";
import { IDocumentation } from "../../../../utils/api/documentations/documentations.type";
import MyDropzoneFile from "../../../common/MyDropzoneFile";

export let imgs = ["png", "jpeg", "jpg", "gif"];
export let videos = ["mp4", "MPG", "AVI", "M4A"];

const AddVideosModal = ({
  documentation,
  modalAddVideo,
  setModalAddVideo,
  typeDocumentations,
  setTypeDocumentations,
  formatDocumentations,
  setFormatDocumentations,
}: {
  documentation?: IDocumentation;
  modalAddVideo: any;
  setModalAddVideo: any;
  typeDocumentations: string;
  setTypeDocumentations: any;
  formatDocumentations: string;
  setFormatDocumentations: any;
}) => {
  function closeModalAddVideo() {
    setModalAddVideo(false);
  }

  const {
    register,
    onSubmit,
    errors,
    isLoading,
    handleReset,
    setPictures,
    pictures,
  } = UseCrudBibliotheque(
    setModalAddVideo,
    typeDocumentations,
    setTypeDocumentations,
    formatDocumentations,
    setFormatDocumentations,
    documentation
  );

  return (
    <Modal
      show={modalAddVideo}
      onHide={() => {
        handleReset();
        closeModalAddVideo();
      }}
      aria-labelledby="Show Fidel Affectation Modal"
      animation={false}
    >
      <Modal.Header className="modal-header border-0 p-3" closeButton>
        <Modal.Title className="modal-title">Nouveau contenu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <form className="mt-4" onSubmit={onSubmit}>
            <div className="row row-add-student my-3">
              <div className="col-md-12 mb-3">
                <Input
                  type="text"
                  label="Titre"
                  id="titre"
                  placeholder="Titre"
                  {...register("titre")}
                  error={errors.titre}
                  required
                />
              </div>
              <div className="col-md-12 mb-3">
                <label className="form-label form-label-modal-custom">
                  Ajouter une vidéo
                  {typeDocumentations === "bibliotheque" && "/image"}
                </label>
                {typeDocumentations !== "bibliotheque" ? (
                  <MyDropzoneFile
                    isVideoFile
                    maxFile={1}
                    setFiles={setPictures}
                  />
                ) : (
                  <MyDropzone
                    setImages={setPictures}
                    acceptFile={{
                      "image/jpeg": [],
                      "image/png": [],
                      "image/jpg": [],
                      "image/gif": [],
                      "video/mp4": [],
                      "video/mpg": [],
                      "video/avi": [],
                      "video/m4a": [],
                    }}
                    withVideo
                  />
                )}

                {pictures && imgs?.includes(get_url_extension(pictures)) && (
                  <div className="img-preview-container">
                    <img
                      src={
                        pictures?.name
                          ? URL.createObjectURL(pictures)
                          : ApiBaseUrl + pictures
                      }
                      alt="Image"
                      className="type-img-preview"
                    />
                  </div>
                )}
                {pictures && videos?.includes(get_url_extension(pictures)) && (
                  <div>
                    <video
                      controls
                      src={
                        pictures?.name
                          ? URL.createObjectURL(pictures)
                          : ApiBaseUrl + pictures
                      }
                      className="custom-video-preview"
                    />
                  </div>
                )}
              </div>
              <div className="col-md-7 offset-md-5 auth-submit-btn-container text-end pt-3">
                <button
                  disabled={isLoading}
                  className="btn btn-theme-admin w-100"
                  type="submit"
                >
                  {isLoading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                        role="status"
                      ></span>
                      <span>
                        {documentation ? "Modification..." : "Ajout..."}
                      </span>
                    </>
                  ) : documentation ? (
                    "Modifier"
                  ) : (
                    "Ajouter"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddVideosModal;
