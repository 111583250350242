import React, { Dispatch, SetStateAction, useCallback } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { DocumentationFormData, IDocumentation } from "../../../../utils/api/documentations/documentations.type";
import {
  useAddOrEditDocumentationsMutation,
  useDeleteDocumentationMutation,
} from "../../../../utils/api/documentations/documentations.api";
import { useAppSelector } from "../../../../redux/hook";

function UseCrudDocumentation(
  setModalAddArchives: Dispatch<SetStateAction<boolean>>,
  typeDocumentations: string,
  setTypeDocumentations: Dispatch<SetStateAction<string>>,
  documentation?: IDocumentation
) {
  const validationSchema = yup.object().shape({
    titre: yup.string().required("Le titre est requis").label("Titre"),
    content: yup.string().label("content"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<DocumentationFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [content, setContent] = useState<string>("");
  const [addOrUpdateDocumentation, { isLoading, isSuccess, error, isError }] =
    useAddOrEditDocumentationsMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: documentation
          ? "Documentation modifiée avec succès !"
          : "Documentation ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!documentation) {
          reset();
          setContent("");
          setTypeDocumentations("");
        }
        setModalAddArchives(false);
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((s) => s?.user);
  const userID = user?.id;
  const onSubmit = (data: any) => {
    data["user"] = userID;
    data["type_documentation"] = typeDocumentations;
    data["format"] = "textes";
    addOrUpdateDocumentation({ slug: documentation?.slug, data });
  };

  const handleReset = () => {
    reset();
    setContent("");
    setTypeDocumentations("");
  };

  useEffect(() => {
    if (documentation?.id) {
      const fields: (keyof DocumentationFormData)[] = ["titre", "content"];

      for (let field of fields) {
        register(field);
        setValue(field, documentation[field]);
        if (field === "content") {
          setContent(documentation.content || "");
          setValue("content", documentation.content);
        }
      }
    }
  }, [documentation]);

  const handleChangeContent = useCallback((value: string) => {
    setContent(value);
    setValue("content", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    content,
    handleReset,
    handleChangeContent,
  };
}

export default UseCrudDocumentation;

export function UseDeleteDocumentations(slug: string) {
  const [deleteData] = useDeleteDocumentationMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet archive ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Archive supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
