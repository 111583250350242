import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { cleannerError, validatePhone } from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useForm, Controller } from "react-hook-form";
import {
  IParoisse,
  ParoisseFormData,
} from "../../../../utils/api/paroisses/paroisses.type";
import {
  useAddOrEditParoisseMutation,
  useDeleteParoisseMutation,
} from "../../../../utils/api/paroisses/paroisses.api";
import { useGetPaysQuery } from "../../../../utils/api/pays/pays.api";
import { Data } from "../../../common/GoogleInput";

function UseCrudParoisses(paroisse?: IParoisse) {
  const [code, setCode] = useState<string>("");
  const [phone, setPhone] = useState<string>();
  const validationSchema = yup.object().shape({
    nom: yup.string().required("Nom est obligatoire").label("Nom"),
    email: yup.string().email().required().label("L'email"),
    telephone: validatePhone(yup, code),
    pays: yup.string().required().label("Pays").nullable(),
    adresse: yup.string().required().label("l'adresse").nullable(),
    // longitude: yup.string().required("Longitude est obligatoire").label("Longitude").nullable(),
    // latitude: yup.string().required("Latitude est obligatoire").label("Latitude").nullable(),
    horaire_matin: yup.string().label("Horaire matin").nullable(),
    horaire_soir: yup.string().label("Horaire soir").nullable(),
    horaire_dimanche_matin: yup
      .string()
      .label("Horaire dimanche matin")
      .nullable(),
    horaire_dimanche_soir: yup
      .string()
      .label("Horaire dimanche soir")
      .nullable(),
    description: yup.string().label("Description").nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
    control,
  } = useForm<ParoisseFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [description, setDescription] = useState<string>("");
  const [address, setAddress] = useState("");
  const [pays, setPays] = useState<{ label: string; value: number }[]>([]);
  const [selected, setSelected] = useState<{ label: string; value: number }>();
  const { data = { results: [] } } = useGetPaysQuery({
    limit: 1000,
  });
  const [addOrUpdateParoisse, { isLoading, isSuccess, error, isError }] =
    useAddOrEditParoisseMutation();

  useEffect(() => {
    if (data?.results?.length) {
      setPays(
        data?.results?.map((el) => {
          return {
            label: el?.nom,
            value: el?.id,
          };
        })
      );
    }
  }, [data]);
  const handleChangePhone = (item, country) => {
    setCode(country?.countryCode);
    setPhone(item);
    if (parseInt(item) !== parseInt(country?.dialCode)) {
      setValue(`telephone`, "+" + item);
    } else {
      setValue(`telephone`, "");
    }
  };

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("adresse", val?.rue);
    setValue("latitude", val?.latitude);
    setValue("longitude", val?.longitude);
  };

  const onSelectPays = (e) => {
    setSelected(e);
    setValue("pays", e?.value);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: paroisse
          ? "Paroisse modifiée avec succès !"
          : "Paroisse ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!paroisse) {
          reset();
          setDescription("");
        }
        navigate("/admin/paroisses");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
      console.log("err", err);
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: any) => {
    console.log("data submitted", data);
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key]) {
        fd.append(key, data[key]);
      } else {
        fd.append(key, "");
      }
    }
    addOrUpdateParoisse({ slug: paroisse?.slug, data: fd });
  };

  const handleReset = () => {
    if (!paroisse) {
      reset();
    }
  };

  useEffect(() => {
    if (paroisse?.id) {
      let fields: (keyof IParoisse)[] = [
        "id",
        "slug",
        "nom",
        "telephone",
        "email",
        "pays",
        "longitude",
        "latitude",
        "horaire_matin",
        "horaire_soir",
        "horaire_dimanche_matin",
        "horaire_dimanche_soir",
        "description",
        "adresse",
      ];
      for (let field of fields) {
        register(field);
        if (
          field !== "pays" &&
          field !== "chef_diocese" &&
          field !== "chef_disrict" &&
          field !== "chef_zone" &&
          field !== "diocese"
        ) {
          setValue(field, paroisse[field]);
        } else {
          if (paroisse[field]) {
            setValue(field, paroisse[field]?.id);
          }
        }

        if (field === "description") {
          setDescription(paroisse[field] || "");
        }
      }
      if (paroisse?.adresse) {
        setAddress(paroisse?.adresse);
      }

      if (paroisse?.telephone) {
        setPhone(paroisse?.telephone);
      }
      if (paroisse?.pays) {
        setSelected({
          label: paroisse.pays?.nom,
          value: paroisse.pays?.id,
        });
      }
    }
  }, [paroisse]);

  const handleChangeDescription = useCallback((value: string) => {
    setDescription(value);
    setValue("description", value);
  }, []);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    description,
    handleReset,
    handleChangeDescription,
    pays,
    onSelectPays,
    selected,
    phone,
    handleChangePhone,
    address,
    onChangeAddress,
    control,
    Controller,
  };
}

export default UseCrudParoisses;

export function UseDeleteParoisse(item: IParoisse) {
  const [deleteData] = useDeleteParoisseMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette paroisse ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Paroisse supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
