import React, { ChangeEvent, useCallback, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/Utils";
import { useDelete } from "../../../../utils/helpers";
import { FaTrash } from "react-icons/fa";
import {
  useAddOrEditConstitutionBleuMutation,
  useDeleteConstitutionBleuMutation,
} from "../../../../utils/api/constitutionBleu/constitutionBleu.api";
import {
  ConstitutionBleu,
  ConstitutionBleuFormData,
} from "../../../../utils/api/constitutionBleu/constitutionBleu.type";
import { useAppSelector } from "../../../../redux/hook";
import { useNavigate } from "react-router-dom";

function useCrudConstitutionBleu(constitution?: ConstitutionBleu) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    titre: yup.string().required().label("Le titre"),
    fichier: yup
      .mixed()
      .when("hasEdit", {
        is: false,
        then: () =>
          yup
            .mixed()
            .nullable()
            .required()
            .label("Le fichier")
            .transform((val: any) => (!val ? null : val)),
      })
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    reset,
  } = useForm<ConstitutionBleuFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [
    addOrUpdateConstitutionBleu,
    { isLoading, isSuccess, error, isError },
  ] = useAddOrEditConstitutionBleuMutation();

  const [titre, setTitre] = useState<string>("");
  const [fichier, setFichier] = useState<any>(null);

  useEffect(() => {
    console.log("fichiers: ", fichier);
    if (fichier?.name) {
      setValue("fichier", fichier);
    } else {
      setValue("fichier", "");
    }
  }, [fichier]);

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: constitution
          ? "Constitution modifiée avec succès !"
          : "Constitution ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!constitution) {
          reset();
          setTitre("");
        }
        navigate("/admin/constitution-bleu");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("errors", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const { user } = useAppSelector((c) => c.user);
  const userId = user?.id;

  const onSubmit = async (data: any) => {
    if (!constitution) data["user"] = userId;
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if (data[key]) {
        fd.append(key, data[key]);
      }
    }
    // console.log("data", data);
    await addOrUpdateConstitutionBleu({ slug: constitution?.slug, data: fd });
  };

  useEffect(() => {
    if (constitution?.id) {
      setValue("hasEdit", true);
      const fields: (keyof ConstitutionBleuFormData)[] = ["titre", "slug"];
      if (constitution?.fichier) {
        setFichier(constitution?.fichier);
      }
      for (let field of fields) {
        register(field);
        setValue(field, constitution[field]);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [constitution]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    reset,
    titre,
    user,
    fichier,
    setFichier,
  };
}

export function DeleteConstitutionBleu({ item }: { item: ConstitutionBleu }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteConstitutionBleuMutation();
  const onDelete = useDelete<ConstitutionBleu>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "cette constitution bleue",
    successMessage: "Constitution bleue supprimée",
  });
  return (
    <div className="container-btn-action-icon-modal">
      <button
        className="btn with-tooltip btn-action-icon-delete"
        data-tooltip-content="Supprimer"
        style={{ color: "#E53E3E" }}
        onClick={onDelete}
      >
        <FaTrash />
      </button>
    </div>
  );
}

export default useCrudConstitutionBleu;
