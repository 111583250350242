import { PhoneNumberUtil } from "google-libphonenumber";
import * as Yup from "yup";
import { IUser } from "./api/user/user.type";
import Avatar from "../assets/appImages/default.jpeg";
// import Image from "../assets/appImages/image.png";
import defaultCover from "../assets/appImages/img-bg.png";
import { ApiBaseUrl } from "./http";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const phoneUtil = PhoneNumberUtil.getInstance();

export function validateNumber(phone?: string, country?: any) {
  if (!phone || !country) return true;
  try {
    const number = parsePhoneNumberFromString(phone, country);
    if (number && number.isValid()) {
      return true;
    }
  } catch (ex) {
    console.log("error phone", ex);
  }
  return false;
}
// export function validateNumber(phone?: string, country?: string) {
//   if (!phone || !country) return true;
//   try {
//     const number = phoneUtil.parse("+" + phone, country);
//     let code = number.getCountryCode();
//     let val = phone?.split(`${code}`)[1];

//     if (phoneUtil.isPossibleNumber(number) && phoneUtil.isValidNumber(number)) {
//       return true;
//     }

//     if (code === 33 && val?.length >= 9) {
//       return true;
//     }
//   } catch (ex) {
//     console.log("error phone", ex);
//   }
//   return false;
// }

export function validatePhone(yup: typeof Yup, country: string) {
  return yup
    .string()
    .test("passwords-match", "Numéro téléphone invalide", (val) =>
      validateNumber(val, country)
    )
    .label("Le téléphone")
    .nullable();
}

export function validatePassword(Yup: any) {
  return Yup.string()
    .required("Le mot de passe est obligatoire ")
    .matches(/[a-z]+/, "Le mot de passe doit avoir au moins une minuscule.")
    .matches(/[A-Z]+/, "Le mot de passe doit avoir au moins une majuscule.")
    .matches(
      /[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
      "Le mot de passe doit avoir au moins un caractère spécial"
    )
    .matches(/\d+/, "Le mot de passe doit avoir au moins un chiffre.")
    .min(8, "Le mot de passe doit être supérieur à 5 caractére");
  //.label("Le mot de passe");
}

export function useLocationState<T>(
  defaultValue: T | any,
  redirectToWhenNull: string | null = null
): T {
  const state = useLocation().state as T;
  const navigate = useNavigate();
  useEffect(() => {
    if (!state && redirectToWhenNull) {
      navigate(redirectToWhenNull);
    }
  }, [state]);
  return state || defaultValue;
}
export function createMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  return {
    __html: `${numberSlice ? text.slice(0, numberSlice) : text}${
      showDots === true ? "..." : ""
    }`,
  };
}
export function readMarkup(
  text: string | any,
  showDots?: boolean,
  numberSlice?: number
) {
  let content = text ? text.replace(/<.+?>/g, "") : text;
  return {
    __html: `${numberSlice ? content.slice(0, numberSlice) : content}${
      showDots === true ? "..." : ""
    }`,
  };
}

export function cleannerError(
  errors: any,
  cleanner: any,
  timeOut: number = 3000
) {
  if (errors) {
    setTimeout(
      () => Object.entries(errors).map(([key]: any) => cleanner(key)),
      timeOut
    );
  }
}

export function isRH(user: any) {
  if (!user) return false;
  return ["rh"].includes(user.user_type);
}

// export function isGestionnaire(user: any) {
//   if (!user) return false;
//   return ["gestionnaire"].includes(user.user_type);
// }

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
  if (additionalSearchQuery) {
    for (let key of Object.keys(additionalSearchQuery)) {
      let val = additionalSearchQuery[key];

      if (Array.isArray(val)) {
        if (val.length > 0) {
          const string = val.map((v) => `${key}=${v}`).join("&");
          if (baseUrl.includes("?")) {
            baseUrl += `&${string}`;
          } else {
            baseUrl += `?${string}`;
          }
        }
      } else if (typeof val === "boolean") {
        if (baseUrl.includes("?")) {
          baseUrl += `&${key}=${val}`;
        } else {
          baseUrl += `?${key}=${val}`;
        }
      } else {
        if (val) {
          if (baseUrl.includes("?")) {
            baseUrl += `&${key}=${val}`;
          } else {
            baseUrl += `?${key}=${val}`;
          }
        }
      }
    }
  }
  return baseUrl;
}

export const isUser = (user: IUser) => {
  return user?.user_type === "user";
};

export const isSuperAdmin = (user: IUser) => {
  return user?.user_type === "superadmin";
};

export const isAdmin = (user: IUser) => {
  return user?.user_type === "admin";
};

export function isPraticien(user: any) {
  return user?.user_type === "praticien";
}

export function isGestionnaire(user: any) {
  return user?.user_type === "gestionnaire";
}

export const getAvatar = (avatar: string | any) => {
  if (avatar && !avatar?.includes("default.png")) {
    if (avatar.indexOf("http") === 0) return avatar;
    return `${ApiBaseUrl}/${avatar}`;
  }
  return Avatar;
};

export const getImage = (image: string | any) => {
  if (image && !image?.includes("default.png")) {
    if (image.indexOf("http") === 0) return image;
    return `${ApiBaseUrl}/${image}`;
  }
  return defaultCover;
};

export function getName(user: IUser) {
  if (user) {
    return user?.prenom + " " + user?.nom;
  }
  return null;
}

export const onHide = (modalId) => {
  // console.log("modalId",modalId)
  document.getElementById(modalId)?.classList.remove("show");
  $(`#${modalId}`).hide();
  // $(`#${modalId}`).click();
  $("body").removeClass("modal-open");
  $(".modal-backdrop").remove();
  $(`body`).css({ overflowY: "scroll" });
};

export const onShow = (modalId: string) => {
  document.getElementById(modalId)?.classList.add("show");
  $(`#${modalId}`).show();
};

export const showModal = (id: string) => {
  // console.log("id", id);
  const element = document.getElementById(id);

  const myModal = new Modal(element);
  myModal.show();
};

export const closeModal = (id: string) => {
  const element = document.getElementById(id);
  const myModal = Modal.getInstance(element);
  $(".modal-backdrop").remove();
  $("body").removeClass("modal-open");
  $("body").css("padding-right", "");
  $("body").css("overflow", "auto");
  myModal?.hide();
};

export const countUppercase = (str: any) => {
  if (str.replace(/[^A-Z]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countLowercase = (str: any) => {
  if (str.replace(/[^a-z]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countNumber = (str: any) => {
  if (str.replace(/[^0-9]/g, "")?.length >= 1) {
    return true;
  }
  return false;
};

export const countCharacters = (str: any) => {
  if (str?.length >= 8) {
    return true;
  }
  return false;
};

export const countSpecial = (str: any) => {
  const punct = `/[!@#$%^&*()_+\\-=\\[\\]{};':"\\|,.<>\\/?]+/;`;
  let count = 0;
  for (let i = 0; i < str?.length; i++) {
    if (!punct.includes(str[i])) {
      continue;
    }
    count++;
  }
  // return count;
  if (count >= 1) {
    return true;
  }
  return false;
};

const CURRENCY_FORMATTER = new Intl.NumberFormat(undefined, {
  currency: "EUR",
  style: "currency",
});

export function formatCurrency(number: number | any) {
  return CURRENCY_FORMATTER.format(number);
}

export const getAddress = (user: IUser) => {
  return user?.adresse;
};

export const formatDateHour = (date) => {
  return moment(date).format("DD/MM/YYYY à HH[h]mm");
};

export const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

export const sortByAlphabetical = (arr) => {
  return [...arr]?.sort(function (a, b) {
    if (a?.name < b?.name) {
      return -1;
    }
    if (a?.name > b?.name) {
      return 1;
    }
    return 0;
  });
};

export const truncate = (str) => {
  return str != null && str.length > 10 ? str.substring(0, 19) + "..." : str;
};
export function get_url_extension(uri: any) {
  if (uri?.type) {
    return uri?.type?.toLowerCase()?.split("/")[1];
  }
  return uri?.toLowerCase()?.split(/[#?]/)[0]?.split(".")?.pop()?.trim();
}

export const getFile = (file: string | any) => {
  if (file && !file?.includes("default.png")) {
    if (file.indexOf("http") === 0) return file;
    return ApiBaseUrl + file;
  }
  return "";
};

export const getFileName = (uri: string) => {
  let name = uri?.split(".")?.length ? uri?.split(".")[0] : uri;

  return name?.includes("mediafiles") ? name?.split("/mediafiles/")[1] : name;
};

export function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str?.length <= num) {
    return str;
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str?.slice(0, num) + "...";
}
export function truncateStringReverse(str, num) {
  if (str?.length <= num) {
    return str;
  }
  return "..." + str?.slice(-num);
}

export const getVideoId = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url?.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

export const hasAccess = (user: IUser, field: string) => {
  let noAccess = false;
  if (user[field] !== true && !isSuperAdmin(user)) {

    noAccess = true;
  }

  return noAccess;
};
