import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults, TypeQuery } from "../user/user.type";
import { ApiBaseUrl } from "../../http";
import { Cotisation } from "./cotisation.type";
import { QueryUrl } from "../../Utils";

export const CotisationApi = createApi({
  reducerPath: "cotisationApi",
  tagTypes: ["cotisations"],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}/api/`,
    prepareHeaders,
  }),
  endpoints: (build) => ({
    getCotisations: build.query<PaginationResults<Cotisation>, TypeQuery>({
      query: (query) => QueryUrl(`cotisations/`, query),
      providesTags: ["cotisations"],
    }),
    getCotisationsByUser: build.query<PaginationResults<Cotisation>, TypeQuery>(
      {
        query: ({ slug, ...query }) =>
          QueryUrl(`user/${slug}/cotisations/`, query),
        providesTags: ["cotisations"],
      }
    ),
  }),
});

export const { useGetCotisationsQuery, useGetCotisationsByUserQuery } =
  CotisationApi;
