import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/Utils";
import {
  useAddOrEditActualiteMutation,
  useDeleteActualiteMutation,
} from "../../../../utils/api/actualite/actualite.api";
import { useNavigate } from "react-router-dom";
import { useGetListThemeQuery } from "../../../../utils/api/theme/theme.api";
import { useAppSelector } from "../../../../redux/hook";
import {
  ObjetReligieux,
  ObjetReligieuxFormData,
} from "../../../../utils/api/objetReligieux/objetReligieux.type";
import { useAddOrEditObjetReligieuxMutation, useDeleteObjetReligieuxMutation } from "../../../../utils/api/objetReligieux/objetReligieux.api";

function AddOrUpdateForm(item?: ObjetReligieux) {
  const validationSchema = yup.object().shape({
    hasEdit: yup.boolean(),
    nom: yup.string().required().label("Le nom"),
    image: yup.string().when("hasEdit", {
      is: false,
      then: () => yup.mixed().label("L'image").nullable().required(),
    }),
  });
  const [image, setImage] = useState<File | undefined>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    control,
  } = useForm<ObjetReligieuxFormData>({
    resolver: yupResolver(validationSchema),
  });
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();

  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditObjetReligieuxMutation();

  useEffect(() => {
    if (image?.name) {
      setValue("image", image);
    }
  }, [image]);

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `L'objet religieux a été ${
          item ? "modifié" : "ajouté"
        } avec succès!`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      }).then(() => {
        navigate("/admin/objets-religieux");
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Erreur de statut ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("err", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: ObjetReligieuxFormData) => {
    if (!item) {
      data["user"] = user?.id;
    }
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      if (key === "image") {
        if (data[key] && data[key] !== undefined) {
          fd.append(key, data[key] as any);
        }
      } else {
        fd.append(key, data[key]);
      }
    }

    await sendData({ slug: item?.slug, data: fd });
  };

  useEffect(() => {
    if (item?.id) {
      const fields: (keyof ObjetReligieuxFormData)[] = ["nom"];
      setValue("hasEdit", true);
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    } else {
      setValue("hasEdit", false);
    }
  }, [item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    image,
    setImage,
    control,
    Controller,
  };
}

export default AddOrUpdateForm;

export function UseDeleteObjetReligieux(slug: string) {
  const [deleteData] = useDeleteObjetReligieuxMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cet objet religieux ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result?.isConfirmed) {
        if (result?.value && !result?.value?.error) {
          Swal.fire({
            icon: "success",
            title: `L'objet religieux a été supprimé avec succès!`,
            iconColor: Color.themeColor,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          let err = result?.value?.error;
          Swal.fire({
            icon: "error",
            title: err?.data?.message
              ? err?.data?.message
              : `Une erreur de statut ${err?.status} est survenue!`,
            showConfirmButton: false,
            timer: 5000,
          });
        }
      }
    });
  };
  return onDelete;
}