import { ReactElement } from "react";
import { IUser, UserType } from "../utils/api/user/user.type";
import { useAppSelector } from "../redux/hook";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FieldType } from "../utils/constante";

export function isSuperAdmin(user?: IUser) {
  return user && (user.user_type === "superadmin");
}

export function isSubAdmin(user?: IUser) {
  return user && (user.user_type === "superadmin" || user?.user_type === "admin");
}
export const isAdmin = (user: IUser) => {
  return (
    user?.user_type === "admin" ||
    user?.user_type === "superadmin" ||
    user?.user_type === "membre" ||
    user?.user_type === "responsable_paroissien" ||
    user?.user_type === "chef_diocese"
  ); //&& user?.office_pastoral
};
export const isFidele = (user: IUser) => {
  return user?.user_type === "fidele";
};
export const isResponsableParoissien = (user: IUser) => {
  return user?.user_type === "responsable_paroissien";
};

export const RedirectAuthRoute = ({ children }: { children: ReactElement }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path: string = "/";

  if (user?.user_type === "fidele") {
    return <Navigate to="/not-authorized" replace />;
  }

  if (isAdmin(user)) {
    path = "/admin/dashboard";
  }

  return !token && !user?.id ? children : <Navigate to={path} replace />;
};

export const IsAdminAllow = ({
  children,
  field,
}: {
  children: ReactElement;
  field: FieldType;
}) => {
  // const { user, token } = useAppSelector((state) => state.user);
  // console.log("me", user)
  // let path: string = "/";
  // if (token) {
  // 	if (isFidele(user)) {
  // 		path = "/fidele/profil";
  // 	}
  // }
  // return !token || !isAdmin(user) ? <Navigate to={path} replace /> : children;

  const { user } = useAppSelector((state) => state.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  // const is_SuperAdmin = useAppSelector((s) => isAdmin(s.user?.user));
  const navigate = useNavigate();
  if (!user[field] && !is_SuperAdmin) {
    setTimeout(() => {
      navigate(-1);
    }, 10);
  }
  return children;
};

export const ProtectedRoutes = ({
  children,
  userType,
}: {
  children: ReactElement;
  userType: UserType[];
}) => {
  let location = useLocation();
  const { user } = useAppSelector((state) => state.user);

  if (user?.user_type === "fidele") {
    return <Navigate to="/not-authorized" replace />;
  }
  
  const isAccess = userType?.includes(user?.user_type);
  const auth = user?.id;
  return isAccess && isAccess !== undefined ? (
    children
  ) : auth && auth !== undefined ? (
    <Navigate to="/connexion" replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
