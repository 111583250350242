import React from "react";
import FormContent from "../FormContent";
import { useParoisseFromLocation } from "../../../../../utils/api/paroisses/paroisses.api";

function Diocese() {
  const [item] = useParoisseFromLocation();
  return (
    <>
      <FormContent type="diocese" item={item?.diocese} />
    </>
  );
}

export default Diocese;
