import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import {
  cleannerError,
  useLocationState,
  validatePhone,
} from "../../../../utils/Utils";
import { Color } from "../../../../utils/theme";
import { useForm, Controller } from "react-hook-form";
import { Data } from "../../../common/GoogleInput";
import {
  ActiviteFormData,
  IActivite,
} from "../../../../utils/api/activites/activites.type";
import { useAddOrEditActiviteMutation, useDeleteActiviteMutation } from "../../../../utils/api/activites/activites.api";
import { useAppSelector } from "../../../../redux/hook";
import { useParoisseFromLocation } from "../../../../utils/api/paroisses/paroisses.api";
import { IParoisse } from "../../../../utils/api/paroisses/paroisses.type";

function UseCrudActiviteForm(type: "activite" | "evenement") {
  const validationSchema = yup.object().shape({
    titre: yup.string().required().label("Le titre"),
    date: yup.string().required().label("La date"),
    paroisse: yup.string().required().label("La paroisse").nullable(),
    heure: yup.string().required().label("L'heure").nullable(),
    lieu: yup.string().required().label("Le lieu").nullable(),
    created_by: yup.string().required().label("L'utilisateur").nullable(),
    description: yup
      .string()
      .label("L'adresse")
      .transform((val: string) => (val === "<p><br></p>" ? "" : val)),
    image: yup.mixed().label("L'image").nullable(),
    type: yup.string().required().label("Le type d'activité"),
    publier: yup.boolean().default(true).nullable(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<ActiviteFormData>({
    resolver: yupResolver(validationSchema),
  });
  const [description, setDescription] = useState("");
  const [image, setImage] = useState<any>(null);
  const [address, setAddress] = useState("");
  const { user } = useAppSelector((s) => s?.user);
  //   const [paroisse] = useParoisseFromLocation();
  const itemState = useLocationState<{ paroisse: IParoisse; item?: IActivite }>(
    undefined
  );
  //   const {paroisse, item} = itemState
  const [sendData, { isLoading, isSuccess, error, isError }] =
    useAddOrEditActiviteMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!itemState) {
      navigate(-1);
    }
  }, [itemState]);

  useEffect(() => {
    if (image?.name) {
      setValue("image", image);
    }
  }, [image]);
  useEffect(() => {
    if (itemState?.item?.image) {
      console.log("itemState?.item?.image", itemState?.item?.image)
      // setValue("image", itemState?.item?.image);
      setImage(itemState?.item?.image)
    }
  }, [itemState?.item]);

  useEffect(() => {
    if (user && !itemState?.item) {
      setValue("created_by", user?.id);
    }

    if (itemState?.paroisse && !itemState?.item) {
      setValue("paroisse", itemState?.paroisse?.id);
    }

    if (type && !itemState?.item) {
      setValue("type", type);
    }
  }, [user, itemState, type]);

  const onChangeAddress = (val: Data) => {
    setAddress(val?.rue);
    setValue("lieu", val?.rue);
  };

  const handleChangeDescription = (val) => {
    setDescription(val);
    setValue("description", val);
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: itemState?.item
          ? "L'activité a été modifiée avec succès !"
          : "L'activité a été ajoutée avec succès",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate(
          `/admin/paroisses/detail-paroisse/${itemState?.paroisse?.slug}?tab=activite-communaute`,
          {
            state: itemState?.paroisse,
          }
        );
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Error status ${err?.status}`,
        showConfirmButton: false,
        timer: 5000,
      });
      console.log("err", err);
    }
  }, [isLoading]);
  useEffect(() => {
    console.log("error", errors);
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = async (data: ActiviteFormData) => {
    if (!itemState?.item) {
      data["publier"] = true;
    }
    console.log("data submitted", data);
    let fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    await sendData({ slug: itemState?.item?.slug, data: fd });
  };

  useEffect(() => {
    if (itemState?.item?.id) {
      let fields: (keyof ActiviteFormData)[] = [
        "created_by",
        "titre",
        "type",
        "date",
        "heure",
        "paroisse",
        "description",
        "lieu",
      ];
      for (let field of fields) {
        register(field);
        if (field !== "paroisse" && field !== "created_by") {
          if (itemState?.item[field]) setValue(field, itemState?.item[field]);
        } else {
          if (itemState?.item[field]) {
            setValue(field, itemState?.item[field]?.id);
          }
        }
      }

      setDescription(itemState?.item?.description);
      if (itemState?.item?.lieu) {
        setAddress(itemState?.item?.lieu);
      }
    }
  }, [itemState?.item]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    type,
    description,
    handleChangeDescription,
    address,
    onChangeAddress,
    image,
    setImage,
  };
}

export default UseCrudActiviteForm;

export function UseDeleteActivite(item: IActivite) {
  const [deleteData] = useDeleteActiviteMutation();
  const onDelete = async () => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer cette activité ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(item?.slug);
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value) {
        Swal.fire({
          icon: "success",
          title: `Activité supprimée avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onDelete;
}
