import React from "react";

import FinancesAdminTable from "../Tables/FinancesAdminTable/FinancesAdminTable";
import TabBanner from "./TabBanner";

function ResponsablesTab(props) {
  return (
    <div className="content-finance-tab">
      <TabBanner {...props} />
      <div className="content-graph-admin mb-2">
        <div className="admin-table">
          <FinancesAdminTable {...props} />
        </div>
      </div>
    </div>
  );
}

export default ResponsablesTab;
